"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "dunning-letters",
    path: "/dunning-letters",
    component: "DunningLetters"
});
let DunningLetters = class DunningLetters extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns', 'b_2248226175642056_contextMenu'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "reference_number",
                    name: this.$fn.pgettext("DunningLettersView", "Reference Number"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "dunningLetter",
                        params: {
                            id: "id",
                        },
                    },
                },
                {
                    field: "status",
                    name: this.$fn.pgettext("DunningLettersView", "Status"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        formatters: [{
                                name: "gettext",
                                context: "DunningLetterStatus",
                                conversion: "StartCase",
                            },
                            {
                                name: "badge",
                                variantmap: {
                                    sent: "light",
                                    delivered: "success",
                                    error: "danger",
                                },
                                variantMapSource: "fieldValue",
                            },
                        ],
                    },
                },
                {
                    field: "customer_name",
                    name: this.$fn.pgettext("DunningLettersView", "Customer Name"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "customer_email",
                    name: this.$fn.pgettext("DunningLettersView", "Client Email"),
                    formatter: {
                        name: "join",
                        separator: "; ",
                    },
                },
                {
                    field: "severity",
                    name: this.$fn.pgettext("DunningLettersView", "Severity"),
                },
                {
                    field: "created_at",
                    name: this.$fn.pgettext("DunningLettersView", "Created at"),
                    formatter: {
                        name: "datetime",
                    },
                },
                {
                    field: "created_by",
                    name: this.$fn.pgettext("DunningLettersView", "Created by"),
                    formatter: {
                        name: "user",
                    },
                },
            ],
            b_2248226175642056_contextMenu: {
                items: [{
                        label: "Delete",
                        command: "remove",
                    },
                ],
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("DunningLettersView", "Dunning Letters"),
                icon: this.$config.dunningLetter.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4828_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_4830_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_reloadSlickgrid_4834_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_2248226175642056_request_4832_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_dunning_letter",
                data: { id: $event.data.data.slug_id },
            };
        });
    }
    evh_2248226175642056_contextmenuRemove(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_contextmenuRemove.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_showModal_4836_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "SendMails",
                props: {
                    items: this.selectedItems,
                },
            };
        });
    }
    evh_6466705138206980_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_script_4840_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.grid.reload();
        });
    }
    act_8320016629450276_script_4840_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8320016629450276_script_4840_getActionArgs_value($event),
            };
        });
    }
    act_8320016629450276_request_4838_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "bulk_delete_dunning_letter",
                data: this.selectedItems.map(p => ({ id: p.id, etag: null })),
            };
        });
    }
    evh_8320016629450276_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4828 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4828_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4827 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4828,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4827],
        });
        const act_2248226175642056_setData_4830 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_4830_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_4829 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_4830,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_4829],
        });
        const act_2248226175642056_reloadSlickgrid_4834 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_4834_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_success_4833 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_4834,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_request_4832 = new core_1.actions.RequestAction({
            actionArgs: this.act_2248226175642056_request_4832_getActionArgs,
            events: [evh_2248226175642056_success_4833],
        });
        const evh_2248226175642056_contextmenuRemove_4831 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_request_4832,
            event: "contextmenu:remove",
            displayName: "request",
        });
        const evh_2248226175642056_contextmenuRemove = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_contextmenuRemove_4831],
        });
        const act_6466705138206980_showModal_4836 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6466705138206980_showModal_4836_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_click_4835 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_showModal_4836,
            event: "click",
            displayName: "showModal",
        });
        const evh_6466705138206980_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_click_4835],
        });
        const act_8320016629450276_script_4840 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8320016629450276_script_4840_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_success_4839 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_script_4840,
            event: "success",
            displayName: "script",
        });
        const act_8320016629450276_request_4838 = new core_1.actions.RequestAction({
            actionArgs: this.act_8320016629450276_request_4838_getActionArgs,
            displayName: "deleteItems",
            events: [evh_8320016629450276_success_4839],
        });
        const evh_8320016629450276_click_4837 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_request_4838,
            event: "click",
            displayName: "deleteItems",
        });
        const evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_4837],
        });
        return {
            act_7315092382398562_closeModal_4828,
            evh_7315092382398562_close_4827,
            evh_7315092382398562_close,
            act_2248226175642056_setData_4830,
            evh_2248226175642056_selectedRowsChanged_4829,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_reloadSlickgrid_4834,
            evh_2248226175642056_success_4833,
            act_2248226175642056_request_4832,
            evh_2248226175642056_contextmenuRemove_4831,
            evh_2248226175642056_contextmenuRemove,
            act_6466705138206980_showModal_4836,
            evh_6466705138206980_click_4835,
            evh_6466705138206980_click,
            act_8320016629450276_script_4840,
            evh_8320016629450276_success_4839,
            act_8320016629450276_request_4838,
            evh_8320016629450276_click_4837,
            evh_8320016629450276_click,
            deleteItems: act_8320016629450276_request_4838,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], DunningLetters.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DunningLetters.prototype, "onSize", null);
DunningLetters = __decorate([
    (0, vue_property_decorator_1.Component)()
], DunningLetters);
exports.default = DunningLetters;
vue_property_decorator_1.Vue.component("DunningLetters", DunningLetters);
