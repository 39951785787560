"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcValidityPeriod = class UcValidityPeriod extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['defaultFromTooltip', 'defaultToTooltip', 'defaultMissingFromMessage', 'defaultMissingToMessage', 'ux'];
    }
    beforeCreate() {
    }
    onFrom(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("from");
    }
    onTo(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("to");
    }
    onFrom_tooltip(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("fromTooltip");
    }
    onTo_tooltip(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("toTooltip");
    }
    onMissing_from_message(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("missingFromMessage");
    }
    onMissing_to_message(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("missingToMessage");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            defaultFromTooltip: this.$fn.pgettext("ValidityPeriod", "Valid from"),
            defaultToTooltip: this.$fn.pgettext("ValidityPeriod", "Valid from"),
            defaultMissingFromMessage: this.$fn.pgettext("UcValidityPeriod", "N/A"),
            defaultMissingToMessage: this.$fn.pgettext("UcValidityPeriod", "Indefinite"),
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_1922 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_1921 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1922,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1921],
        });
        return {
            act_7315092382398562_reloadComponentData_1922,
            evh_7315092382398562_reload_1921,
            evh_7315092382398562_reload,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
    }),
    __metadata("design:type", typeof (_a = typeof date !== "undefined" && date) === "function" ? _a : Object)
], UcValidityPeriod.prototype, "from", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('from'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcValidityPeriod.prototype, "onFrom", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({}),
    __metadata("design:type", typeof (_b = typeof date !== "undefined" && date) === "function" ? _b : Object)
], UcValidityPeriod.prototype, "to", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('to'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcValidityPeriod.prototype, "onTo", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcValidityPeriod.prototype, "fromTooltip", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('fromTooltip'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcValidityPeriod.prototype, "onFrom_tooltip", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcValidityPeriod.prototype, "toTooltip", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('toTooltip'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcValidityPeriod.prototype, "onTo_tooltip", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcValidityPeriod.prototype, "missingFromMessage", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('missingFromMessage'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcValidityPeriod.prototype, "onMissing_from_message", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcValidityPeriod.prototype, "missingToMessage", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('missingToMessage'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcValidityPeriod.prototype, "onMissing_to_message", null);
UcValidityPeriod = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcValidityPeriod);
exports.default = UcValidityPeriod;
vue_property_decorator_1.Vue.component("UcValidityPeriod", UcValidityPeriod);
