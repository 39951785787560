"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoicePayments = class UcInvoicePayments extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['triggerButtonID', 'ux', 'b_6466705138206980_style'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onReload(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("reload");
    }
    $$load_triggerButtonID() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            triggerButtonID: null,
            ux: null,
            b_6466705138206980_style: {
                "min-width": "150px",
            },
        });
    }
    get items() {
        if (this.loaded) {
            return this.invoice.payments.data;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_4592790460410433_showModal_5208_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    oid: $event.scope.item.payment_id,
                },
            };
        });
    }
    evh_4592790460410433_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4592790460410433_click.executeFromDOM(this, event, scope);
        });
    }
    act_322074727583344_resource_5210_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "patch",
                target: this.invoice,
                data: {
                    no_payment_required: $event.data.checked ? 'true' : null,
                },
            };
        });
    }
    evh_322074727583344_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_322074727583344_change.executeFromDOM(this, event, scope);
        });
    }
    act_5807883900791941_resource_5212_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "patch",
                target: this.invoice,
                data: {
                    uncollectible: $event.data.checked ? 'true' : null,
                },
            };
        });
    }
    evh_5807883900791941_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5807883900791941_change.executeFromDOM(this, event, scope);
        });
    }
    act_1984986731969919_emit_5216_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1984986731969919_close_5215_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_1984986731969919_showModal_5214_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: this.invoice.invoice_kind == 'incoming' ? 'payment' : 'deposit',
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_1984986731969919_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1984986731969919_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_298505142589676_emit_5220_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_298505142589676_close_5219_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_298505142589676_showModal_5218_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: this.invoice.invoice_kind == 'incoming' ? 'debit' : 'credit',
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_298505142589676_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_298505142589676_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5738488038990219_emit_5224_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_5738488038990219_close_5223_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_5738488038990219_showModal_5222_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "reconciliation_of_invoices",
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_5738488038990219_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5738488038990219_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7481595298497917_emit_5228_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7481595298497917_close_5227_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_7481595298497917_showModal_5226_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "bad_debt_journal",
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_7481595298497917_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7481595298497917_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5206 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5205 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5206,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5205],
        });
        const act_4592790460410433_showModal_5208 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4592790460410433_showModal_5208_getActionArgs,
            displayName: "OpenPayment",
            events: [],
        });
        const evh_4592790460410433_click_5207 = new core_1.actions.EventHandlerImpl({
            action: act_4592790460410433_showModal_5208,
            event: "click",
            displayName: "OpenPayment",
        });
        const evh_4592790460410433_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4592790460410433_click_5207],
        });
        const act_322074727583344_resource_5210 = new core_1.actions.ResourceAction({
            actionArgs: this.act_322074727583344_resource_5210_getActionArgs,
            events: [],
        });
        const evh_322074727583344_change_5209 = new core_1.actions.EventHandlerImpl({
            action: act_322074727583344_resource_5210,
            event: "change",
            displayName: "resource",
        });
        const evh_322074727583344_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_322074727583344_change_5209],
        });
        const act_5807883900791941_resource_5212 = new core_1.actions.ResourceAction({
            actionArgs: this.act_5807883900791941_resource_5212_getActionArgs,
            events: [],
        });
        const evh_5807883900791941_change_5211 = new core_1.actions.EventHandlerImpl({
            action: act_5807883900791941_resource_5212,
            event: "change",
            displayName: "resource",
        });
        const evh_5807883900791941_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5807883900791941_change_5211],
        });
        const act_1984986731969919_emit_5216 = new core_1.actions.EmitAction({
            actionArgs: this.act_1984986731969919_emit_5216_getActionArgs,
            events: [],
        });
        const evh_1984986731969919_close_5215 = new core_1.actions.EventHandlerImpl({
            action: act_1984986731969919_emit_5216,
            event: "close",
            when: this.evh_1984986731969919_close_5215_getWhen,
            displayName: "emit",
        });
        const act_1984986731969919_showModal_5214 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1984986731969919_showModal_5214_getActionArgs,
            displayName: "createCashPayment",
            events: [evh_1984986731969919_close_5215],
        });
        const evh_1984986731969919_clickPrevent_5213 = new core_1.actions.EventHandlerImpl({
            action: act_1984986731969919_showModal_5214,
            event: "click.prevent",
            displayName: "createCashPayment",
        });
        const evh_1984986731969919_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1984986731969919_clickPrevent_5213],
        });
        const act_298505142589676_emit_5220 = new core_1.actions.EmitAction({
            actionArgs: this.act_298505142589676_emit_5220_getActionArgs,
            events: [],
        });
        const evh_298505142589676_close_5219 = new core_1.actions.EventHandlerImpl({
            action: act_298505142589676_emit_5220,
            event: "close",
            when: this.evh_298505142589676_close_5219_getWhen,
            displayName: "emit",
        });
        const act_298505142589676_showModal_5218 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_298505142589676_showModal_5218_getActionArgs,
            displayName: "createBankingPayment",
            events: [evh_298505142589676_close_5219],
        });
        const evh_298505142589676_clickPrevent_5217 = new core_1.actions.EventHandlerImpl({
            action: act_298505142589676_showModal_5218,
            event: "click.prevent",
            displayName: "createBankingPayment",
        });
        const evh_298505142589676_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_298505142589676_clickPrevent_5217],
        });
        const act_5738488038990219_emit_5224 = new core_1.actions.EmitAction({
            actionArgs: this.act_5738488038990219_emit_5224_getActionArgs,
            events: [],
        });
        const evh_5738488038990219_close_5223 = new core_1.actions.EventHandlerImpl({
            action: act_5738488038990219_emit_5224,
            event: "close",
            when: this.evh_5738488038990219_close_5223_getWhen,
            displayName: "emit",
        });
        const act_5738488038990219_showModal_5222 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5738488038990219_showModal_5222_getActionArgs,
            displayName: "createReconciliation",
            events: [evh_5738488038990219_close_5223],
        });
        const evh_5738488038990219_clickPrevent_5221 = new core_1.actions.EventHandlerImpl({
            action: act_5738488038990219_showModal_5222,
            event: "click.prevent",
            displayName: "createReconciliation",
        });
        const evh_5738488038990219_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5738488038990219_clickPrevent_5221],
        });
        const act_7481595298497917_emit_5228 = new core_1.actions.EmitAction({
            actionArgs: this.act_7481595298497917_emit_5228_getActionArgs,
            events: [],
        });
        const evh_7481595298497917_close_5227 = new core_1.actions.EventHandlerImpl({
            action: act_7481595298497917_emit_5228,
            event: "close",
            when: this.evh_7481595298497917_close_5227_getWhen,
            displayName: "emit",
        });
        const act_7481595298497917_showModal_5226 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7481595298497917_showModal_5226_getActionArgs,
            displayName: "createBadDebt",
            events: [evh_7481595298497917_close_5227],
        });
        const evh_7481595298497917_clickPrevent_5225 = new core_1.actions.EventHandlerImpl({
            action: act_7481595298497917_showModal_5226,
            event: "click.prevent",
            displayName: "createBadDebt",
        });
        const evh_7481595298497917_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7481595298497917_clickPrevent_5225],
        });
        return {
            act_7315092382398562_reloadComponentData_5206,
            evh_7315092382398562_reload_5205,
            evh_7315092382398562_reload,
            act_4592790460410433_showModal_5208,
            evh_4592790460410433_click_5207,
            evh_4592790460410433_click,
            act_322074727583344_resource_5210,
            evh_322074727583344_change_5209,
            evh_322074727583344_change,
            act_5807883900791941_resource_5212,
            evh_5807883900791941_change_5211,
            evh_5807883900791941_change,
            act_1984986731969919_emit_5216,
            evh_1984986731969919_close_5215,
            act_1984986731969919_showModal_5214,
            evh_1984986731969919_clickPrevent_5213,
            evh_1984986731969919_clickPrevent,
            act_298505142589676_emit_5220,
            evh_298505142589676_close_5219,
            act_298505142589676_showModal_5218,
            evh_298505142589676_clickPrevent_5217,
            evh_298505142589676_clickPrevent,
            act_5738488038990219_emit_5224,
            evh_5738488038990219_close_5223,
            act_5738488038990219_showModal_5222,
            evh_5738488038990219_clickPrevent_5221,
            evh_5738488038990219_clickPrevent,
            act_7481595298497917_emit_5228,
            evh_7481595298497917_close_5227,
            act_7481595298497917_showModal_5226,
            evh_7481595298497917_clickPrevent_5225,
            evh_7481595298497917_clickPrevent,
            OpenPayment: act_4592790460410433_showModal_5208,
            createCashPayment: act_1984986731969919_showModal_5214,
            createBankingPayment: act_298505142589676_showModal_5218,
            createReconciliation: act_5738488038990219_showModal_5222,
            createBadDebt: act_7481595298497917_showModal_5226,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoicePayments.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoicePayments.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        default: null,
    }),
    __metadata("design:type", Object)
], UcInvoicePayments.prototype, "reload", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('reload'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoicePayments.prototype, "onReload", null);
UcInvoicePayments = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoicePayments);
exports.default = UcInvoicePayments;
vue_property_decorator_1.Vue.component("UcInvoicePayments", UcInvoicePayments);
