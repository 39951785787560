"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcPropertyUnits = class UcPropertyUnits extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    onParent(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("parent");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_showModal_5660_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "PropertyUnitEditor",
                props: {
                    mode: "add",
                    parent: this.parent,
                },
            };
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2585509888553580_emit_5664_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_2585509888553580_close_5663_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_2585509888553580_showModal_5662_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "PropertyUnitEditor",
                props: {
                    parent: this.parent,
                    item: $event.scope.item,
                },
            };
        });
    }
    evh_2585509888553580_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2585509888553580_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5658 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5657 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5658,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5657],
        });
        const act_8320016629450276_showModal_5660 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_5660_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_clickPreventStop_5659 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_5660,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_5659],
        });
        const act_2585509888553580_emit_5664 = new core_1.actions.EmitAction({
            actionArgs: this.act_2585509888553580_emit_5664_getActionArgs,
            events: [],
        });
        const evh_2585509888553580_close_5663 = new core_1.actions.EventHandlerImpl({
            action: act_2585509888553580_emit_5664,
            event: "close",
            when: this.evh_2585509888553580_close_5663_getWhen,
            displayName: "emit",
        });
        const act_2585509888553580_showModal_5662 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2585509888553580_showModal_5662_getActionArgs,
            events: [evh_2585509888553580_close_5663],
        });
        const evh_2585509888553580_clickPreventStop_5661 = new core_1.actions.EventHandlerImpl({
            action: act_2585509888553580_showModal_5662,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_2585509888553580_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2585509888553580_clickPreventStop_5661],
        });
        return {
            act_7315092382398562_reloadComponentData_5658,
            evh_7315092382398562_reload_5657,
            evh_7315092382398562_reload,
            act_8320016629450276_showModal_5660,
            evh_8320016629450276_clickPreventStop_5659,
            evh_8320016629450276_clickPreventStop,
            act_2585509888553580_emit_5664,
            evh_2585509888553580_close_5663,
            act_2585509888553580_showModal_5662,
            evh_2585509888553580_clickPreventStop_5661,
            evh_2585509888553580_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcPropertyUnits.prototype, "parent", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcPropertyUnits.prototype, "onParent", null);
UcPropertyUnits = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcPropertyUnits);
exports.default = UcPropertyUnits;
vue_property_decorator_1.Vue.component("UcPropertyUnits", UcPropertyUnits);
