"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "accountsDailyFlow",
    path: "/accounts-daily-flow",
    component: "AccountsDailyFlow"
});
let AccountsDailyFlow = class AccountsDailyFlow extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'accounts', 'parameters', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
        this.$$cache_parameters = new core_1.ComponentValueCache(this, "parameters", "AccountsDailyFlowViewFilters");
    }
    getItems(...args) {
        const parameters = this.parameters;
        return this.$fn.fetch('browse_accounts_daily_flow', {
            parameters: Object.assign(Object.assign({}, parameters), { account: parameters.account.id })
        });
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_accounts() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_account')
                .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']));
        });
    }
    $$load_parameters() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                date_from: null,
                date_to: null,
                account: this.accounts ? this.accounts[0] : null,
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            accounts: null,
            parameters: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "date",
                    name: this.$fn.pgettext("AccountsDailyFlowView", "Date"),
                },
                {
                    field: "open",
                    name: this.$fn.pgettext("AccountsDailyFlowView", "Opening balance"),
                    type: "number",
                    noFooterTotals: true,
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "pay_in",
                    name: this.$fn.pgettext("AccountsDailyFlowView", "Pay in"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "pay_out",
                    name: this.$fn.pgettext("AccountsDailyFlowView", "Pay out"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "close",
                    name: this.$fn.pgettext("AccountsDailyFlowView", "Closing balance"),
                    type: "number",
                    noFooterTotals: true,
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("AccountsDailyFlowView", "Daily Flow"),
                icon: this.$config.accountsDailyFlow.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5504_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_5506_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_showModal_5508_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Account",
                props: {
                    oid: $event.data.data.account_id,
                },
            };
        });
    }
    evh_2248226175642056_command_5507_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'account_name';
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_reloadSlickgrid_5510_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "accountsDailyFlow",
            };
        });
    }
    evh_6466705138206980_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_setData_5512_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "parameters.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_8320016629450276_setData_5514_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "parameters.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_8320016629450276_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_changed.executeFromDOM(this, event, scope);
        });
    }
    act_6705964765214521_reloadSlickgrid_5516_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "accountsDailyFlow",
            };
        });
    }
    evh_6705964765214521_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6705964765214521_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5504 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5504_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5503 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5504,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5503],
        });
        const act_2248226175642056_setData_5506 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5506_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_5505 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5506,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_5505],
        });
        const act_2248226175642056_showModal_5508 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_5508_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_command_5507 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_5508,
            event: "command",
            when: this.evh_2248226175642056_command_5507_getWhen,
            displayName: "showModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_5507],
        });
        const act_6466705138206980_reloadSlickgrid_5510 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_6466705138206980_reloadSlickgrid_5510_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_optionSelected_5509 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_reloadSlickgrid_5510,
            event: "optionSelected",
            displayName: "reloadSlickgrid",
        });
        const evh_6466705138206980_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_optionSelected_5509],
        });
        const act_8320016629450276_setData_5512 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8320016629450276_setData_5512_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_changed_5511 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_setData_5512,
            event: "changed",
            displayName: "setData",
        });
        const act_8320016629450276_setData_5514 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8320016629450276_setData_5514_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_changed_5513 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_setData_5514,
            event: "changed",
            displayName: "setData",
        });
        const evh_8320016629450276_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_changed_5511, evh_8320016629450276_changed_5513],
        });
        const act_6705964765214521_reloadSlickgrid_5516 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_6705964765214521_reloadSlickgrid_5516_getActionArgs,
            events: [],
        });
        const evh_6705964765214521_clickPreventStop_5515 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_reloadSlickgrid_5516,
            event: "click.prevent.stop",
            displayName: "reloadSlickgrid",
        });
        const evh_6705964765214521_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6705964765214521_clickPreventStop_5515],
        });
        return {
            act_7315092382398562_closeModal_5504,
            evh_7315092382398562_close_5503,
            evh_7315092382398562_close,
            act_2248226175642056_setData_5506,
            evh_2248226175642056_selectedRowsChanged_5505,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_showModal_5508,
            evh_2248226175642056_command_5507,
            evh_2248226175642056_command,
            act_6466705138206980_reloadSlickgrid_5510,
            evh_6466705138206980_optionSelected_5509,
            evh_6466705138206980_optionSelected,
            act_8320016629450276_setData_5512,
            evh_8320016629450276_changed_5511,
            act_8320016629450276_setData_5514,
            evh_8320016629450276_changed_5513,
            evh_8320016629450276_changed,
            act_6705964765214521_reloadSlickgrid_5516,
            evh_6705964765214521_clickPreventStop_5515,
            evh_6705964765214521_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], AccountsDailyFlow.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], AccountsDailyFlow.prototype, "onSize", null);
AccountsDailyFlow = __decorate([
    (0, vue_property_decorator_1.Component)()
], AccountsDailyFlow);
exports.default = AccountsDailyFlow;
vue_property_decorator_1.Vue.component("AccountsDailyFlow", AccountsDailyFlow);
