"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let JobHeader = class JobHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux', 'b_1382594685840889_style'];
    }
    beforeCreate() {
    }
    onItem(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                title: this.$fn.pgettext("Job|ModalHeader", "Job"),
            },
            ux: null,
            b_1382594685840889_style: {
                maxWidth: "80px",
            },
        });
    }
    get resourceTitle() {
        if (this.loaded) {
            return this.$fn.pgettext("Job|ModalHeader", "Job");
        }
        return null;
    }
    get resourceKey() {
        if (this.loaded) {
            return '#' + this.item.number;
        }
        return null;
    }
    act_2754826507016131_emit_5360_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_2754826507016131_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2754826507016131_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7463771596263925_emit_5364_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_7463771596263925_request_5362_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                url: this.item.links.retry,
                method: "PATCH",
                headers: {
                    "If-Match": this.item.etag,
                },
            };
        });
    }
    evh_7463771596263925_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7463771596263925_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7763705103899431_emit_5368_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_7763705103899431_request_5366_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                url: this.item.links.cancel,
                method: "PATCH",
                headers: {
                    "If-Match": this.item.etag,
                },
            };
        });
    }
    evh_7763705103899431_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7763705103899431_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_2754826507016131_emit_5360 = new core_1.actions.EmitAction({
            actionArgs: this.act_2754826507016131_emit_5360_getActionArgs,
            displayName: "reload",
            events: [],
        });
        const evh_2754826507016131_clickPreventStop_5359 = new core_1.actions.EventHandlerImpl({
            action: act_2754826507016131_emit_5360,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_2754826507016131_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2754826507016131_clickPreventStop_5359],
        });
        const act_7463771596263925_emit_5364 = new core_1.actions.EmitAction({
            actionArgs: this.act_7463771596263925_emit_5364_getActionArgs,
            events: [],
        });
        const evh_7463771596263925_success_5363 = new core_1.actions.EventHandlerImpl({
            action: act_7463771596263925_emit_5364,
            event: "success",
            displayName: "emit",
        });
        const act_7463771596263925_request_5362 = new core_1.actions.RequestAction({
            actionArgs: this.act_7463771596263925_request_5362_getActionArgs,
            displayName: "retryJob",
            events: [evh_7463771596263925_success_5363],
        });
        const evh_7463771596263925_clickPreventStop_5361 = new core_1.actions.EventHandlerImpl({
            action: act_7463771596263925_request_5362,
            event: "click.prevent.stop",
            displayName: "retryJob",
        });
        const evh_7463771596263925_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7463771596263925_clickPreventStop_5361],
        });
        const act_7763705103899431_emit_5368 = new core_1.actions.EmitAction({
            actionArgs: this.act_7763705103899431_emit_5368_getActionArgs,
            events: [],
        });
        const evh_7763705103899431_success_5367 = new core_1.actions.EventHandlerImpl({
            action: act_7763705103899431_emit_5368,
            event: "success",
            displayName: "emit",
        });
        const act_7763705103899431_request_5366 = new core_1.actions.RequestAction({
            actionArgs: this.act_7763705103899431_request_5366_getActionArgs,
            displayName: "cancelJob",
            events: [evh_7763705103899431_success_5367],
        });
        const evh_7763705103899431_clickPreventStop_5365 = new core_1.actions.EventHandlerImpl({
            action: act_7763705103899431_request_5366,
            event: "click.prevent.stop",
            displayName: "cancelJob",
        });
        const evh_7763705103899431_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7763705103899431_clickPreventStop_5365],
        });
        return {
            act_2754826507016131_emit_5360,
            evh_2754826507016131_clickPreventStop_5359,
            evh_2754826507016131_clickPreventStop,
            act_7463771596263925_emit_5364,
            evh_7463771596263925_success_5363,
            act_7463771596263925_request_5362,
            evh_7463771596263925_clickPreventStop_5361,
            evh_7463771596263925_clickPreventStop,
            act_7763705103899431_emit_5368,
            evh_7763705103899431_success_5367,
            act_7763705103899431_request_5366,
            evh_7763705103899431_clickPreventStop_5365,
            evh_7763705103899431_clickPreventStop,
            reload: act_2754826507016131_emit_5360,
            retryJob: act_7463771596263925_request_5362,
            cancelJob: act_7763705103899431_request_5366,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], JobHeader.prototype, "item", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], JobHeader.prototype, "onItem", null);
JobHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], JobHeader);
exports.default = JobHeader;
vue_property_decorator_1.Vue.component("JobHeader", JobHeader);
