"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FieldsetPaymentGeneral = class FieldsetPaymentGeneral extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['paymentTypes', 'cashPaymentTypes', 'accounts', 'categories', 'paymentMethods', 'ux', 'b_8697745105058001_dataSource'];
    }
    beforeCreate() {
    }
    onPayment(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payment");
    }
    onLabel_align(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    }
    $$load_accounts() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_account')
                .then(p => _
                .chain(p.data)
                .filter({ account_type: this.payment.account.account_type })
                .orderBy(['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc'])
                .value());
        });
    }
    $$load_categories() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_tags', { parameters: { tag_type: 'payment' } }).then(data => _.map(data, p => p.name));
        });
    }
    $$load_paymentMethods() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_payment_method')
                .then(data => _
                .chain(data)
                .filter(p => this.payment.account.account_type !== 'cash_book' ? !p.cash : p.cash)
                .map(p => ({
                value: p.id,
                text: this.$fn.pgettext('PaymentMethod', p.name)
            }))
                .value());
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            paymentTypes: {
                credit: this.$fn.pgettext("PaymentType", "credit"),
                debit: this.$fn.pgettext("PaymentType", "debit"),
            },
            cashPaymentTypes: {
                deposit: this.$fn.pgettext("CashBookPaymentType", "deposit"),
                payment: this.$fn.pgettext("CashBookPaymentType", "payment"),
            },
            accounts: null,
            categories: null,
            paymentMethods: null,
            ux: null,
            b_8697745105058001_dataSource: {
                name: "suggest_client",
            },
        });
    }
    get isReadOnly() {
        if (this.loaded) {
            return this.payment.isReadonly;
        }
        return null;
    }
    get cashBookPayment() {
        if (this.loaded) {
            return this.payment.account && this.payment.account.account_type == 'cash_book';
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_4615685607825208_setData_5452_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "payment.currency",
                value: $event.data.option.currency,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_4615685607825208_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4615685607825208_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5450 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5449 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5450,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5449],
        });
        const act_4615685607825208_setData_5452 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4615685607825208_setData_5452_getActionArgs,
            events: [],
        });
        const evh_4615685607825208_optionSelected_5451 = new core_1.actions.EventHandlerImpl({
            action: act_4615685607825208_setData_5452,
            event: "optionSelected",
            displayName: "setData",
        });
        const evh_4615685607825208_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4615685607825208_optionSelected_5451],
        });
        return {
            act_7315092382398562_reloadComponentData_5450,
            evh_7315092382398562_reload_5449,
            evh_7315092382398562_reload,
            act_4615685607825208_setData_5452,
            evh_4615685607825208_optionSelected_5451,
            evh_4615685607825208_optionSelected,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetPaymentGeneral.prototype, "payment", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('payment'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetPaymentGeneral.prototype, "onPayment", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "right",
    }),
    __metadata("design:type", String)
], FieldsetPaymentGeneral.prototype, "labelAlign", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('labelAlign'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetPaymentGeneral.prototype, "onLabel_align", null);
FieldsetPaymentGeneral = __decorate([
    (0, vue_property_decorator_1.Component)()
], FieldsetPaymentGeneral);
exports.default = FieldsetPaymentGeneral;
vue_property_decorator_1.Vue.component("FieldsetPaymentGeneral", FieldsetPaymentGeneral);
