<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
  >
    <ItpText
      :strong="!noStrong"
      :text="item.serial_number"
      class="mr-2"
      v-if="noLink || !linkStyleUnderline"
    >
    </ItpText>
    <ItpLink
      :to="{ name: 'app.product', params: { id: item.id } }"
      v-if="!noLink && linkStyleUnderline"
    >
      <ItpText
        :strong="!noStrong"
        :text="item.serial_number"
        class="mr-2"
      >
      </ItpText>
    </ItpLink>
    <ItpLink
      :icon="$config.externalLinkIcon"
      :to="{ name: 'app.product', params: { id: item.id } }"
      v-if="!noLink && linkStyleIcon && !linkStyleUnderline"
    >
    </ItpLink>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcProductMiniCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noLink!: boolean;

  @Watch('noLink')
  onNo_link(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLink")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noStrong!: boolean;

  @Watch('noStrong')
  onNo_strong(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noStrong")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  linkStyleUnderline!: boolean;

  @Watch('linkStyleUnderline')
  onLink_style_underline(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("linkStyleUnderline")
  }

  @Prop({
    type: Boolean,
    default: true,
  })
  linkStyleIcon!: boolean;

  @Watch('linkStyleIcon')
  onLink_style_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("linkStyleIcon")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5632: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5631: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5632 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5631 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5632,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5631],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5632,
      evh_7315092382398562_reload_5631,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcProductMiniCard", UcProductMiniCard);

</script>