"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let LetterRegisterEntryHeader = class LetterRegisterEntryHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux'];
    }
    beforeCreate() {
    }
    onRegister(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("register");
    }
    onEntry(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("entry");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                newTransaction: this.$fn.pgettext("LetterRegister", "New entry"),
            },
            ux: null,
        });
    }
    get resourceTitle() {
        if (this.loaded) {
            return this.register.name;
        }
        return null;
    }
    get resourceKey() {
        if (this.loaded) {
            return this.entry.isNew ? this.messages.newTransaction : this.entry.entry_number;
        }
        return null;
    }
    act_1040167445267876_emit_2762_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1040167445267876_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_4942415580156499_emit_2764_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_4942415580156499_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4942415580156499_success.executeFromDOM(this, event, scope);
        });
    }
    act_49312645155713_emit_2766_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "deleted",
            };
        });
    }
    evh_49312645155713_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_49312645155713_success.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_1040167445267876_emit_2762 = new core_1.actions.EmitAction({
            actionArgs: this.act_1040167445267876_emit_2762_getActionArgs,
            displayName: "reload",
            events: [],
        });
        const evh_1040167445267876_clickPreventStop_2761 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_emit_2762,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_1040167445267876_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_clickPreventStop_2761],
        });
        const act_4942415580156499_emit_2764 = new core_1.actions.EmitAction({
            actionArgs: this.act_4942415580156499_emit_2764_getActionArgs,
            events: [],
        });
        const evh_4942415580156499_success_2763 = new core_1.actions.EventHandlerImpl({
            action: act_4942415580156499_emit_2764,
            event: "success",
            displayName: "emit",
        });
        const evh_4942415580156499_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4942415580156499_success_2763],
        });
        const act_49312645155713_emit_2766 = new core_1.actions.EmitAction({
            actionArgs: this.act_49312645155713_emit_2766_getActionArgs,
            events: [],
        });
        const evh_49312645155713_success_2765 = new core_1.actions.EventHandlerImpl({
            action: act_49312645155713_emit_2766,
            event: "success",
            displayName: "emit",
        });
        const evh_49312645155713_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_49312645155713_success_2765],
        });
        return {
            act_1040167445267876_emit_2762,
            evh_1040167445267876_clickPreventStop_2761,
            evh_1040167445267876_clickPreventStop,
            act_4942415580156499_emit_2764,
            evh_4942415580156499_success_2763,
            evh_4942415580156499_success,
            act_49312645155713_emit_2766,
            evh_49312645155713_success_2765,
            evh_49312645155713_success,
            reload: act_1040167445267876_emit_2762,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], LetterRegisterEntryHeader.prototype, "register", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('register'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], LetterRegisterEntryHeader.prototype, "onRegister", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], LetterRegisterEntryHeader.prototype, "entry", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('entry'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], LetterRegisterEntryHeader.prototype, "onEntry", null);
LetterRegisterEntryHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], LetterRegisterEntryHeader);
exports.default = LetterRegisterEntryHeader;
vue_property_decorator_1.Vue.component("LetterRegisterEntryHeader", LetterRegisterEntryHeader);
