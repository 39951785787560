<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="AccountsView"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="accounts"
    :headerTitle="'Cash and Bank Accounts'|pgettext('AccountsView')"
    :items="$fn.slickDataSource('list_account')"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'accounts'|pgettext('ExportFilename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        :icon="$config.account.createAccountIcon"
        :text="'Add Account'|pgettext('AccountsView')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/finance/accounts.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "accounts",
    path: "/accounts",
    component: "AccountsView"
  }
)

@Component()
export default class AccountsView extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "owner_name",
          name: this.$fn.pgettext("AccountsView", "Owner"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "company",
            navigateOnIconClick: true,
            params: {
              id: "owner_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "name",
          name: this.$fn.pgettext("AccountsView", "Name"),
          type: "command",
          formatter: {
            name: "link",
            href: "#",
          }
          ,
        }
          ,
        {
          field: "account_type",
          name: this.$fn.pgettext("AccountsView", "Type"),
          formatter: {
            name: "gettext",
            context: "AccountTypes",
            conversion: "Sentence",
            filterInput: true,
          }
          ,
        }
          ,
        {
          field: "currency",
          name: this.$fn.pgettext("AccountsView", "Currency"),
        }
          ,
        {
          field: "description",
          name: this.$fn.pgettext("AccountsView", "Description"),
        }
          ,
        {
          field: "account_number",
          name: this.$fn.pgettext("AccountsView", "Account nr"),
        }
          ,
        {
          field: "bank",
          name: this.$fn.pgettext("AccountsView", "Bank"),
        }
          ,
        {
          field: "iban",
          name: this.$fn.pgettext("AccountsView", "IBAN"),
        }
          ,
        {
          field: "swift",
          name: this.$fn.pgettext("AccountsView", "SWIFT"),
        }
          ,
        {
          field: "branch",
          name: this.$fn.pgettext("AccountsView", "Branch"),
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("AccountsView", "Cash and Bank Accounts"),
        icon: this.$config.account.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1324_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1324, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_1326_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_1326, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_showModal_1328_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_1328, alias=undefined
    return {
      name: "Account",
      props: {
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_1327_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_1327, alias=undefined
    return $event.data.column.id === 'name'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_1330_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_1330, alias=undefined
    return {
      name: "Account",
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1324: actions.CloseModalAction;
    evh_7315092382398562_close_1323: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_1326: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_1325: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_showModal_1328: actions.ShowModalAction;
    evh_2248226175642056_command_1327: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_6466705138206980_showModal_1330: actions.ShowModalAction;
    evh_6466705138206980_click_1329: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1324 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1324_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1323 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1324,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1323],
      }
    );
    const act_2248226175642056_setData_1326 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_1326_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_1325 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_1326,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_1325],
      }
    );
    const act_2248226175642056_showModal_1328 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_1328_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_command_1327 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_1328,
        event: "command",
        when: this.evh_2248226175642056_command_1327_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_1327],
      }
    );
    const act_6466705138206980_showModal_1330 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_1330_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_1329 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_1330,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_1329],
      }
    );
    return {
      act_7315092382398562_closeModal_1324,
      evh_7315092382398562_close_1323,
      evh_7315092382398562_close,
      act_2248226175642056_setData_1326,
      evh_2248226175642056_selectedRowsChanged_1325,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_showModal_1328,
      evh_2248226175642056_command_1327,
      evh_2248226175642056_command,
      act_6466705138206980_showModal_1330,
      evh_6466705138206980_click_1329,
      evh_6466705138206980_click,
    }
  }
}

Vue.component("AccountsView", AccountsView);

</script>