<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="bin_type"
    :label="'Type'|pgettext('FixedAssetWasteBin')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="groups"
      size="md"
      v-model="fixedAsset.waste_bin.bin_type"
      :options="binTypes"
      valueField="id"
      textField="name"
      required
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="bin_number"
    :label="'Number'|pgettext('FixedAssetWasteBin')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      type="number"
      name="bin_number"
      v-model="fixedAsset.waste_bin.bin_number"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="capacity"
    :label="'Capacity'|pgettext('FixedAssetWasteBin')"
    :labelAlign="labelAlign"
    :appendText="'liter'|pgettext('InputAddon')"
    required
  >
    <ItpFormInput
      type="number"
      name="capacity"
      v-model="fixedAsset.waste_bin.capacity.amount"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetFixedAssetWasteBin extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  fixedAsset!: object;

  @Watch('fixedAsset')
  onFixed_asset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAsset")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  binTypes!: any;
  ux!: any;
  dataMembers = ['binTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        binTypes: [{
          id: "communal",
          name: this.$fn.pgettext("BinTypes", "Communal"),
        }
          ,
        {
          id: "paper",
          name: this.$fn.pgettext("BinTypes", "Paper"),
        }
          ,
        {
          id: "plastic",
          name: this.$fn.pgettext("BinTypes", "Plastic"),
        }
          ,
        ]
        ,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4344: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4343: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4344 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4343 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4344,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4343],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4344,
      evh_7315092382398562_reload_4343,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetFixedAssetWasteBin", FieldsetFixedAssetWasteBin);

</script>