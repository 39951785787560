<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
    class="mb-2"
  >
    <strong>
      {{ 'Invoices'|pgettext('Payment') }}
    </strong>
    <ItpButton
      :icon="$config.addIcon"
      :text="'Add invoice...'|pgettext('Payment')"
      size="sm"
      variant="light"
      class="ml-4"
      v-if="!payment.isReadonly"
      @click="evh_3343864469950044_click($event, {})"
    >
    </ItpButton>
    <DIV
      :title="'Invoice amount mismatch'|pgettext('Payment')"
      class="ml-4"
      v-if="invoiceTotalsDiff"
    >
      <Icon
        icon="emojione-v1:warning"
      >
      </Icon>
    </DIV>
  </ItpBox>
  <ItpDataTable
    rowKeyField="invoice_id"
    tableId="paymentInvoices"
    :paging="b_5962862420439144_paging"
    :editable="!payment.isReadonly"
    :sortable="b_5962862420439144_sortable"
    :value="payment.invoices.data"
    no-create-row
    no-confirm-delete-row
    footer-totals
    :columns="b_5962862420439144_columns"
    v-if="payment.invoices.data"
    @save-completed="evh_5962862420439144_saveCompleted($event, {})"
    @delete="evh_5962862420439144_delete($event, {})"
    @delete-completed="evh_5962862420439144_deleteCompleted($event, {})"
  >
  </ItpDataTable>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPaymentInvoices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  createInvoicePaymentData(...args: any[]) {
    const
      [data] = args,

    return {
      invoice_id: data.id,
      invoice_number: data.invoice_number,
      currency: data.currency,
      amount: data.unpaid,
      exchange_rate: 1,
      client: data.client,
      invoice: data
    };
  }

  @Prop({
    required: true,
    type: Object,
  })
  payment!: object;

  @Watch('payment')
  onPayment(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payment")
  }

  ux!: any;
  b_5962862420439144_paging!: any;
  b_5962862420439144_sortable!: any;
  b_5962862420439144_columns!: any;
  dataMembers = ['ux', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_5962862420439144_paging: false,
        b_5962862420439144_sortable: false,
        b_5962862420439144_columns: [{
          name: "invoice_number",
          width: "210px",
          header: this.$fn.pgettext("InvoicePayment", "Invoice"),
          readonly: true,
          textAlign: "left",
          type: "template",
          value: "<DIV>\n\
  <strong>{{ row.invoice.invoice_number }}</strong> <UcInvoiceTypeBadge :value=\"row.invoice.invoice_type\"></UcInvoiceTypeBadge><br/>\n\
  {{ row.invoice.invoice_date || date }}<br/>\n\
  <strong>{{ row.invoice.amount || row.invoice.total | number }} {{ row.invoice.currency }}</strong><br/>\n\
  <UcClientMiniCard :item=\"row.invoice.client\" no-link></UcClientMiniCard>\n\
</DIV>\n\
",
        }
          ,
        {
          name: "amount",
          header: this.$fn.pgettext("InvoicePayment", "Amount"),
          type: "number",
          width: "110px",
          textAlign: "right",
          required: true,
        }
          ,
        {
          name: "exchange_rate",
          width: "70px",
          header: this.$fn.pgettext("InvoicePayment", "Exch. Rate"),
          type: "number",
          textAlign: "right",
          required: true,
          noFooterTotals: true,
        }
          ,
        {
          name: "notes",
          width: "210px",
          header: this.$fn.pgettext("InvoicePayment", "Notes"),
        }
          ,
        ]
        ,
      },
    }
  }

  get invoiceTotals() {
    if (this.loaded) {

      return this.payment.invoices.data.length ? _.sumBy(this.payment.invoices.data, p => p.amount) : null
    }
    return null;
  }

  get invoiceTotalsDiff() {
    if (this.loaded) {

      return this.invoiceTotals != null ? (this.payment.amount - this.invoiceTotals) : null
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_3343864469950044_script_5480_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3343864469950044_script_5480, alias=undefined
    _.chain($event.data.modalResult.value)
      .map(this.createInvoicePaymentData)
      .filter(p => !_.some(this.payment.invoices.data, { invoice_id: p.invoice_id }))
      .each(p => this.payment.invoices.data.push(p))
      .value()
  }

  async act_3343864469950044_script_5480_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3343864469950044_script_5480, alias=undefined
    return {
      value: () => this.act_3343864469950044_script_5480_getActionArgs_value($event),
    }
  }

  async evh_3343864469950044_close_5479_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_5479, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_3343864469950044_emit_5482_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3343864469950044_emit_5482, alias=undefined
    return {
      event: "recalculate",
    }
  }

  async evh_3343864469950044_close_5481_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_5481, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_3343864469950044_showModal_5478_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_5478, alias=addInvoice
    return {
      name: "InvoiceBrowser",
      props: {
        size: "full-screen",
        company_id: this.payment.account && this.payment.account.owner_id,
        client_id: this.payment.client && this.payment.client.id,
        multiselect: true,
      }
      ,
    }
  }

  async evh_3343864469950044_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_emit_5484_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_5484, alias=undefined
    return {
      event: "recalculate",
      value: $event.data.result,
    }
  }

  async evh_5962862420439144_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_5486_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_5486, alias=undefined
    _.remove(this.payment.invoices.data, { invoice_id: $event.data.row.invoice_id })
  }

  async act_5962862420439144_script_5486_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_5486, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_5486_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_emit_5488_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_5488, alias=undefined
    return {
      event: "recalculate",
    }
  }

  async evh_5962862420439144_deleteCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_deleteCompleted.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5476: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5475: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_3343864469950044_script_5480: actions.ScriptAction;
    evh_3343864469950044_close_5479: actions.EventHandlerImpl;
    act_3343864469950044_emit_5482: actions.EmitAction;
    evh_3343864469950044_close_5481: actions.EventHandlerImpl;
    act_3343864469950044_showModal_5478: actions.ShowModalAction;
    evh_3343864469950044_click_5477: actions.EventHandlerImpl;
    evh_3343864469950044_click: actions.EventHandlerGroup;
    act_5962862420439144_emit_5484: actions.EmitAction;
    evh_5962862420439144_saveCompleted_5483: actions.EventHandlerImpl;
    evh_5962862420439144_saveCompleted: actions.EventHandlerGroup;
    act_5962862420439144_script_5486: actions.ScriptAction;
    evh_5962862420439144_delete_5485: actions.EventHandlerImpl;
    evh_5962862420439144_delete: actions.EventHandlerGroup;
    act_5962862420439144_emit_5488: actions.EmitAction;
    evh_5962862420439144_deleteCompleted_5487: actions.EventHandlerImpl;
    evh_5962862420439144_deleteCompleted: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5476 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5475 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5476,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5475],
      }
    );
    const act_3343864469950044_script_5480 = new actions.ScriptAction(
      {
        actionArgs: this.act_3343864469950044_script_5480_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_5479 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_script_5480,
        event: "close",
        when: this.evh_3343864469950044_close_5479_getWhen,
        displayName: "script",
      }
    );
    const act_3343864469950044_emit_5482 = new actions.EmitAction(
      {
        actionArgs: this.act_3343864469950044_emit_5482_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_5481 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_emit_5482,
        event: "close",
        when: this.evh_3343864469950044_close_5481_getWhen,
        displayName: "emit",
      }
    );
    const act_3343864469950044_showModal_5478 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_5478_getActionArgs,
        displayName: "addInvoice",
        events: [evh_3343864469950044_close_5479, evh_3343864469950044_close_5481],
      }
    );
    const evh_3343864469950044_click_5477 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_5478,
        event: "click",
        displayName: "addInvoice",
      }
    );
    const evh_3343864469950044_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_click_5477],
      }
    );
    const act_5962862420439144_emit_5484 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_5484_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_saveCompleted_5483 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_5484,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_saveCompleted_5483],
      }
    );
    const act_5962862420439144_script_5486 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_5486_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_delete_5485 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_5486,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_5962862420439144_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_delete_5485],
      }
    );
    const act_5962862420439144_emit_5488 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_5488_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_deleteCompleted_5487 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_5488,
        event: "delete-completed",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_deleteCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_deleteCompleted_5487],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5476,
      evh_7315092382398562_reload_5475,
      evh_7315092382398562_reload,
      act_3343864469950044_script_5480,
      evh_3343864469950044_close_5479,
      act_3343864469950044_emit_5482,
      evh_3343864469950044_close_5481,
      act_3343864469950044_showModal_5478,
      evh_3343864469950044_click_5477,
      evh_3343864469950044_click,
      act_5962862420439144_emit_5484,
      evh_5962862420439144_saveCompleted_5483,
      evh_5962862420439144_saveCompleted,
      act_5962862420439144_script_5486,
      evh_5962862420439144_delete_5485,
      evh_5962862420439144_delete,
      act_5962862420439144_emit_5488,
      evh_5962862420439144_deleteCompleted_5487,
      evh_5962862420439144_deleteCompleted,
      addInvoice: act_3343864469950044_showModal_5478,
    }
  }
}

Vue.component("UcPaymentInvoices", UcPaymentInvoices);

</script>