<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <JobRunView
    :item="item.runs[0]"
    v-if="item.runs && item.runs.length === 1"
  >
  </JobRunView>
  <BCard
    no-body
    v-if="item.runs && item.runs.length > 1"
  >
    <BTabs
      card
      v-if="item.runs && item.runs.length"
    >
      <BTab
        :key="run.id"
        v-for="run in item.runs"
      >
        <template
          slot="title"
        >
          <ItpText
            :text="'#' + run.run_number"
          >
          </ItpText>
          <UcJobStatusBadge
            :value="run.status"
          >
          </UcJobStatusBadge>
        </template>
        <JobRunView
          :item="run"
        >
        </JobRunView>
      </BTab>
    </BTabs>
  </BCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class JobRuns extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5372: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5371: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5372 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5371 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5372,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5371],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5372,
      evh_7315092382398562_reload_5371,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("JobRuns", JobRuns);

</script>