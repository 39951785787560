"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FieldsetInvoiceRegisterEntry = class FieldsetInvoiceRegisterEntry extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['invoiceTypes', 'paymentMethods', 'categories', 'statuses', 'currencies', 'ux', 'b_1548630417156826_dataSource', 'b_8680084583475136_dataSource'];
    }
    beforeCreate() {
    }
    onReferenceEntrySelected(...args) {
        const [option] = args;
        if (this.item.id == null && option.invoice_type === 'proforma') {
            this.item.amount = option.amount;
            this.item.fulfillment_date = option.fulfillment_date;
            this.item.due_date = option.due_date;
            this.item.client_id = option.client_id;
        }
    }
    onItem(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    }
    onLabel_align(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    }
    onForm(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("form");
    }
    $$load_invoiceTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('InvoiceType');
        });
    }
    $$load_paymentMethods() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_payment_method')
                .then(data => _.map(data, p => ({
                value: p.id,
                text: this.$fn.pgettext('PaymentMethod', p.name)
            })));
        });
    }
    $$load_categories() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice' } }).then(data => _.map(data, p => p.name));
        });
    }
    $$load_statuses() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(data => _.map(data, p => p.name));
        });
    }
    $$load_b_1548630417156826_dataSource() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "suggest_invoice_register_entry",
                parameters: {
                    source: this.item.id,
                },
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            invoiceTypes: null,
            paymentMethods: null,
            categories: null,
            statuses: null,
            currencies: ["HUF",
                "EUR",
                "USD",
            ],
            ux: null,
            b_1548630417156826_dataSource: null,
            b_8680084583475136_dataSource: {
                name: "suggest_client",
            },
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_script_2450_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onReferenceEntrySelected($event.data.option);
        });
    }
    act_1548630417156826_script_2450_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1548630417156826_script_2450_getActionArgs_value($event),
            };
        });
    }
    evh_1548630417156826_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_2524784394460522_emit_2452_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "close",
            };
        });
    }
    evh_2524784394460522_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2524784394460522_click.executeFromDOM(this, event, scope);
        });
    }
    act_7445225937215608_emit_2458_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "changed",
                value: $event.data.response.data,
            };
        });
    }
    act_7445225937215608_request_2456_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_invoice_register_entry",
                data: {
                    id: this.item.id,
                    etag: this.item.etag,
                    invoice_id: $event.data.modalResult.value.id
                },
            };
        });
    }
    evh_7445225937215608_close_2455_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_7445225937215608_showModal_2454_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceBrowser",
                props: {
                    invoice_kind: this.item.invoice_kind,
                    company_id: this.item.company_id,
                    client_id: this.item.client_id,
                    invoice_number: this.item.invoice_number,
                    invoice_date: this.item.fulfillment_date,
                },
            };
        });
    }
    evh_7445225937215608_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7445225937215608_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_3283186227685836_emit_2460_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "close",
            };
        });
    }
    act_3283186227685836_showModal_2462_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: this.item.invoice_kind,
                    invoiceType: this.item.invoice_type,
                    invoiceRegisterEntry: this.item.id,
                },
            };
        });
    }
    evh_3283186227685836_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3283186227685836_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_2448 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_2447 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_2448,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_2447],
        });
        const act_1548630417156826_script_2450 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1548630417156826_script_2450_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_optionSelected_2449 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_script_2450,
            event: "optionSelected",
            displayName: "script",
        });
        const evh_1548630417156826_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_optionSelected_2449],
        });
        const act_2524784394460522_emit_2452 = new core_1.actions.EmitAction({
            actionArgs: this.act_2524784394460522_emit_2452_getActionArgs,
            events: [],
        });
        const evh_2524784394460522_click_2451 = new core_1.actions.EventHandlerImpl({
            action: act_2524784394460522_emit_2452,
            event: "click",
            displayName: "emit",
        });
        const evh_2524784394460522_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2524784394460522_click_2451],
        });
        const act_7445225937215608_emit_2458 = new core_1.actions.EmitAction({
            actionArgs: this.act_7445225937215608_emit_2458_getActionArgs,
            events: [],
        });
        const evh_7445225937215608_success_2457 = new core_1.actions.EventHandlerImpl({
            action: act_7445225937215608_emit_2458,
            event: "success",
            displayName: "emit",
        });
        const act_7445225937215608_request_2456 = new core_1.actions.RequestAction({
            actionArgs: this.act_7445225937215608_request_2456_getActionArgs,
            displayName: "onInvoiceSelectedAction",
            events: [evh_7445225937215608_success_2457],
        });
        const evh_7445225937215608_close_2455 = new core_1.actions.EventHandlerImpl({
            action: act_7445225937215608_request_2456,
            event: "close",
            when: this.evh_7445225937215608_close_2455_getWhen,
            displayName: "onInvoiceSelectedAction",
        });
        const act_7445225937215608_showModal_2454 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7445225937215608_showModal_2454_getActionArgs,
            events: [evh_7445225937215608_close_2455],
        });
        const evh_7445225937215608_clickPreventStop_2453 = new core_1.actions.EventHandlerImpl({
            action: act_7445225937215608_showModal_2454,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_7445225937215608_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7445225937215608_clickPreventStop_2453],
        });
        const act_3283186227685836_emit_2460 = new core_1.actions.EmitAction({
            actionArgs: this.act_3283186227685836_emit_2460_getActionArgs,
            events: [],
        });
        const evh_3283186227685836_clickPreventStop_2459 = new core_1.actions.EventHandlerImpl({
            action: act_3283186227685836_emit_2460,
            event: "click.prevent.stop",
            displayName: "emit",
        });
        const act_3283186227685836_showModal_2462 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3283186227685836_showModal_2462_getActionArgs,
            events: [],
        });
        const evh_3283186227685836_clickPreventStop_2461 = new core_1.actions.EventHandlerImpl({
            action: act_3283186227685836_showModal_2462,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3283186227685836_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3283186227685836_clickPreventStop_2459, evh_3283186227685836_clickPreventStop_2461],
        });
        return {
            act_7315092382398562_reloadComponentData_2448,
            evh_7315092382398562_reload_2447,
            evh_7315092382398562_reload,
            act_1548630417156826_script_2450,
            evh_1548630417156826_optionSelected_2449,
            evh_1548630417156826_optionSelected,
            act_2524784394460522_emit_2452,
            evh_2524784394460522_click_2451,
            evh_2524784394460522_click,
            act_7445225937215608_emit_2458,
            evh_7445225937215608_success_2457,
            act_7445225937215608_request_2456,
            evh_7445225937215608_close_2455,
            act_7445225937215608_showModal_2454,
            evh_7445225937215608_clickPreventStop_2453,
            evh_7445225937215608_clickPreventStop,
            act_3283186227685836_emit_2460,
            evh_3283186227685836_clickPreventStop_2459,
            act_3283186227685836_showModal_2462,
            evh_3283186227685836_clickPreventStop_2461,
            evh_3283186227685836_clickPreventStop,
            onInvoiceSelectedAction: act_7445225937215608_request_2456,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetInvoiceRegisterEntry.prototype, "item", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetInvoiceRegisterEntry.prototype, "onItem", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "right",
    }),
    __metadata("design:type", String)
], FieldsetInvoiceRegisterEntry.prototype, "labelAlign", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('labelAlign'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetInvoiceRegisterEntry.prototype, "onLabel_align", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetInvoiceRegisterEntry.prototype, "form", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('form'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetInvoiceRegisterEntry.prototype, "onForm", null);
FieldsetInvoiceRegisterEntry = __decorate([
    (0, vue_property_decorator_1.Component)()
], FieldsetInvoiceRegisterEntry);
exports.default = FieldsetInvoiceRegisterEntry;
vue_property_decorator_1.Vue.component("FieldsetInvoiceRegisterEntry", FieldsetInvoiceRegisterEntry);
