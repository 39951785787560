<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="FixedAsset"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="page--v2 d-flex flex-column"
    v-if="loaded"
  >
    <MaterialTabs>
      <ItpBox
        alignItems="center"
        class="mr-3"
        slot="tabs-start"
      >
        <b-breadcrumb
          class="m-0 bg-white"
        >
          <b-breadcrumb-item
            :text="'Fixed Assets'|gettext"
            :to="b_2285343489561581_to"
          >
          </b-breadcrumb-item>
          <b-breadcrumb-item
            :text="item.snapshot('name')"
            active
          >
          </b-breadcrumb-item>
        </b-breadcrumb>
        <ItpButton
          variant="default"
          icon="fa-sync-alt"
          :text="'Reload'|gettext"
          :spinning="actions.reload.isRunning"
          class="px-3"
          @click.prevent.stop="evh_2095089031469157_clickPreventStop($event, {})"
        >
        </ItpButton>
      </ItpBox>
      <b-tab
        :title="'General'|gettext"
        class="container float-left"
      >
        <ItpRow>
          <ItpCol
            w="6"
          >
            <UcFixedAssetGeneral
              :fixedAsset="item"
              :reload="lastLoaded"
              class="pb-4"
              @reload="evh_2679161326024595_reload($event, {})"
            >
            </UcFixedAssetGeneral>
          </ItpCol>
          <ItpCol
            w="6"
          >
            <UcFixedAssetWasteBin
              :fixedAsset="item"
              :reload="lastLoaded"
              class="pb-4"
              v-if="item && item.asset_type === 'waste_bin'"
              @reload="evh_2789477278097085_reload($event, {})"
            >
            </UcFixedAssetWasteBin>
            <UcCommentsList
              :parent="item"
              :reload="lastLoaded"
              class="pb-4"
            >
            </UcCommentsList>
            <UcAttachmentsList
              :parent="item"
              :reload="lastLoaded"
              class="pb-4"
            >
            </UcAttachmentsList>
            <UcFixedAssetWorkItemsPanel
              :oid="item.id"
              :reload="lastLoaded"
              class="pb-4"
              @reload="evh_8856043737157701_reload($event, {})"
            >
            </UcFixedAssetWorkItemsPanel>
          </ItpCol>
        </ItpRow>
      </b-tab>
    </MaterialTabs>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "fixedAsset",
    path: "/fixed-assets/:id",
    component: "FixedAsset",
    params: [{
      name: "id",
      prop: "fixedAssetId",
    }
      ,
    ]

  }
)

@Component()
export default class FixedAsset extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  fixedAssetId!: string;

  @Watch('fixedAssetId')
  onFixed_asset_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAssetId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_fixed_asset', { asResource: true, parameters: { id: this.fixedAssetId } })
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2285343489561581_to!: any;
  dataMembers = ['item', 'formId', 'ux', 'b_7315092382398562_modalBindings', 'b_2285343489561581_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formId: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2285343489561581_to: {
          name: "app.fixedAssets",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.snapshot('name')
          ,
        this.$fn.gettext("Fixed Asset")
          ,
        ]
        ,
        icon: this.$config.fixedAsset.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1466_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1466, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2095089031469157_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_2679161326024595_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2679161326024595_reload.executeFromDOM(this, event, scope);
  }

  async evh_2789477278097085_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2789477278097085_reload.executeFromDOM(this, event, scope);
  }

  async evh_8856043737157701_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8856043737157701_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1466: actions.CloseModalAction;
    evh_7315092382398562_close_1465: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2095089031469157_reloadComponentData_1468: actions.ReloadComponentDataAction;
    evh_2095089031469157_clickPreventStop_1467: actions.EventHandlerImpl;
    evh_2095089031469157_clickPreventStop: actions.EventHandlerGroup;
    act_2679161326024595_reloadComponentData_1470: actions.ReloadComponentDataAction;
    evh_2679161326024595_reload_1469: actions.EventHandlerImpl;
    evh_2679161326024595_reload: actions.EventHandlerGroup;
    act_2789477278097085_reloadComponentData_1472: actions.ReloadComponentDataAction;
    evh_2789477278097085_reload_1471: actions.EventHandlerImpl;
    evh_2789477278097085_reload: actions.EventHandlerGroup;
    act_8856043737157701_reloadComponentData_1474: actions.ReloadComponentDataAction;
    evh_8856043737157701_reload_1473: actions.EventHandlerImpl;
    evh_8856043737157701_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1466 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1466_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1465 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1466,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1465],
      }
    );
    const act_2095089031469157_reloadComponentData_1468 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_2095089031469157_clickPreventStop_1467 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_reloadComponentData_1468,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_2095089031469157_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_clickPreventStop_1467],
      }
    );
    const act_2679161326024595_reloadComponentData_1470 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2679161326024595_reload_1469 = new actions.EventHandlerImpl(
      {
        action: act_2679161326024595_reloadComponentData_1470,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2679161326024595_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2679161326024595_reload_1469],
      }
    );
    const act_2789477278097085_reloadComponentData_1472 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2789477278097085_reload_1471 = new actions.EventHandlerImpl(
      {
        action: act_2789477278097085_reloadComponentData_1472,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2789477278097085_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2789477278097085_reload_1471],
      }
    );
    const act_8856043737157701_reloadComponentData_1474 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8856043737157701_reload_1473 = new actions.EventHandlerImpl(
      {
        action: act_8856043737157701_reloadComponentData_1474,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8856043737157701_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8856043737157701_reload_1473],
      }
    );
    return {
      act_7315092382398562_closeModal_1466,
      evh_7315092382398562_close_1465,
      evh_7315092382398562_close,
      act_2095089031469157_reloadComponentData_1468,
      evh_2095089031469157_clickPreventStop_1467,
      evh_2095089031469157_clickPreventStop,
      act_2679161326024595_reloadComponentData_1470,
      evh_2679161326024595_reload_1469,
      evh_2679161326024595_reload,
      act_2789477278097085_reloadComponentData_1472,
      evh_2789477278097085_reload_1471,
      evh_2789477278097085_reload,
      act_8856043737157701_reloadComponentData_1474,
      evh_8856043737157701_reload_1473,
      evh_8856043737157701_reload,
      reload: act_2095089031469157_reloadComponentData_1468,
    }
  }
}

Vue.component("FixedAsset", FixedAsset);

</script>