<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="SensorDataBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="autoReadings"
    :headerTitle="'Auto Readings'|pgettext('MeterSensorDataBrowser')"
    :items="$fn.slickDataSource('integro_api/list_auto_meter_readings', {parameters: getQueryParameters})"
    export-to-csv
    :exportToCsvFilename="'meter-sensor-data'|pgettext('ExportFilename')"
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpFormDatetime
        name="time_to"
        v-model="timeTo"
        size="xs"
        class="w-auto mx-2"
      >
      </ItpFormDatetime>
      <JobHandlerControl
        size="md"
        jobType="create_meter_readings"
        load-current
        :payload="() => onCreateMeterReadingsPayload()"
        icon="material-symbols:cloud-sync-outline-rounded"
        :text="'Create readings'|pgettext('SensorDataBrowser|Button')"
        :disabled="!selectedItems || !selectedItems.length"
        @completed="evh_8320016629450276_completed($event, {})"
      >
      </JobHandlerControl>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "autoReadings",
    path: "/auto-readings",
    component: "SensorDataBrowser"
  }
)

@Component()
export default class SensorDataBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_timeTo = new ComponentValueCache(this, "timeTo", "SensorDataBrowser.timeTo");
  }

  getQueryParameters(...args: any[]) {
    return { time: this.timeTo };
  }

  onCreateMeterReadingsPayload(...args: any[]) {
    return this.$imodal
      .show({ name: 'CreateMeterReadingsParameters' })
      .then(result => {
        if (!result || result.cancel || !result.value) {
          return;
        }
        return {
          ...result.value,
          readings: this.selectedItems
            .filter(p => !!p.meter_id)
            .map(p => ({
              meter_id: p.meter_id,
              serial: p.meter_serial,
              value: p.value,
              unit: p.unit,
              reading_type: 'automated',
              reading_date: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'yyyy-MM-dd'),
              reading_time: this.$fn.formatDate(this.$fn.parseISODate(p.timestamp), 'hh:mm'),
            }))
        };
      }
      )
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  timeTo!: any;

  async $$load_timeTo() {
    return new Date()
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'timeTo', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        timeTo: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "meter_type",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Meter Type"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "MeterTypes",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "meter_serial",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Meter"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "meter",
            params: {
              id: "meter_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "tag_id",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Meter Tag"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "meter",
            params: {
              id: "meter_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "sensor",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Sensor Key"),
        }
          ,
        {
          field: "raw",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Raw"),
          textAlign: "right",
          formatter: {
            name: "number",
          }
          ,
          cssClass: "text-muted",
        }
          ,
        {
          field: "offset",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Offset"),
          textAlign: "right",
          formatter: {
            name: "number",
          }
          ,
          cssClass: "text-muted",
        }
          ,
        {
          field: "value",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Value"),
          textAlign: "right",
          cssClass: "font-weight-bold",
          formatter: {
            name: "number",
          }
          ,
        }
          ,
        {
          field: "unit",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Unit"),
          cssClass: "font-weight-bold",
        }
          ,
        {
          field: "timestamp",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Timestamp"),
          formatter: {
            name: "datetime",
          }
          ,
        }
          ,
        {
          field: "rentals",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Rentals"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            separator: ", ",
            formatterContext: "item",
            formatters: [{
              name: "getvalue",
              opts: {
                field: "name",
                source: "value",
              }
              ,
            }
              ,
            {
              name: "routeLink",
              to: "rental",
              navigateOnIconClick: true,
              params: {
                id: "id",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        {
          field: "tenants",
          name: this.$fn.pgettext("MeterSensorDataBrowser", "Tenants"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            separator: ", ",
            formatterContext: "item",
            formatters: [{
              name: "getvalue",
              opts: {
                field: "name",
                source: "value",
              }
              ,
            }
              ,
            {
              name: "routeLink",
              to: "client",
              navigateOnIconClick: true,
              params: {
                id: "id",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("MeterSensorDataBrowser", "Auto Readings"),
        icon: "fa-ethernet",
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5440_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5440, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_5442_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5442, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_script_5444_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8320016629450276_script_5444, alias=undefined
    this.$refs.grid.setSelectedItems([])
  }

  async act_8320016629450276_script_5444_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8320016629450276_script_5444, alias=undefined
    return {
      value: () => this.act_8320016629450276_script_5444_getActionArgs_value($event),
    }
  }

  async evh_8320016629450276_completed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_completed.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5440: actions.CloseModalAction;
    evh_7315092382398562_close_5439: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_5442: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_5441: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_8320016629450276_script_5444: actions.ScriptAction;
    evh_8320016629450276_completed_5443: actions.EventHandlerImpl;
    evh_8320016629450276_completed: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5440 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5440_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5439 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5440,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5439],
      }
    );
    const act_2248226175642056_setData_5442 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5442_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_5441 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5442,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_5441],
      }
    );
    const act_8320016629450276_script_5444 = new actions.ScriptAction(
      {
        actionArgs: this.act_8320016629450276_script_5444_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_completed_5443 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_script_5444,
        event: "completed",
        displayName: "script",
      }
    );
    const evh_8320016629450276_completed = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_completed_5443],
      }
    );
    return {
      act_7315092382398562_closeModal_5440,
      evh_7315092382398562_close_5439,
      evh_7315092382398562_close,
      act_2248226175642056_setData_5442,
      evh_2248226175642056_selectedRowsChanged_5441,
      evh_2248226175642056_selectedRowsChanged,
      act_8320016629450276_script_5444,
      evh_8320016629450276_completed_5443,
      evh_8320016629450276_completed,
    }
  }
}

Vue.component("SensorDataBrowser", SensorDataBrowser);

</script>