"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ContractServiceEditor = class ContractServiceEditor extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'labelAlign', 'isEditable', 'isDeletable', 'isServiceReadonly', 'isServiceFeeReadonly', 'messages', 'item', 'feeItem', 'currencies', 'services', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    getConfiguration(...args) {
        switch (this.mode) {
            case 'addService':
                return {
                    isEditable: true,
                    isDeletable: false,
                    isServiceReadonly: false,
                };
            case 'editService':
                return {
                    isEditable: !this.service.locked,
                    isDeletable: !this.service.locked,
                    isServiceReadonly: this.service.locked,
                };
            case 'addServiceFee':
                return {
                    isEditable: true,
                    isDeletable: true,
                    isServiceReadonly: true,
                    isServiceFeeReadonly: false
                };
            case 'editServiceFee':
                return {
                    isEditable: !this.fee.locked,
                    isDeletable: !this.fee.locked,
                    isServiceReadonly: true,
                    isServiceFeeReadonly: this.fee.locked
                };
        }
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onService(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("service");
    }
    onFee(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("fee");
    }
    onMode(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("mode");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_isEditable() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getConfiguration().isEditable;
        });
    }
    $$load_isDeletable() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getConfiguration().isDeletable;
        });
    }
    $$load_isServiceReadonly() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getConfiguration().isServiceReadonly;
        });
    }
    $$load_isServiceFeeReadonly() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getConfiguration().isServiceFeeReadonly;
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.service || this.$fn.schemaDefaults('CreateContractService', {
                valid_from: this.$fn.today(),
                contract_id: this.contract.id
            });
        });
    }
    $$load_feeItem() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.fee ||
                this.$fn.schemaDefaults('CreateContractServiceFee', {
                    unit: _.get(this.item, 'service.unit'),
                    contract_id: this.contract.id,
                    service_id: _.get(this.item, 'id'),
                });
        });
    }
    $$load_currencies() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_currency');
        });
    }
    $$load_services() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('suggest_product', {
                parameters: { query: '*', top: 50, skip: 0, product_types: 'service', product_invoice_groups: 'all' }
            }).then(data => _.orderBy(data, [p => p.name.toLowerCase()]));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            labelAlign: "right",
            isEditable: null,
            isDeletable: null,
            isServiceReadonly: null,
            isServiceFeeReadonly: null,
            messages: {
                addService: {
                    title: this.$fn.pgettext("Contract", "Add Service"),
                },
                editService: {
                    title: this.$fn.pgettext("Contract", "Edit Service"),
                },
                addServiceFee: {
                    title: this.$fn.pgettext("Contract", "Add Service Fee"),
                },
                editServiceFee: {
                    title: this.$fn.pgettext("Contract", "Edit Service Fee"),
                },
            },
            item: null,
            feeItem: null,
            currencies: null,
            services: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "md",
            },
        });
    }
    get currentObjectType() {
        if (this.loaded) {
            return this.mode.indexOf('Fee') > -1 ? 'contract_service_fee' : 'contract_service';
        }
        return null;
    }
    get displayFee() {
        if (this.loaded) {
            return this.mode !== 'editService';
        }
        return null;
    }
    get displayFeeDates() {
        if (this.loaded) {
            return this.displayFee && this.mode !== 'addService';
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.messages[this.mode].title,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6430_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_6436_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_6434_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: this.currentObjectType,
                op: "upsert",
                data: this.mode === 'addService'
                    ? _.extend({}, this.item, { fees: [_.extend(this.feeItem, { valid_from: this.item.valid_from, valid_to: this.item.valid_to })] })
                    : (this.currentObjectType == 'contract_service' ? this.item : this.feeItem),
                options: {
                    parameters: {
                        rev: this.contract.revision_number,
                    },
                },
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_3054425420812800_setData_6438_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.name",
                value: $event.data.option.name,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_3054425420812800_setData_6440_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.service_id",
                value: $event.data.option.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_3054425420812800_setData_6442_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "feeItem.unit",
                value: $event.data.option.unit,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_3054425420812800_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3054425420812800_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_1478395556975758_closeModal_6446_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1478395556975758_request_6444_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_contract_service",
                data: {
                    id: this.item.id,
                    etag: this.item.etag,
                    locked: true
                },
            };
        });
    }
    evh_1478395556975758_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1478395556975758_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2712422394771578_closeModal_6450_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_2712422394771578_request_6448_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_contract_service_fee",
                data: {
                    id: this.feeItem.id,
                    etag: this.feeItem.etag,
                    locked: true
                },
            };
        });
    }
    evh_2712422394771578_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2712422394771578_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeModal_6454_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1548630417156826_request_6452_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_contract_service",
                data: this.item,
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeModal_6458_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1419464017721962_request_6456_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_contract_service_fee",
                data: this.feeItem,
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_5566617329548203_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6430 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6430_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6429 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6430,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6429],
        });
        const act_2248226175642056_closeComponent_6432 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6431 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6432,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6431],
        });
        const act_1040167445267876_closeModal_6436 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_6436_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6435 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_6436,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_crud_6434 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_6434_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_6435],
        });
        const evh_1040167445267876_submit_6433 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_6434,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6433],
        });
        const act_3054425420812800_setData_6438 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3054425420812800_setData_6438_getActionArgs,
            events: [],
        });
        const evh_3054425420812800_optionSelected_6437 = new core_1.actions.EventHandlerImpl({
            action: act_3054425420812800_setData_6438,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_3054425420812800_setData_6440 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3054425420812800_setData_6440_getActionArgs,
            events: [],
        });
        const evh_3054425420812800_optionSelected_6439 = new core_1.actions.EventHandlerImpl({
            action: act_3054425420812800_setData_6440,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_3054425420812800_setData_6442 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3054425420812800_setData_6442_getActionArgs,
            events: [],
        });
        const evh_3054425420812800_optionSelected_6441 = new core_1.actions.EventHandlerImpl({
            action: act_3054425420812800_setData_6442,
            event: "optionSelected",
            displayName: "setData",
        });
        const evh_3054425420812800_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3054425420812800_optionSelected_6437, evh_3054425420812800_optionSelected_6439, evh_3054425420812800_optionSelected_6441],
        });
        const act_1478395556975758_closeModal_6446 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1478395556975758_closeModal_6446_getActionArgs,
            events: [],
        });
        const evh_1478395556975758_success_6445 = new core_1.actions.EventHandlerImpl({
            action: act_1478395556975758_closeModal_6446,
            event: "success",
            displayName: "closeModal",
        });
        const act_1478395556975758_request_6444 = new core_1.actions.RequestAction({
            actionArgs: this.act_1478395556975758_request_6444_getActionArgs,
            displayName: "lockService",
            events: [evh_1478395556975758_success_6445],
        });
        const evh_1478395556975758_clickPreventStop_6443 = new core_1.actions.EventHandlerImpl({
            action: act_1478395556975758_request_6444,
            event: "click.prevent.stop",
            displayName: "lockService",
        });
        const evh_1478395556975758_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1478395556975758_clickPreventStop_6443],
        });
        const act_2712422394771578_closeModal_6450 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2712422394771578_closeModal_6450_getActionArgs,
            events: [],
        });
        const evh_2712422394771578_success_6449 = new core_1.actions.EventHandlerImpl({
            action: act_2712422394771578_closeModal_6450,
            event: "success",
            displayName: "closeModal",
        });
        const act_2712422394771578_request_6448 = new core_1.actions.RequestAction({
            actionArgs: this.act_2712422394771578_request_6448_getActionArgs,
            displayName: "lockServiceFee",
            events: [evh_2712422394771578_success_6449],
        });
        const evh_2712422394771578_clickPreventStop_6447 = new core_1.actions.EventHandlerImpl({
            action: act_2712422394771578_request_6448,
            event: "click.prevent.stop",
            displayName: "lockServiceFee",
        });
        const evh_2712422394771578_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2712422394771578_clickPreventStop_6447],
        });
        const act_1548630417156826_closeModal_6454 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1548630417156826_closeModal_6454_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_6453 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeModal_6454,
            event: "success",
            displayName: "closeModal",
        });
        const act_1548630417156826_request_6452 = new core_1.actions.RequestAction({
            actionArgs: this.act_1548630417156826_request_6452_getActionArgs,
            displayName: "deleteService",
            events: [evh_1548630417156826_success_6453],
        });
        const evh_1548630417156826_clickPreventStop_6451 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_request_6452,
            event: "click.prevent.stop",
            displayName: "deleteService",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_6451],
        });
        const act_1419464017721962_closeModal_6458 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_6458_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_success_6457 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_6458,
            event: "success",
            displayName: "closeModal",
        });
        const act_1419464017721962_request_6456 = new core_1.actions.RequestAction({
            actionArgs: this.act_1419464017721962_request_6456_getActionArgs,
            displayName: "deleteServiceFee",
            events: [evh_1419464017721962_success_6457],
        });
        const evh_1419464017721962_clickPreventStop_6455 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_request_6456,
            event: "click.prevent.stop",
            displayName: "deleteServiceFee",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6455],
        });
        const act_5566617329548203_closeComponent_6460 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_5566617329548203_clickPreventStop_6459 = new core_1.actions.EventHandlerImpl({
            action: act_5566617329548203_closeComponent_6460,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_5566617329548203_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5566617329548203_clickPreventStop_6459],
        });
        return {
            act_7315092382398562_closeModal_6430,
            evh_7315092382398562_close_6429,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6432,
            evh_2248226175642056_close_6431,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_6436,
            evh_1040167445267876_success_6435,
            act_1040167445267876_crud_6434,
            evh_1040167445267876_submit_6433,
            evh_1040167445267876_submit,
            act_3054425420812800_setData_6438,
            evh_3054425420812800_optionSelected_6437,
            act_3054425420812800_setData_6440,
            evh_3054425420812800_optionSelected_6439,
            act_3054425420812800_setData_6442,
            evh_3054425420812800_optionSelected_6441,
            evh_3054425420812800_optionSelected,
            act_1478395556975758_closeModal_6446,
            evh_1478395556975758_success_6445,
            act_1478395556975758_request_6444,
            evh_1478395556975758_clickPreventStop_6443,
            evh_1478395556975758_clickPreventStop,
            act_2712422394771578_closeModal_6450,
            evh_2712422394771578_success_6449,
            act_2712422394771578_request_6448,
            evh_2712422394771578_clickPreventStop_6447,
            evh_2712422394771578_clickPreventStop,
            act_1548630417156826_closeModal_6454,
            evh_1548630417156826_success_6453,
            act_1548630417156826_request_6452,
            evh_1548630417156826_clickPreventStop_6451,
            evh_1548630417156826_clickPreventStop,
            act_1419464017721962_closeModal_6458,
            evh_1419464017721962_success_6457,
            act_1419464017721962_request_6456,
            evh_1419464017721962_clickPreventStop_6455,
            evh_1419464017721962_clickPreventStop,
            act_5566617329548203_closeComponent_6460,
            evh_5566617329548203_clickPreventStop_6459,
            evh_5566617329548203_clickPreventStop,
            save: act_1040167445267876_crud_6434,
            lockService: act_1478395556975758_request_6444,
            lockServiceFee: act_2712422394771578_request_6448,
            deleteService: act_1548630417156826_request_6452,
            deleteServiceFee: act_1419464017721962_request_6456,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], ContractServiceEditor.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractServiceEditor.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], ContractServiceEditor.prototype, "service", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('service'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractServiceEditor.prototype, "onService", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], ContractServiceEditor.prototype, "fee", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('fee'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractServiceEditor.prototype, "onFee", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], ContractServiceEditor.prototype, "mode", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('mode'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractServiceEditor.prototype, "onMode", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ContractServiceEditor.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractServiceEditor.prototype, "onSize", null);
ContractServiceEditor = __decorate([
    (0, vue_property_decorator_1.Component)()
], ContractServiceEditor);
exports.default = ContractServiceEditor;
vue_property_decorator_1.Vue.component("ContractServiceEditor", ContractServiceEditor);
