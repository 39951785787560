"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateTemplate = class CreateTemplate extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'objectTypes', 'ux', 'b_7315092382398562_modalBindings', 'b_7527331142178322_options'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.schemaDefaults('CreateTemplate');
        });
    }
    $$load_objectTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('ObjectTypes');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            objectTypes: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_7527331142178322_options: [{
                    value: "mjml",
                    text: "MJML",
                },
                {
                    value: "html",
                    text: "HTML",
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Templates", "Create Template"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4160_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_4166_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    evh_1040167445267876_success_4165_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!this.modalName;
        });
    }
    act_1040167445267876_request_4164_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_template",
                data: Object.assign(Object.assign({}, this.item), { content: '<div></div>' }),
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4160 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4160_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4159 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4160,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4159],
        });
        const act_2248226175642056_closeComponent_4162 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_4161 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_4162,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_4161],
        });
        const act_1040167445267876_closeModal_4166 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_4166_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_4165 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_4166,
            event: "success",
            when: this.evh_1040167445267876_success_4165_getWhen,
            displayName: "closeModal",
        });
        const act_1040167445267876_request_4164 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_4164_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_4165],
        });
        const evh_1040167445267876_submit_4163 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_4164,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_4163],
        });
        const act_1419464017721962_closeModal_4168 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_4167 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_4168,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_4167],
        });
        return {
            act_7315092382398562_closeModal_4160,
            evh_7315092382398562_close_4159,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_4162,
            evh_2248226175642056_close_4161,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_4166,
            evh_1040167445267876_success_4165,
            act_1040167445267876_request_4164,
            evh_1040167445267876_submit_4163,
            evh_1040167445267876_submit,
            act_1419464017721962_closeModal_4168,
            evh_1419464017721962_clickPreventStop_4167,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_request_4164,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "sm",
    }),
    __metadata("design:type", String)
], CreateTemplate.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateTemplate.prototype, "onSize", null);
CreateTemplate = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateTemplate);
exports.default = CreateTemplate;
vue_property_decorator_1.Vue.component("CreateTemplate", CreateTemplate);
