<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateRental"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :dialogName="resourceTitle"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      schema="CreateRental"
      :id="formId"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetRentalGeneralData
        :rental="editor"
      >
      </FieldsetRentalGeneralData>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      type="submit"
      :form="forms[formName]"
      :spinning="actions.create.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.create.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateRental extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  defaultCompany!: any;

  async $$load_defaultCompany() {
    return this.$fn.fetch('read_company', { parameters: { id: 'default' } })
  }

  resource!: any;

  async $$load_resource() {
    return this.$fn.schemaDefaults('CreateRental', {
      company_id: this.defaultCompany.id,
      valid_from: this.$fn.today()
    })
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  container!: any;

  async $$load_container() {
    return {
      self: this.$fn.urlFor('create_rental'),
      object: 'list',
      data: []
    }
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  resourceTitle!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['defaultCompany', 'resource', 'editor', 'container', 'formName', 'formId', 'resourceTitle', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        defaultCompany: null,
        resource: null,
        editor: null,
        container: null,
        formName: "rental",
        formId: null,
        resourceTitle: this.$fn.pgettext("Rental", "Rental"),
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Rental", "Create Rental"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3880_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3880, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_3886_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_3886, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1040167445267876_navigate_3888_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1040167445267876_navigate_3888, alias=undefined
    return {
      location: {
        name: "app.rental",
        params: {
          id: $event.data.response.data.id,
        }
        ,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_3890_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3890, alias=undefined
    return {
      grid: "rentals",
    }
  }

  async act_1040167445267876_rest_3884_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_3884, alias=create
    return {
      method: "save",
      container: this.container,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3880: actions.CloseModalAction;
    evh_7315092382398562_close_3879: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3882: actions.CloseComponentAction;
    evh_2248226175642056_close_3881: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_3886: actions.CloseComponentAction;
    evh_1040167445267876_saved_3885: actions.EventHandlerImpl;
    act_1040167445267876_navigate_3888: actions.NavigateAction;
    evh_1040167445267876_saved_3887: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_3890: actions.ReloadSlickgridAction;
    evh_1040167445267876_saved_3889: actions.EventHandlerImpl;
    act_1040167445267876_rest_3884: actions.RestAction;
    evh_1040167445267876_submit_3883: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_3892: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_3891: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3880 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3880_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3879 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3880,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3879],
      }
    );
    const act_2248226175642056_closeComponent_3882 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3881 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3882,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3881],
      }
    );
    const act_1040167445267876_closeComponent_3886 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_3886_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3885 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_3886,
        event: "saved",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_navigate_3888 = new actions.NavigateAction(
      {
        actionArgs: this.act_1040167445267876_navigate_3888_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3887 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_navigate_3888,
        event: "saved",
        displayName: "navigate",
      }
    );
    const act_1040167445267876_reloadSlickgrid_3890 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_3890_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3889 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_3890,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_rest_3884 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_3884_getActionArgs,
        displayName: "create",
        events: [evh_1040167445267876_saved_3885, evh_1040167445267876_saved_3887, evh_1040167445267876_saved_3889],
      }
    );
    const evh_1040167445267876_submit_3883 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_3884,
        event: "submit",
        displayName: "create",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3883],
      }
    );
    const act_1419464017721962_closeComponent_3892 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_3891 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_3892,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3891],
      }
    );
    return {
      act_7315092382398562_closeModal_3880,
      evh_7315092382398562_close_3879,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3882,
      evh_2248226175642056_close_3881,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_3886,
      evh_1040167445267876_saved_3885,
      act_1040167445267876_navigate_3888,
      evh_1040167445267876_saved_3887,
      act_1040167445267876_reloadSlickgrid_3890,
      evh_1040167445267876_saved_3889,
      act_1040167445267876_rest_3884,
      evh_1040167445267876_submit_3883,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_3892,
      evh_1419464017721962_clickPreventStop_3891,
      evh_1419464017721962_clickPreventStop,
      create: act_1040167445267876_rest_3884,
    }
  }
}

Vue.component("CreateRental", CreateRental);

</script>