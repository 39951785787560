<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpRow>
    <ItpCol
      w="6"
    >
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="account_type"
        :label="'Account type'|gettext"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect
          name="account_type"
          v-model="item.account_type"
          :options="accountTypes"
          :readonly="isReadOnly"
          required
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="name"
        :label="'Name'|gettext"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          name="name"
          v-model="item.name"
          :readonly="isReadOnly"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="5"
        name="account_type"
        :label="'Default'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="is_default"
          inline
          v-model="item.is_default"
          :readonly="isReadOnly"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="5"
        name="currency"
        :label="'Currency'|gettext"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect
          name="currency"
          v-model="item.currency"
          :options="currencies"
          valueField="code"
          textField="code"
          :readonly="isReadOnly"
          required
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="5"
        name="currency"
        :label="'Rounding'|pgettext('Account')"
        :labelAlign="labelAlign"
      >
        <ItpFormSelect
          name="round_to"
          v-model="item.round_to"
          :options="roundingTypes"
          :readonly="isReadOnly"
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="owner"
        :label="'Owner'|gettext"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="owner_id"
          v-model="item.owner_id"
          :dataSource="b_6041539876822251_dataSource"
          :initialOptions="[item.owner]"
          valueField="id"
          textField="name"
          :readonly="isReadOnly"
          required
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="payment_in_numbering_id"
        :label="'Numbering (Pay in)'|pgettext('Account')"
        :labelAlign="labelAlign"
        v-if="item.object"
      >
        <NumberingSelector
          name="payment_in_numbering_id"
          v-model="item.payment_in_numbering_id"
          :readonly="isReadOnly"
          :objectType="item.object"
        >
        </NumberingSelector>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="payment_out_numbering_id"
        :label="'Numbering (Pay out)'|pgettext('Account')"
        :labelAlign="labelAlign"
        v-if="item.object"
      >
        <NumberingSelector
          name="payment_out_numbering_id"
          v-model="item.payment_out_numbering_id"
          :readonly="isReadOnly"
          :objectType="item.object"
        >
        </NumberingSelector>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="description"
        :label="'Description'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="description"
          v-model="item.description"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpCol>
    <ItpCol
      w="6"
    >
      <ItpFormGroup
        labelColsMd="3"
        contentColsMd="9"
        name="account_number"
        :label="'Account number'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="account_number"
          v-model="item.account_number"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="3"
        contentColsMd="9"
        name="iban"
        :label="'IBAN'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="iban"
          v-model="item.iban"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="3"
        contentColsMd="9"
        name="swift"
        :label="'SWIFT'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="swift"
          v-model="item.swift"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="3"
        contentColsMd="9"
        name="bank"
        :label="'Bank'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="bank"
          v-model="item.bank"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="3"
        contentColsMd="9"
        name="branch"
        :label="'Branch'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="branch"
          v-model="item.branch"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
    </ItpCol>
  </ItpRow>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetAccountGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  accountTypes!: any;

  async $$load_accountTypes() {
    return this.$fn.getEnumValuesFromSchema('AccountTypes')
  }

  roundingTypes!: any;
  ux!: any;
  b_6041539876822251_dataSource!: any;
  dataMembers = ['currencies', 'accountTypes', 'roundingTypes', 'ux', 'b_6041539876822251_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        currencies: null,
        accountTypes: null,
        roundingTypes: [{
          value: null,
          text: this.$fn.pgettext("RoundingType", "no rounding"),
        }
          ,
        {
          value: 1,
          text: this.$fn.pgettext("RoundingType", "round to 1"),
        }
          ,
        {
          value: 5,
          text: this.$fn.pgettext("RoundingType", "round to 5"),
        }
          ,
        {
          value: 10,
          text: this.$fn.pgettext("RoundingType", "round to 10"),
        }
          ,
        ]
        ,
        ux: null,
        b_6041539876822251_dataSource: {
          name: "suggest_company",
          parameters: {
            owned: true,
          }
          ,
        }
        ,
      },
    }
  }

  get isReadOnly() {
    if (this.loaded) {

      return this.item.locked
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5160: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5159: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5160 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5159 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5160,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5159],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5160,
      evh_7315092382398562_reload_5159,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetAccountGeneral", FieldsetAccountGeneral);

</script>