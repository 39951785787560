<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Job"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <JobHeader
      :item="resource"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </JobHeader>
  </UcHeader>
  <DIV
    class="px-4 py-3"
  >
    <JobView
      :item="resource"
      v-if="resource"
      @reload="evh_1040167445267876_reload($event, {})"
    >
    </JobView>
  </DIV>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Job extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resource!: any;

  async $$load_resource() {
    return this.$fn.fetch('read_a_job', { parameters: { id: this.oid }, asResource: true })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['resource', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resource: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.$fn.pgettext("Job|Title", "Job")
          ,
        this.resource.name
          ,
        this.resource.id
          ,
        ]
        ,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5304_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5304, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_5312_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_5312, alias=undefined
    return {
      grid: "jobs",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async evh_1040167445267876_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_reload.executeFromDOM(this, event, scope);
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5304: actions.CloseModalAction;
    evh_7315092382398562_close_5303: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_5306: actions.CloseComponentAction;
    evh_2248226175642056_close_5305: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_5308: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_5307: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_5310: actions.CloseModalAction;
    evh_8564662037462133_deleted_5309: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_5312: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_5311: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_reloadComponentData_5314: actions.ReloadComponentDataAction;
    evh_1040167445267876_reload_5313: actions.EventHandlerImpl;
    evh_1040167445267876_reload: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_5316: actions.CloseComponentAction;
    evh_1548630417156826_clickPreventStop_5315: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5304 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5304_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5303 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5304,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5303],
      }
    );
    const act_2248226175642056_closeComponent_5306 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5305 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_5306,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5305],
      }
    );
    const act_8564662037462133_reloadComponentData_5308 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_5307 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_5308,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_5307],
      }
    );
    const act_8564662037462133_closeModal_5310 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_5309 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_5310,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const act_8564662037462133_reloadSlickgrid_5312 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_5312_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_5311 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_5312,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_5309, evh_8564662037462133_deleted_5311],
      }
    );
    const act_1040167445267876_reloadComponentData_5314 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_1040167445267876_reload_5313 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadComponentData_5314,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_1040167445267876_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_reload_5313],
      }
    );
    const act_1548630417156826_closeComponent_5316 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_5315 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_5316,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_5315],
      }
    );
    return {
      act_7315092382398562_closeModal_5304,
      evh_7315092382398562_close_5303,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_5306,
      evh_2248226175642056_close_5305,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_5308,
      evh_8564662037462133_reload_5307,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_5310,
      evh_8564662037462133_deleted_5309,
      act_8564662037462133_reloadSlickgrid_5312,
      evh_8564662037462133_deleted_5311,
      evh_8564662037462133_deleted,
      act_1040167445267876_reloadComponentData_5314,
      evh_1040167445267876_reload_5313,
      evh_1040167445267876_reload,
      act_1548630417156826_closeComponent_5316,
      evh_1548630417156826_clickPreventStop_5315,
      evh_1548630417156826_clickPreventStop,
    }
  }
}

Vue.component("Job", Job);

</script>