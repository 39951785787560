"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcRentalServiceCostAllocators = class UcRentalServiceCostAllocators extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    onService(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("service");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    act_2576027262358435_emit_7082_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_2576027262358435_showModal_7080_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalServiceCostAllocator",
                props: {
                    oid: $event.scope.item.id,
                    service: this.service,
                },
            };
        });
    }
    evh_2576027262358435_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2576027262358435_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7593494570053535_emit_7086_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_7593494570053535_request_7084_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_rental_service_cost_allocator",
                data: $event.scope.item,
            };
        });
    }
    act_7593494570053535_request_7084_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.gettext("Please confirm delete."),
                btnOk: this.$fn.gettext("Delete"),
            };
        });
    }
    evh_7593494570053535_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7593494570053535_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2140198529698299_showModal_7088_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalServiceCostAllocator",
                props: {
                    service: this.service,
                },
            };
        });
    }
    evh_2140198529698299_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2140198529698299_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_2576027262358435_emit_7082 = new core_1.actions.EmitAction({
            actionArgs: this.act_2576027262358435_emit_7082_getActionArgs,
            events: [],
        });
        const evh_2576027262358435_close_7081 = new core_1.actions.EventHandlerImpl({
            action: act_2576027262358435_emit_7082,
            event: "close",
            displayName: "emit",
        });
        const act_2576027262358435_showModal_7080 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2576027262358435_showModal_7080_getActionArgs,
            events: [evh_2576027262358435_close_7081],
        });
        const evh_2576027262358435_clickPreventStop_7079 = new core_1.actions.EventHandlerImpl({
            action: act_2576027262358435_showModal_7080,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_2576027262358435_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2576027262358435_clickPreventStop_7079],
        });
        const act_7593494570053535_emit_7086 = new core_1.actions.EmitAction({
            actionArgs: this.act_7593494570053535_emit_7086_getActionArgs,
            events: [],
        });
        const evh_7593494570053535_success_7085 = new core_1.actions.EventHandlerImpl({
            action: act_7593494570053535_emit_7086,
            event: "success",
            displayName: "emit",
        });
        const act_7593494570053535_request_7084 = new core_1.actions.RequestAction({
            actionArgs: this.act_7593494570053535_request_7084_getActionArgs,
            confirm: this.act_7593494570053535_request_7084_getConfirm,
            events: [evh_7593494570053535_success_7085],
        });
        const evh_7593494570053535_clickPreventStop_7083 = new core_1.actions.EventHandlerImpl({
            action: act_7593494570053535_request_7084,
            event: "click.prevent.stop",
            displayName: "request",
        });
        const evh_7593494570053535_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7593494570053535_clickPreventStop_7083],
        });
        const act_2140198529698299_showModal_7088 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2140198529698299_showModal_7088_getActionArgs,
            events: [],
        });
        const evh_2140198529698299_clickPreventStop_7087 = new core_1.actions.EventHandlerImpl({
            action: act_2140198529698299_showModal_7088,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_2140198529698299_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2140198529698299_clickPreventStop_7087],
        });
        return {
            act_2576027262358435_emit_7082,
            evh_2576027262358435_close_7081,
            act_2576027262358435_showModal_7080,
            evh_2576027262358435_clickPreventStop_7079,
            evh_2576027262358435_clickPreventStop,
            act_7593494570053535_emit_7086,
            evh_7593494570053535_success_7085,
            act_7593494570053535_request_7084,
            evh_7593494570053535_clickPreventStop_7083,
            evh_7593494570053535_clickPreventStop,
            act_2140198529698299_showModal_7088,
            evh_2140198529698299_clickPreventStop_7087,
            evh_2140198529698299_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcRentalServiceCostAllocators.prototype, "service", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('service'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcRentalServiceCostAllocators.prototype, "onService", null);
UcRentalServiceCostAllocators = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcRentalServiceCostAllocators);
exports.default = UcRentalServiceCostAllocators;
vue_property_decorator_1.Vue.component("UcRentalServiceCostAllocators", UcRentalServiceCostAllocators);
