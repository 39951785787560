<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Product"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <MaterialTabs
    v-if="loaded"
  >
    <ItpBox
      alignItems="center"
      class="mr-3"
      slot="tabs-start"
    >
      <b-breadcrumb
        class="m-0 bg-white"
        v-if="loaded"
      >
        <b-breadcrumb-item
          :text="'Products'|gettext"
          :to="b_4615685607825208_to"
        >
        </b-breadcrumb-item>
        <b-breadcrumb-item
          :text="item.snapshot('number') + ' ' + item.snapshot('name')"
          active
        >
        </b-breadcrumb-item>
      </b-breadcrumb>
      <ItpButton
        variant="default"
        icon="fa-sync-alt"
        :text="'Reload'|gettext"
        :spinning="actions.reload.isRunning"
        class="px-3"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
      <BtnSyncResource
        :resource="item"
        size="sm"
        class="ml-auto mr-2"
        v-if="loaded"
        @success="evh_6705964765214521_success($event, {})"
      >
      </BtnSyncResource>
    </ItpBox>
    <b-tab
      :title="'General'|gettext"
      class="container float-left"
    >
      <ItpRow>
        <ItpCol
          w="6"
        >
          <UcProductGeneral
            :product="item"
            :reload="lastLoaded"
            class="pb-5"
            @reload="evh_5477420070495986_reload($event, {})"
          >
          </UcProductGeneral>
        </ItpCol>
        <ItpCol
          w="6"
        >
          <UcCommentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-5"
          >
          </UcCommentsList>
          <UcAttachmentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-5"
          >
          </UcAttachmentsList>
        </ItpCol>
      </ItpRow>
    </b-tab>
    <b-tab
      :title="'Prices'|gettext"
      class="container-fluid h-100"
    >
      <UcProductPrices
        :product="item"
        :reload="lastLoaded"
        class="h-100"
      >
      </UcProductPrices>
    </b-tab>
  </MaterialTabs>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "product",
    path: "/products/:id",
    component: "Product",
    params: [{
      name: "id",
      prop: "oid",
    }
      ,
    ]

  }
)

@Component()
export default class Product extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_product', { asResource: true, parameters: { id: this.oid } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_4615685607825208_to!: any;
  dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_4615685607825208_to: {
          name: "app.products",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.name || this.item.number
          ,
        this.$fn.gettext("Product")
          ,
        ]
        ,
        icon: this.$config.product.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3220_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3220, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_6705964765214521_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6705964765214521_success.executeFromDOM(this, event, scope);
  }

  async evh_5477420070495986_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3220: actions.CloseModalAction;
    evh_7315092382398562_close_3219: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_8320016629450276_reloadComponentData_3222: actions.ReloadComponentDataAction;
    evh_8320016629450276_clickPreventStop_3221: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_6705964765214521_reloadComponentData_3224: actions.ReloadComponentDataAction;
    evh_6705964765214521_success_3223: actions.EventHandlerImpl;
    evh_6705964765214521_success: actions.EventHandlerGroup;
    act_5477420070495986_reloadComponentData_3226: actions.ReloadComponentDataAction;
    evh_5477420070495986_reload_3225: actions.EventHandlerImpl;
    evh_5477420070495986_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3220 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3220_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3219 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3220,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3219],
      }
    );
    const act_8320016629450276_reloadComponentData_3222 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_8320016629450276_clickPreventStop_3221 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadComponentData_3222,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_3221],
      }
    );
    const act_6705964765214521_reloadComponentData_3224 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6705964765214521_success_3223 = new actions.EventHandlerImpl(
      {
        action: act_6705964765214521_reloadComponentData_3224,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const evh_6705964765214521_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_6705964765214521_success_3223],
      }
    );
    const act_5477420070495986_reloadComponentData_3226 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5477420070495986_reload_3225 = new actions.EventHandlerImpl(
      {
        action: act_5477420070495986_reloadComponentData_3226,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5477420070495986_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5477420070495986_reload_3225],
      }
    );
    return {
      act_7315092382398562_closeModal_3220,
      evh_7315092382398562_close_3219,
      evh_7315092382398562_close,
      act_8320016629450276_reloadComponentData_3222,
      evh_8320016629450276_clickPreventStop_3221,
      evh_8320016629450276_clickPreventStop,
      act_6705964765214521_reloadComponentData_3224,
      evh_6705964765214521_success_3223,
      evh_6705964765214521_success,
      act_5477420070495986_reloadComponentData_3226,
      evh_5477420070495986_reload_3225,
      evh_5477420070495986_reload,
      reload: act_8320016629450276_reloadComponentData_3222,
    }
  }
}

Vue.component("Product", Product);

</script>