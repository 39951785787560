<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Account"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcAccountHeader
      :account="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcAccountHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="account"
      :readonly="item.locked"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <FieldsetAccountGeneral
          :item="item"
          :formId="formId"
          no-submit-button
          @saved="evh_7789867021953041_saved($event, {})"
        >
        </FieldsetAccountGeneral>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      type="submit"
      :text="'Save'|gettext"
      :form="forms.account"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Account extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.oid == null ? this.$fn.schemaDefaults('CreateAccountCommand') : this.$fn.fetch('read_account', { asResource: true, parameters: { id: this.oid } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.oid == null ?
          this.$fn.gettext("New Cash or Bank Account") :
          [this.item.snapshot("name"), this.$fn.gettext("Account")],
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1358_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1358, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_1366_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_1366, alias=undefined
    return {
      grid: "payments",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_1370_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_1370, alias=undefined
    return {
      path: "item",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_setData_1370_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_1370, alias=undefined
    return !!$event.data
  }

  async act_1040167445267876_emit_1372_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_1372, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async act_1040167445267876_closeModal_1374_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1374, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_1376_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_1376, alias=undefined
    return {
      grid: "accounts",
    }
  }

  async act_1040167445267876_crud_1368_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_1368, alias=save
    return {
      objectType: "account",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_reloadSlickgrid_1380_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_7789867021953041_reloadSlickgrid_1380, alias=undefined
    return {
      grid: "accounts",
    }
  }

  async evh_7789867021953041_saved(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_saved.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_1382_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_1382, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1358: actions.CloseModalAction;
    evh_7315092382398562_close_1357: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1360: actions.CloseModalAction;
    evh_2248226175642056_close_1359: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_1362: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_1361: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeComponent_1364: actions.CloseComponentAction;
    evh_8564662037462133_deleted_1363: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_1366: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_1365: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_setData_1370: actions.SetDataAction;
    evh_1040167445267876_success_1369: actions.EventHandlerImpl;
    act_1040167445267876_emit_1372: actions.EmitAction;
    evh_1040167445267876_success_1371: actions.EventHandlerImpl;
    act_1040167445267876_closeModal_1374: actions.CloseModalAction;
    evh_1040167445267876_success_1373: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_1376: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_1375: actions.EventHandlerImpl;
    act_1040167445267876_crud_1368: actions.CRUDAction;
    evh_1040167445267876_submit_1367: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_7789867021953041_closeModal_1378: actions.CloseModalAction;
    evh_7789867021953041_saved_1377: actions.EventHandlerImpl;
    act_7789867021953041_reloadSlickgrid_1380: actions.ReloadSlickgridAction;
    evh_7789867021953041_saved_1379: actions.EventHandlerImpl;
    evh_7789867021953041_saved: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_1382: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_1381: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1358 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1358_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1357 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1358,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1357],
      }
    );
    const act_2248226175642056_closeModal_1360 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1359 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1360,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1359],
      }
    );
    const act_8564662037462133_reloadComponentData_1362 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_1361 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_1362,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_1361],
      }
    );
    const act_8564662037462133_closeComponent_1364 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_1363 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeComponent_1364,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const act_8564662037462133_reloadSlickgrid_1366 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_1366_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_1365 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_1366,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_1363, evh_8564662037462133_deleted_1365],
      }
    );
    const act_1040167445267876_setData_1370 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_1370_getActionArgs,
        when: this.act_1040167445267876_setData_1370_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_1369 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_1370,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_emit_1372 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_1372_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1371 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_1372,
        event: "success",
        displayName: "emit",
      }
    );
    const act_1040167445267876_closeModal_1374 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1374_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1373 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1374,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_reloadSlickgrid_1376 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_1376_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1375 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_1376,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_crud_1368 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_1368_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_1369, evh_1040167445267876_success_1371, evh_1040167445267876_success_1373, evh_1040167445267876_success_1375],
      }
    );
    const evh_1040167445267876_submit_1367 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_1368,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1367],
      }
    );
    const act_7789867021953041_closeModal_1378 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_7789867021953041_saved_1377 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_closeModal_1378,
        event: "saved",
        displayName: "closeModal",
      }
    );
    const act_7789867021953041_reloadSlickgrid_1380 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_7789867021953041_reloadSlickgrid_1380_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_saved_1379 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_reloadSlickgrid_1380,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_7789867021953041_saved = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_saved_1377, evh_7789867021953041_saved_1379],
      }
    );
    const act_1419464017721962_closeModal_1382 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_1382_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1381 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_1382,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1381],
      }
    );
    return {
      act_7315092382398562_closeModal_1358,
      evh_7315092382398562_close_1357,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1360,
      evh_2248226175642056_close_1359,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_1362,
      evh_8564662037462133_reload_1361,
      evh_8564662037462133_reload,
      act_8564662037462133_closeComponent_1364,
      evh_8564662037462133_deleted_1363,
      act_8564662037462133_reloadSlickgrid_1366,
      evh_8564662037462133_deleted_1365,
      evh_8564662037462133_deleted,
      act_1040167445267876_setData_1370,
      evh_1040167445267876_success_1369,
      act_1040167445267876_emit_1372,
      evh_1040167445267876_success_1371,
      act_1040167445267876_closeModal_1374,
      evh_1040167445267876_success_1373,
      act_1040167445267876_reloadSlickgrid_1376,
      evh_1040167445267876_success_1375,
      act_1040167445267876_crud_1368,
      evh_1040167445267876_submit_1367,
      evh_1040167445267876_submit,
      act_7789867021953041_closeModal_1378,
      evh_7789867021953041_saved_1377,
      act_7789867021953041_reloadSlickgrid_1380,
      evh_7789867021953041_saved_1379,
      evh_7789867021953041_saved,
      act_1419464017721962_closeModal_1382,
      evh_1419464017721962_clickPreventStop_1381,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_1368,
    }
  }
}

Vue.component("Account", Account);

</script>