<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    name="status"
    :label="'Type'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect
      :autofocus="!isReadonly"
      name="status"
      v-model="invoice.invoice_type"
      :options="invoiceTypes"
      valueField="value"
      textField="text"
      :readonly="isReadonly"
      required
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    name="reference_invoice"
    :label="'Reference Invoice'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    :required="isReferenceInvoiceRequired"
  >
    <div
      slot="description"
      v-if="invoice.invoice_type"
    >
      {{ messages.invoiceTypeHelp[invoice.invoice_type] }}
    </div>
    <ItpFormSelect2
      name="reference_invoice"
      v-model="invoice.reference_invoice"
      :dataSource="() => ({
    name: 'suggest_invoice',
    parameters: {
      invoice_kind: invoice.invoice_kind,
      parent_invoice_type: invoice.invoice_type,
      client_id: invoice.client ? invoice.client.id: null
    }
  })"
      valueField="id"
      textField="name"
      bind-object
      :readonly="isReadonly"
      :disabled="!invoice.invoice_type"
      clearable
      use-option-template-for-selected-option
      optionTemplate="<UcSuggestInvoiceOption :option=&quot;option&quot; hide-invoice-kind></UcSuggestInvoiceOption>"
      @optionSelected="evh_4186377094414664_optionSelected($event, {})"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <hr
    class="my-4"
  >
  </hr>
  <ItpFormGroup
    name="client"
    :label="'Client'|gettext"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="client"
      v-model="invoice.client"
      bind-object
      :dataSource="b_6917646200545441_dataSource"
      required
      :disabled="preventChangeClient"
      optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
      use-option-template-for-selected-option
      @selected="evh_6917646200545441_selected($event, {})"
    >
    </ItpFormSelect2>
    <UcInvoiceClientPanel
      :client="invoice.client"
      class="mt-2 p-2 bg-light"
      slot="description"
      v-if="invoice.client"
    >
    </UcInvoiceClientPanel>
  </ItpFormGroup>
  <hr
    class="my-4"
  >
  </hr>
  <ItpFormGroup
    name="invoice_number"
    :label="'Invoice Number'|gettext"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    required
    v-if="isIncomingInvoice"
  >
    <div
      slot="description"
      v-if="duplicateInvoiceNumber"
    >
      {{ messages.duplicateInvoiceNumber }}
    </div>
    <ItpFormInput
      name="invoice_number"
      v-model="invoice.invoice_number"
      :readonly="isReadonly"
      :placeholder="'Enter invoice number'|pgettext('Invoice/invoice_number/placeholder')"
      required
      @blur="evh_7384593256854782_blur($event, {})"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="invoice_date"
    :label="'Invoice Date'|gettext"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="invoice_date"
      v-model="invoice.invoice_date"
      :readonly="isReadonly"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="fulfillment_date"
    :label="'Fulfillment Date'|gettext"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="fulfillment_date"
      v-model="invoice.fulfillment_date"
      :readonly="isReadonly"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="due_date"
    :label="'Due Date'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="due_date"
      v-model="invoice.due_date"
      :readonly="isReadonly"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <hr
    class="my-4"
  >
  </hr>
  <ItpFormGroup
    name="status"
    :label="'Status'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    required
    v-if="isIncomingInvoice"
  >
    <ItpFormSelect
      name="status"
      v-model="invoice.status"
      :options="incomingInvoiceStatuses"
      valueField="value"
      textField="text"
      :readonly="isReadonly"
      required
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    name="reference_date"
    :label="'Reference Date'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="reference_date"
      v-model="invoice.reference_date"
      :readonly="isReadonly"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="billing_period"
    :label="'Billing Period'|gettext"
    contentClass="form-row"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    v-if="invoice.billing_period"
  >
    <ItpFormDatetime
      name="billing_period_start"
      v-model="invoice.billing_period.start"
      :readonly="isReadonly"
      :placeholder="'From'|pgettext('BillingPeriod')"
      class="col-6"
    >
    </ItpFormDatetime>
    <ItpFormDatetime
      name="billing_period_end"
      v-model="invoice.billing_period.end"
      :readonly="isReadonly"
      :placeholder="'To'|pgettext('BillingPeriod')"
      class="col-6"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="currency"
    :label="'Currency'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormSelect
      name="currency"
      v-model="invoice.currency"
      :options="currencies"
      valueField="code"
      textField="code"
      :readonly="isReadonly"
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    name="currency"
    :label="'Exchange rate'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormInput
      type="number"
      name="exchange_rate"
      v-model="invoice.exchange_rate"
      :readonly="isReadonly"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="language"
    :label="'Language'|gettext"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormSelect
      name="language"
      v-model="invoice.language"
      :options="languages"
      :readonly="isReadonly"
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    name="expense_type"
    :label="'Expense Type'|gettext"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormSelect
      name="expense_type"
      v-model="invoice.expense_type"
      :options="expenseTypes"
      :readonly="isReadonly"
      valueField="name"
      textField="name"
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    name="payment_method"
    :label="'Payment Method'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    required
    class="col"
  >
    <ItpFormSelect
      name="payment_method"
      v-model="invoice.payment_method"
      :options="paymentMethods"
      :readonly="isReadonly"
      required
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="tags"
    :label="'Categories'|pgettext('Invoice')"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormSelect2
      name="tags"
      multiple
      taggable
      :options="tags"
      v-model="invoice.tags"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <hr
    class="my-4"
  >
  </hr>
  <ItpFormGroup
    name="reference_number"
    :label="'Reference Number'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormInput
      name="reference_number"
      v-model="invoice.reference_number"
      :readonly="isReadonly"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="order_number"
    :label="'Order Number'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormInput
      name="order_number"
      v-model="invoice.order_number"
      :readonly="isReadonly"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="file_number"
    :label="'File Number'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormInput
      name="file_number"
      v-model="invoice.file_number"
      :readonly="isReadonly"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <hr
    class="my-4"
  >
  </hr>
  <ItpFormGroup
    name="notes"
    :label="'Notes'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormTextarea
      name="notes"
      v-model="invoice.notes"
      :readonly="isReadonly"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <ItpFormGroup
    name="internal_notes"
    :label="'Internal Notes'|pgettext('Invoice')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    class="col"
  >
    <ItpFormTextarea
      name="internal_notes"
      v-model="invoice.internal_notes"
      :readonly="isReadonly"
      rows="4"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetInvoiceHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  onReferenceInvoiceSelected(...args: any[]) {
    const { option, value } = args[0];

    this.selectClient({ clientId: option.client.id })
  }

  selectClient(...args: any[]) {
    const { clientId } = args[0];
    return this.$fn
      .fetch('get_invoice_party_details', { parameters: { id: clientId } })
      .then(data => this.setData('invoice.client', data))
      .catch(err => this.onError(err))
  }

  checkInvoiceNumber(...args: any[]) {
    const invoiceNumber = args[0];
    return this.$fn
      .fetch('read_invoice', { parameters: { id: invoiceNumber, client: _.get(this.invoice, 'client.id') } })
      .then(d => {
        if (d.id != this.invoice.id) {
          this.duplicateInvoiceNumber = true;
        }
        else {
          this.duplicateInvoiceNumber = false;
        }
      })
      .catch(err => {
        this.duplicateInvoiceNumber = false;
      })
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  messages!: any;
  duplicateInvoiceNumber!: any;
  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  paymentMethods!: any;

  async $$load_paymentMethods() {
    return this.$fn.fetch('list_payment_method')
      .then(data => _.map(data, p => ({
        value: p.id,
        text: this.$fn.pgettext('PaymentMethod', p.name)
      })))
  }

  expenseTypes!: any;

  async $$load_expenseTypes() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } })
  }

  invoiceStatuses!: any;

  async $$load_invoiceStatuses() {
    return this.$fn.getEnumValuesFromSchema(
      this.isIncomingInvoice ? 'IncomingInvoiceStatus' : 'OutgoingInvoiceStatus',
      { sort: false }
    )
  }

  incomingInvoiceStatuses!: any;

  async $$load_incomingInvoiceStatuses() {
    return this.$fn.getEnumValuesFromSchema('IncomingInvoiceStatus', { sort: false })
  }

  invoiceTypes!: any;

  async $$load_invoiceTypes() {
    return this.$fn.getEnumValuesFromSchema('InvoiceType', { sort: false })
  }

  languages!: any;
  tags!: any;

  async $$load_tags() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice' } }).then(data => _.map(data, p => p.name))
  }

  ux!: any;
  b_6917646200545441_dataSource!: any;
  dataMembers = ['messages', 'duplicateInvoiceNumber', 'currencies', 'paymentMethods', 'expenseTypes', 'invoiceStatuses', 'incomingInvoiceStatuses', 'invoiceTypes', 'languages', 'tags', 'ux', 'b_6917646200545441_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          invoiceTypes: {
            correction: this.$fn.pgettext("InvoiceType", "Correction"),
            storno: this.$fn.pgettext("InvoiceType", "Storno"),
            proforma: this.$fn.pgettext("InvoiceType", "Pro Forma"),
          }
          ,
          invoiceTypeHelp: {
            normal: this.$fn.pgettext("Invoice|Reference|description", "Normal invoices can reference Pro Forma invoices."),
            correction: this.$fn.pgettext("Invoice|Reference|description", "A correction must reference the invoice being corrected."),
            storno: this.$fn.pgettext("Invoice|Reference|description", "The reversing invoice must reference the invoice being reversed."),
            proforma: this.$fn.pgettext("Invoice|Reference|description", "Pro Forma invoices can reference former Pro Forma invoices."),
          }
          ,
          duplicateInvoiceNumber: this.$fn.pgettext("Invoice", "The given invoice number already exists."),
        }
        ,
        duplicateInvoiceNumber: false,
        currencies: null,
        paymentMethods: null,
        expenseTypes: null,
        invoiceStatuses: null,
        incomingInvoiceStatuses: null,
        invoiceTypes: null,
        languages: [{
          value: "hu",
          text: this.$fn.pgettext("Language", "hungarian"),
        }
          ,
        {
          value: "en",
          text: this.$fn.pgettext("Language", "english"),
        }
          ,
        ]
        ,
        tags: null,
        ux: null,
        b_6917646200545441_dataSource: {
          name: "suggest_client",
        }
        ,
      },
    }
  }

  get isReadonly() {
    if (this.loaded) {

      return false
    }
    return null;
  }

  get isIncomingInvoice() {
    if (this.loaded) {

      return this.invoice.invoice_kind == 'incoming'
    }
    return null;
  }

  get isReferenceInvoiceRequired() {
    if (this.loaded) {

      return ['storno', 'correction'].indexOf(this.invoice.invoice_type) > -1
    }
    return null;
  }

  get preventChangeClient() {
    if (this.loaded) {

      return !!this.invoice.reference_invoice
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_4186377094414664_script_4980_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_4186377094414664_script_4980, alias=undefined
    this.onReferenceInvoiceSelected($event.data)
  }

  async act_4186377094414664_script_4980_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_4186377094414664_script_4980, alias=undefined
    return {
      value: () => this.act_4186377094414664_script_4980_getActionArgs_value($event),
    }
  }

  async evh_4186377094414664_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4186377094414664_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_6917646200545441_script_4982_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_6917646200545441_script_4982, alias=undefined
    this.selectClient({ clientId: this.invoice.client.id })
  }

  async act_6917646200545441_script_4982_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_6917646200545441_script_4982, alias=undefined
    return {
      value: () => this.act_6917646200545441_script_4982_getActionArgs_value($event),
    }
  }

  async act_6917646200545441_script_4982_getWhen($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_6917646200545441_script_4982, alias=undefined
    return !!this.invoice.client
  }

  async evh_6917646200545441_selected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6917646200545441_selected.executeFromDOM(this, event, scope);
  }

  async act_7384593256854782_script_4984_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7384593256854782_script_4984, alias=undefined
    this.checkInvoiceNumber(this.invoice.invoice_number)
  }

  async act_7384593256854782_script_4984_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7384593256854782_script_4984, alias=undefined
    return {
      value: () => this.act_7384593256854782_script_4984_getActionArgs_value($event),
    }
  }

  async evh_7384593256854782_blur_4983_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7384593256854782_blur_4983, alias=undefined
    return this.invoice.isValueChanged('invoice_number')
  }

  async evh_7384593256854782_blur(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7384593256854782_blur.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4978: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4977: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_4186377094414664_script_4980: actions.ScriptAction;
    evh_4186377094414664_optionSelected_4979: actions.EventHandlerImpl;
    evh_4186377094414664_optionSelected: actions.EventHandlerGroup;
    act_6917646200545441_script_4982: actions.ScriptAction;
    evh_6917646200545441_selected_4981: actions.EventHandlerImpl;
    evh_6917646200545441_selected: actions.EventHandlerGroup;
    act_7384593256854782_script_4984: actions.ScriptAction;
    evh_7384593256854782_blur_4983: actions.EventHandlerImpl;
    evh_7384593256854782_blur: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4978 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4977 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4978,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4977],
      }
    );
    const act_4186377094414664_script_4980 = new actions.ScriptAction(
      {
        actionArgs: this.act_4186377094414664_script_4980_getActionArgs,
        events: [],
      }
    );
    const evh_4186377094414664_optionSelected_4979 = new actions.EventHandlerImpl(
      {
        action: act_4186377094414664_script_4980,
        event: "optionSelected",
        displayName: "script",
      }
    );
    const evh_4186377094414664_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_4186377094414664_optionSelected_4979],
      }
    );
    const act_6917646200545441_script_4982 = new actions.ScriptAction(
      {
        actionArgs: this.act_6917646200545441_script_4982_getActionArgs,
        when: this.act_6917646200545441_script_4982_getWhen,
        events: [],
      }
    );
    const evh_6917646200545441_selected_4981 = new actions.EventHandlerImpl(
      {
        action: act_6917646200545441_script_4982,
        event: "selected",
        displayName: "script",
      }
    );
    const evh_6917646200545441_selected = new actions.EventHandlerGroup(
      {
        handlers: [evh_6917646200545441_selected_4981],
      }
    );
    const act_7384593256854782_script_4984 = new actions.ScriptAction(
      {
        actionArgs: this.act_7384593256854782_script_4984_getActionArgs,
        events: [],
      }
    );
    const evh_7384593256854782_blur_4983 = new actions.EventHandlerImpl(
      {
        action: act_7384593256854782_script_4984,
        event: "blur",
        when: this.evh_7384593256854782_blur_4983_getWhen,
        displayName: "script",
      }
    );
    const evh_7384593256854782_blur = new actions.EventHandlerGroup(
      {
        handlers: [evh_7384593256854782_blur_4983],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4978,
      evh_7315092382398562_reload_4977,
      evh_7315092382398562_reload,
      act_4186377094414664_script_4980,
      evh_4186377094414664_optionSelected_4979,
      evh_4186377094414664_optionSelected,
      act_6917646200545441_script_4982,
      evh_6917646200545441_selected_4981,
      evh_6917646200545441_selected,
      act_7384593256854782_script_4984,
      evh_7384593256854782_blur_4983,
      evh_7384593256854782_blur,
    }
  }
}

Vue.component("FieldsetInvoiceHeader", FieldsetInvoiceHeader);

</script>