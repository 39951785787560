var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          _c("ItpFormSelect", {
            staticClass: "mx-2 my-1 w-auto",
            attrs: {
              name: "date_type",
              placeholder: _vm._f("pgettext")("date filter type", "Invoices"),
              options: _vm.b_2248226175642056_options
            },
            model: {
              value: _vm.item.date_type,
              callback: function($$v) {
                _vm.$set(_vm.item, "date_type", $$v)
              },
              expression: "item.date_type"
            }
          }),
          _vm._v(" "),
          _c(
            "DateRangeSelector",
            {
              staticClass: "mx-2 my-1",
              attrs: {
                name: "invoiceFilters",
                from: _vm.item.date_from,
                to: _vm.item.date_to
              },
              on: {
                changed: function($event) {
                  return _vm.evh_5962862420439144_changed($event, {})
                }
              }
            },
            [
              _c("ItpText", {
                attrs: { text: _vm._f("pgettext")("Unpaid", "Invoices") }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpFormCheckbox",
            {
              staticClass: "mx-2 my-1",
              attrs: { name: "unpaid", inline: "" },
              model: {
                value: _vm.item.unpaid,
                callback: function($$v) {
                  _vm.$set(_vm.item, "unpaid", $$v)
                },
                expression: "item.unpaid"
              }
            },
            [
              _c("ItpText", {
                attrs: { text: _vm._f("pgettext")("Unpaid", "Invoices") }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("ItpButton", {
            staticClass: "mx-2 my-1",
            attrs: {
              icon: _vm.$config.reloadIcon,
              text: _vm._f("gettext")("Load"),
              variant: "light"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.evh_1048678296148443_clickPreventStop($event, {})
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }