<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Numbering"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="numbering"
      :readonly="item.locked"
      :labelAlign="$config.forms.defaultLabelAlign"
      :id="formId"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpRow>
        <ItpCol>
          <ItpFormGroup
            labelColsMd="2"
            contentColsMd="5"
            name="resource_type"
            :label="'Resource type'|pgettext('Numbering')"
            required
          >
            <ItpFormSelect
              name="resource_type"
              v-model="item.resource_type"
              :options="resourceTypes"
              required
              :placeholder="'The type of the resource.'|pgettext('Numbering')"
            >
            </ItpFormSelect>
          </ItpFormGroup>
          <ItpFormGroup
            labelColsMd="2"
            contentColsMd="5"
            name="key"
            :label="'Key'|pgettext('Numbering')"
            required
            v-if="oid"
          >
            <ItpFormInput
              name="key"
              v-model="item.key"
              required
              :placeholder="'The unique key of the sequence.'|pgettext('Numbering')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            name="description"
            :label="'Description'|pgettext('Numbering')"
            labelColsMd="2"
            contentColsMd="8"
            required
          >
            <ItpFormInput
              name="description"
              v-model="item.description"
              required
              autofocus
              :placeholder="'Short name/description.'|pgettext('Numbering')"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            name="format"
            :label="'Format'|pgettext('Numbering')"
            labelColsMd="2"
            contentColsMd="8"
            required
          >
            <ItpFormInput
              name="format"
              v-model="item.format"
              required
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            name="start"
            :label="'Start'|pgettext('Numbering')"
            labelColsMd="2"
            contentColsMd="2"
            required
          >
            <ItpFormInput
              type="number"
              name="start"
              v-model="item.start"
              required
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            name="increment"
            :label="'Increment'|pgettext('Numbering')"
            labelColsMd="2"
            contentColsMd="2"
            required
          >
            <ItpFormInput
              type="number"
              name="increment"
              v-model="item.increment"
              required
            >
            </ItpFormInput>
          </ItpFormGroup>
        </ItpCol>
      </ItpRow>
    </ItpForm>
    <HR>
    </HR>
    <ItpDataTable
      :paging="b_3083451129821185_paging"
      :value="item.variables"
      :columns="b_3083451129821185_columns"
      v-if="loaded"
    >
    </ItpDataTable>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms.numbering && forms.numbering.id"
      :text="'Save'|pgettext('Numbering')"
      type="submit"
      variant="primary"
      default
      :disabled="!forms.numbering || !forms.numbering.submittable"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|pgettext('Numbering')"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Numbering extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: null,
  })
  objectType!: string;

  @Watch('objectType')
  onObject_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("objectType")
  }

  @Prop({
    type: Object,
    default: null,
  })
  createParams!: object;

  @Watch('createParams')
  onCreate_params(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("createParams")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resourceTypes!: any;
  item!: any;

  async $$load_item() {
    return this.oid
      ? this.$fn.fetch('read_numbering', { parameters: { id: this.oid }, asResource: true })
      : this.$fn.schemaDefaults('CreateNumbering', _.extend({
        resource_type: this.objectType,
        start: 1,
        increment: 1,
        variables: []
      }, this.createParams))
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_3083451129821185_paging!: any;
  b_3083451129821185_columns!: any;
  dataMembers = ['resourceTypes', 'item', 'formId', 'ux', 'b_7315092382398562_modalBindings', 'b_3083451129821185_paging', 'b_3083451129821185_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resourceTypes: ["account"
          ,
          "contract"
          ,
          "dunning_letter"
          ,
          "fixed_asset"
          ,
          "invoice:out"
          ,
          "invoice:in"
          ,
          "invoice_register"
          ,
          "letter_register"
          ,
          "part"
          ,
          "product"
          ,
          "register:incoming_invoice"
          ,
          "register:outgoing_invoice"
          ,
          "register:incoming_letters"
          ,
          "register:outgoing_letters"
          ,
          "rental"
          ,
          "rental_contract"
          ,
          "worksheet"
          ,
        ]
        ,
        item: null,
        formId: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
        b_3083451129821185_paging: false,
        b_3083451129821185_columns: [{
          name: "id",
          header: this.$fn.pgettext("Numbering", "ID"),
          width: "120px",
          required: true,
        }
          ,
        {
          name: "name",
          header: this.$fn.pgettext("Numbering", "Name"),
          width: "120px",
          required: true,
        }
          ,
        {
          name: "data_type",
          header: this.$fn.pgettext("Numbering", "Data type"),
          width: "120px",
          required: true,
        }
          ,
        {
          name: "value",
          header: this.$fn.pgettext("Numbering", "Value"),
          width: "120px",
          required: true,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.oid == null ? this.$fn.gettext("New Numbering") : this.item.snapshot("key"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3014_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3014, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_3020_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_3020, alias=undefined
    return {
      result: {
        close: true,
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_3018_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_3018, alias=submit
    return {
      objectType: "numbering",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_3022_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_3022, alias=undefined
    return {
      name: this.modalName,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3014: actions.CloseModalAction;
    evh_7315092382398562_close_3013: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3016: actions.CloseComponentAction;
    evh_2248226175642056_close_3015: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_3020: actions.CloseModalAction;
    evh_1040167445267876_success_3019: actions.EventHandlerImpl;
    act_1040167445267876_crud_3018: actions.CRUDAction;
    evh_1040167445267876_submit_3017: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_3022: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_3021: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3014 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3014_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3013 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3014,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3013],
      }
    );
    const act_2248226175642056_closeComponent_3016 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3015 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3016,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3015],
      }
    );
    const act_1040167445267876_closeModal_3020 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_3020_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_3019 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_3020,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_3018 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_3018_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_3019],
      }
    );
    const evh_1040167445267876_submit_3017 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_3018,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3017],
      }
    );
    const act_1419464017721962_closeModal_3022 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_3022_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_3021 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_3022,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3021],
      }
    );
    return {
      act_7315092382398562_closeModal_3014,
      evh_7315092382398562_close_3013,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3016,
      evh_2248226175642056_close_3015,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_3020,
      evh_1040167445267876_success_3019,
      act_1040167445267876_crud_3018,
      evh_1040167445267876_submit_3017,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_3022,
      evh_1419464017721962_clickPreventStop_3021,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_crud_3018,
    }
  }
}

Vue.component("Numbering", Numbering);

</script>