<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Settings"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="overflow-hidden h-100 d-flex"
  >
    <ItpSidebar
      default-width="400"
      class="mr-3"
    >
      <b-list-group>
        <router-link
          :key="item.id"
          :to="item.to"
          v-for="item in sidebarItems"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          class="py-2"
        >
          <b-list-group-item
            class="[
  {'font-weight-bold': isActive},
  'd-flex justify-content-between',
  'align-items-center'
]
"
          >
            <b-link
              :href="href"
              @click="navigate"
              class="text-truncate"
            >
              {{ item.name }}
            </b-link>
          </b-list-group-item>
        </router-link>
      </b-list-group>
    </ItpSidebar>
    <router-view
      class="h-100 w-100 overflow-hidden"
    >
    </router-view>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "settings",
    path: "/settings",
    component: "Settings"
  }
)

@Component()
export default class Settings extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  sidebarItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['sidebarItems', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        sidebarItems: [{
          id: "groups",
          name: this.$fn.gettext("Groups"),
          to: {
            name: "app.settings.groups",
          }
          ,
        }
          ,
        {
          id: "numberings",
          name: this.$fn.gettext("Numberings"),
          to: {
            name: "app.settings.numberings",
          }
          ,
        }
          ,
        {
          id: "statData",
          name: this.$fn.gettext("External stat data"),
          to: {
            name: "app.settings.statData",
          }
          ,
        }
          ,
        {
          id: "tasks",
          name: this.$fn.gettext("Tasks"),
          to: {
            name: "app.settings.tasks",
          }
          ,
        }
          ,
        {
          id: "registers",
          name: this.$fn.gettext("Registers"),
          to: {
            name: "app.settings.registers",
          }
          ,
        }
          ,
        {
          id: "expenseTypes",
          name: this.$fn.gettext("Expense types"),
          to: {
            name: "app.settings.expenseTypes",
          }
          ,
        }
          ,
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Settings"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4144_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4144, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4144: actions.CloseModalAction;
    evh_7315092382398562_close_4143: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4144 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4144_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4143 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4144,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4143],
      }
    );
    return {
      act_7315092382398562_closeModal_4144,
      evh_7315092382398562_close_4143,
      evh_7315092382398562_close,
    }
  }
}

Vue.component("Settings", Settings);

</script>