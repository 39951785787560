"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const big_js_1 = require("big.js");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcWorksheetWorkItems = class UcWorksheetWorkItems extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['expenseTypes', 'taxCodes', 'units', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_columns'];
    }
    beforeCreate() {
    }
    onServiceSelected(...args) {
        const [row, service] = args;
        row.service = service;
        row.service_id = service.id;
        row.unit = service.unit;
        row.task = service.name;
        row.tax_code = service.sales_vat_code;
        row.intermediated_service = service.intermediated_service || false;
        row.setFocus('quantity');
    }
    onServiceChanged(...args) {
        console.log('onServiceChanged', args);
    }
    calculateUnitPrice(...args) {
        const [row, tax, decimals] = args;
        row.unit_price = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) / (0, big_js_1.default)(row.quantity) / (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    }
    calculateNetAmount(...args) {
        const [row, tax, decimals] = args;
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.quantity) * (0, big_js_1.default)(row.unit_price) * (1 - (0, big_js_1.default)(row.discount) / 100)).round(decimals).toNumber();
    }
    calculateNetAmountFromAmount(...args) {
        const [row, tax, decimals] = args;
        row.net_amount = (0, big_js_1.default)((0, big_js_1.default)(row.amount) / (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
    }
    calculateAmount(...args) {
        const [row, tax, decimals] = args;
        row.amount = (0, big_js_1.default)((0, big_js_1.default)(row.net_amount) * (1 + (0, big_js_1.default)(tax.rate) / 100)).round(decimals).toNumber();
    }
    recalculateLineItem(...args) {
        const data = args[0].data;
        const row = data.row;
        const decimals = 2;
        const tx = _.find(this.taxCodes, { id: row.tax_code });
        if (row.quantity === 0) {
            return;
        }
        switch (data.field) {
            case 'quantity':
            case 'unit_price':
            case 'discount': {
                this.calculateNetAmount(row, tx, decimals);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'net_amount': {
                this.calculateUnitPrice(row, tx, decimals);
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'tax_code': {
                this.calculateAmount(row, tx, decimals);
                break;
            }
            case 'amount': {
                this.calculateNetAmountFromAmount(row, tx, decimals);
                this.calculateUnitPrice(row, tx, decimals);
                break;
            }
        }
    }
    onWorksheet(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("worksheet");
    }
    onIs_readonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("isReadonly");
    }
    $$load_expenseTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } });
        });
    }
    $$load_taxCodes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_tax_code');
        });
    }
    $$load_units() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_unit');
        });
    }
    $$load_b_5962862420439144_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    name: "service",
                    type: "select2",
                    header: this.$fn.gettext("Task"),
                    width: "140px",
                    formatter: {
                        name: "template",
                        value: "<b-link :to=\"{\n\
  name: 'app.product',\n\
  params: { id: row.service_id }\n\
}\">{{ row.task }}</b-link>\n\
",
                    },
                    options: this.$fn.searchDataSource('suggest_product', { parameters: { product_types: 'service' } }),
                    optionValueField: "name",
                    valueField: "id",
                    textField: "name",
                    bindObject: true,
                    validator: ["required",
                    ],
                },
                {
                    name: "asset",
                    type: "select2",
                    header: this.$fn.gettext("Asset"),
                    width: "140px",
                    formatter: {
                        name: "template",
                        value: "<b-link :to=\"{\n\
  name: 'app.fixedAsset',\n\
  params: { id: row.asset_id }\n\
}\">{{ row.asset ? row.asset.name : '-' }}</b-link>\n\
",
                    },
                    options: this.$fn.searchDataSource('suggest_fixed_asset'),
                    optionValueField: "name",
                    valueField: "id",
                    textField: "name",
                    bindObject: true,
                },
                {
                    name: "date",
                    type: "date",
                    header: this.$fn.gettext("Date"),
                    width: "140px",
                    required: true,
                },
                {
                    name: "notes",
                    type: "textarea",
                    width: "200px",
                    header: this.$fn.gettext("Notes"),
                },
                {
                    name: "quantity",
                    type: "number",
                    default: 0,
                    header: this.$fn.pgettext("Quantity Abbr", "Qty"),
                    headerTooltip: this.$fn.gettext("Quantity"),
                    width: "60px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
                {
                    name: "unit",
                    header: this.$fn.pgettext("Unit Abbr", "Unit"),
                    headerTooltip: this.$fn.gettext("Unit"),
                    width: "70px",
                    textAlign: "center",
                    required: true,
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
                {
                    name: "unit_price",
                    type: "number",
                    default: 0,
                    header: this.$fn.gettext("Price"),
                    width: "70px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
                {
                    name: "discount",
                    type: "number",
                    default: 0,
                    header: this.$fn.pgettext("AbbrDiscount", "Disc"),
                    headerTooltip: this.$fn.gettext("Discount"),
                    width: "70px",
                    required: true,
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
                {
                    name: "net_amount",
                    type: "number",
                    default: 0,
                    header: this.$fn.gettext("Net"),
                    width: "100px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
                {
                    name: "amount",
                    type: "number",
                    default: 0,
                    header: this.$fn.gettext("Amount"),
                    width: "100px",
                    textAlign: "right",
                    required: true,
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
                {
                    name: "tax_code",
                    header: this.$fn.gettext("VAT"),
                    headerTooltip: this.$fn.gettext("VAT Code"),
                    width: "100px",
                    type: "select",
                    options: this.taxCodes,
                    valueField: "id",
                    textField: "id",
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
                {
                    name: "intermediated_service",
                    header: this.$fn.pgettext("AbbrIntermediatedService", "IMS"),
                    headerTooltip: this.$fn.gettext("Intermediated Service"),
                    width: "60px",
                    type: "checkbox",
                    editorIsDisabled: row => !row.service || !row.service.id,
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            expenseTypes: null,
            taxCodes: null,
            units: null,
            ux: {
                errorHandling: "local",
                initialLoadSpinner: true,
            },
            b_5962862420439144_rowHeader: false,
            b_5962862420439144_paging: false,
            b_5962862420439144_sortable: false,
            b_5962862420439144_columns: null,
        });
    }
    act_5962862420439144_emit_5740_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_5962862420439144_saveCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5742_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.worksheet.id
                ? $event.data.waitFor(this.$fn.crud('work_item').upsert(Object.assign(Object.assign({}, $event.data.row), { worksheet_id: this.worksheet.id })))
                : this.worksheet.work_items.splice(this.worksheet.work_items.length - 1, 0, $event.data.row);
        });
    }
    act_5962862420439144_script_5742_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5742_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5744_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.worksheet.id
                ? $event.data.waitFor(this.$fn.crud('work_item').delete($event.data.row))
                : _.remove(this.worksheet.work_items, $event.data.row);
        });
    }
    act_5962862420439144_script_5744_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5744_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5746_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onServiceSelected($event.data.row, $event.data.option);
        });
    }
    act_5962862420439144_script_5746_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5746_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_serviceSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_serviceSelected.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5748_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onServiceChanged($event);
        });
    }
    act_5962862420439144_script_5748_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5748_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_serviceChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_serviceChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5750_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_5750_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5750_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_quantityChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_quantityChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5752_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_5752_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5752_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_unitPriceChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_unitPriceChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5754_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_5754_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5754_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_discountChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_discountChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5756_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_5756_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5756_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_netAmountChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_netAmountChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5758_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_5758_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5758_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_amountChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_amountChange.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5760_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateLineItem($event);
        });
    }
    act_5962862420439144_script_5760_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5760_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_taxCodeChange(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_taxCodeChange.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_5962862420439144_emit_5740 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_5740_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_saveCompleted_5739 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_5740,
            event: "save-completed",
            displayName: "emit",
        });
        const evh_5962862420439144_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_saveCompleted_5739],
        });
        const act_5962862420439144_script_5742 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5742_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_save_5741 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5742,
            event: "save",
            displayName: "script",
        });
        const evh_5962862420439144_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_save_5741],
        });
        const act_5962862420439144_script_5744 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5744_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_delete_5743 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5744,
            event: "delete",
            displayName: "script",
        });
        const evh_5962862420439144_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_delete_5743],
        });
        const act_5962862420439144_script_5746 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5746_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_serviceSelected_5745 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5746,
            event: "service:selected",
            displayName: "script",
        });
        const evh_5962862420439144_serviceSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_serviceSelected_5745],
        });
        const act_5962862420439144_script_5748 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5748_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_serviceChange_5747 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5748,
            event: "service:change",
            displayName: "script",
        });
        const evh_5962862420439144_serviceChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_serviceChange_5747],
        });
        const act_5962862420439144_script_5750 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5750_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_quantityChange_5749 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5750,
            event: "quantity:change",
            displayName: "script",
        });
        const evh_5962862420439144_quantityChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_quantityChange_5749],
        });
        const act_5962862420439144_script_5752 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5752_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_unitPriceChange_5751 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5752,
            event: "unit_price:change",
            displayName: "script",
        });
        const evh_5962862420439144_unitPriceChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_unitPriceChange_5751],
        });
        const act_5962862420439144_script_5754 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5754_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_discountChange_5753 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5754,
            event: "discount:change",
            displayName: "script",
        });
        const evh_5962862420439144_discountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_discountChange_5753],
        });
        const act_5962862420439144_script_5756 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5756_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_netAmountChange_5755 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5756,
            event: "net_amount:change",
            displayName: "script",
        });
        const evh_5962862420439144_netAmountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_netAmountChange_5755],
        });
        const act_5962862420439144_script_5758 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5758_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_amountChange_5757 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5758,
            event: "amount:change",
            displayName: "script",
        });
        const evh_5962862420439144_amountChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_amountChange_5757],
        });
        const act_5962862420439144_script_5760 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5760_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_taxCodeChange_5759 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5760,
            event: "tax_code:change",
            displayName: "script",
        });
        const evh_5962862420439144_taxCodeChange = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_taxCodeChange_5759],
        });
        return {
            act_5962862420439144_emit_5740,
            evh_5962862420439144_saveCompleted_5739,
            evh_5962862420439144_saveCompleted,
            act_5962862420439144_script_5742,
            evh_5962862420439144_save_5741,
            evh_5962862420439144_save,
            act_5962862420439144_script_5744,
            evh_5962862420439144_delete_5743,
            evh_5962862420439144_delete,
            act_5962862420439144_script_5746,
            evh_5962862420439144_serviceSelected_5745,
            evh_5962862420439144_serviceSelected,
            act_5962862420439144_script_5748,
            evh_5962862420439144_serviceChange_5747,
            evh_5962862420439144_serviceChange,
            act_5962862420439144_script_5750,
            evh_5962862420439144_quantityChange_5749,
            evh_5962862420439144_quantityChange,
            act_5962862420439144_script_5752,
            evh_5962862420439144_unitPriceChange_5751,
            evh_5962862420439144_unitPriceChange,
            act_5962862420439144_script_5754,
            evh_5962862420439144_discountChange_5753,
            evh_5962862420439144_discountChange,
            act_5962862420439144_script_5756,
            evh_5962862420439144_netAmountChange_5755,
            evh_5962862420439144_netAmountChange,
            act_5962862420439144_script_5758,
            evh_5962862420439144_amountChange_5757,
            evh_5962862420439144_amountChange,
            act_5962862420439144_script_5760,
            evh_5962862420439144_taxCodeChange_5759,
            evh_5962862420439144_taxCodeChange,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcWorksheetWorkItems.prototype, "worksheet", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('worksheet'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcWorksheetWorkItems.prototype, "onWorksheet", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcWorksheetWorkItems.prototype, "isReadonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('isReadonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcWorksheetWorkItems.prototype, "onIs_readonly", null);
UcWorksheetWorkItems = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcWorksheetWorkItems);
exports.default = UcWorksheetWorkItems;
vue_property_decorator_1.Vue.component("UcWorksheetWorkItems", UcWorksheetWorkItems);
