<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpText
    tag="h5"
    capitalize
    muted
    class="mb-0"
  >
    {{ resourceTitle }}
  </ItpText>
  <span
    class="m-0 p-0"
  >
    &middot;
  </span>
  <ItpText
    tag="h4"
    :text="resourceKey"
    class="mb-0"
  >
  </ItpText>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!account.isNew"
      @click.prevent.stop="evh_6917646200545441_clickPreventStop($event, {})"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      class="border"
      v-if="!account.isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="account"
        :tooltip="'Delete account'|pgettext('Account')"
        v-if="!account.isNew || !account.isReadOnly"
        @success="evh_997556510679958_success($event, {})"
      >
      </BtnDeleteResource>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcAccountHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  account!: object;

  @Watch('account')
  onAccount(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("account")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          resourceTitle: this.$fn.pgettext("Account", "Account"),
          newAccount: this.$fn.pgettext("Account", "New account"),
        }
        ,
        ux: null,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.resourceTitle
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.account.isNew ? this.messages.newAccount : this.account.snapshot('name')
    }
    return null;
  }

  async act_6917646200545441_emit_5166_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6917646200545441_emit_5166, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_6917646200545441_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6917646200545441_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_997556510679958_emit_5168_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_997556510679958_emit_5168, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_997556510679958_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_997556510679958_success.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_6917646200545441_emit_5166: actions.EmitAction;
    evh_6917646200545441_clickPreventStop_5165: actions.EventHandlerImpl;
    evh_6917646200545441_clickPreventStop: actions.EventHandlerGroup;
    act_997556510679958_emit_5168: actions.EmitAction;
    evh_997556510679958_success_5167: actions.EventHandlerImpl;
    evh_997556510679958_success: actions.EventHandlerGroup;
  }

  getActions() {
    const act_6917646200545441_emit_5166 = new actions.EmitAction(
      {
        actionArgs: this.act_6917646200545441_emit_5166_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_6917646200545441_clickPreventStop_5165 = new actions.EventHandlerImpl(
      {
        action: act_6917646200545441_emit_5166,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6917646200545441_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6917646200545441_clickPreventStop_5165],
      }
    );
    const act_997556510679958_emit_5168 = new actions.EmitAction(
      {
        actionArgs: this.act_997556510679958_emit_5168_getActionArgs,
        events: [],
      }
    );
    const evh_997556510679958_success_5167 = new actions.EventHandlerImpl(
      {
        action: act_997556510679958_emit_5168,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_997556510679958_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_997556510679958_success_5167],
      }
    );
    return {
      act_6917646200545441_emit_5166,
      evh_6917646200545441_clickPreventStop_5165,
      evh_6917646200545441_clickPreventStop,
      act_997556510679958_emit_5168,
      evh_997556510679958_success_5167,
      evh_997556510679958_success,
      reload: act_6917646200545441_emit_5166,
    }
  }
}

Vue.component("UcAccountHeader", UcAccountHeader);

</script>