<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
    class="pb-3"
  >
    <ItpIcon
      :name="$config.contract.icon"
      spacing="mr-3"
      :title="'Contract'|pgettext('Contract')"
      class="mr-2"
    >
    </ItpIcon>
    <ItpLink
      :to="{
    name: 'app.contract',
    params: {id: item.id}
  }"
    >
      <ItpText
        strong
        :text="item.contract_number"
      >
      </ItpText>
    </ItpLink>
    <UcContractTypeBadge
      :value="item.contract_type"
      class="mx-3"
    >
    </UcContractTypeBadge>
    <ItpLink
      :to="{
    name: 'app.client',
    params: {id: item.counterparty_id}
  }"
    >
      <ItpText
        strong
        :text="item.counterparty_name"
      >
      </ItpText>
    </ItpLink>
    <ItpLink
      :to="{
    name: 'app.rental',
    params: {id: item.rental_id}
  }"
      class="px-3"
      v-if="displayRental"
    >
      <ItpText
        strong
        :text="item.rental_name"
      >
      </ItpText>
    </ItpLink>
  </ItpBox>
  <ItpBox
    alignItems="center"
  >
    <UcValidityPeriod
      :from="item.valid_from"
      :to="item.valid_to"
      class="pr-3"
    >
    </UcValidityPeriod>
    <UcContractStatusBadge
      :value="item.status"
      class="mr-3"
    >
    </UcContractStatusBadge>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
    default: true,
  })
  displayRental!: boolean;

  @Watch('displayRental')
  onDisplay_rental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("displayRental")
  }

  @Prop({
    type: String,
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4524: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4523: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4524 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4523 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4524,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4523],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4524,
      evh_7315092382398562_reload_4523,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContractCard", UcContractCard);

</script>