<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateMeterReadingsParameters"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="createOptions"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="notes"
        :label="'Notes'|pgettext('CreateMeterReadings')"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="notes"
          v-model="params.notes"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="dateOverride"
        :label="'Reading date'|pgettext('CreateMeterReadings')"
        :description="'Use this date as reading date instead.'|pgettext('CreateMeterReadings')"
        :labelAlign="labelAlign"
      >
        <ItpFormDatetime
          name="dateOverride"
          clearable
          v-model="params.date_override"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms.createOptions && forms.createOptions.id"
      :text="'Create Readings'|pgettext('MeterMeasurementGenerateButton')"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|pgettext('CreateMeterReadings')"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateMeterReadingsParameters extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_params = new ComponentValueCache(this, "params", "CreateMeterReadingsParameters");
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  params!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['params', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        params: {
          notes: null,
          date_override: null,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("CreateMeterReadings", "Create Meter Readings"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5426_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5426, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_5430_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_5430, alias=submit
    return {
      result: {
        close: true,
        ok: true,
        value: this.params,
      }
      ,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5426: actions.CloseModalAction;
    evh_7315092382398562_close_5425: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_5428: actions.CloseComponentAction;
    evh_2248226175642056_close_5427: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_5430: actions.CloseComponentAction;
    evh_1040167445267876_submit_5429: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_5432: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_5431: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5426 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5426_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5425 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5426,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5425],
      }
    );
    const act_2248226175642056_closeComponent_5428 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5427 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_5428,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5427],
      }
    );
    const act_1040167445267876_closeComponent_5430 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_5430_getActionArgs,
        displayName: "submit",
        events: [],
      }
    );
    const evh_1040167445267876_submit_5429 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_5430,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_5429],
      }
    );
    const act_1419464017721962_closeComponent_5432 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_5431 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_5432,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_5431],
      }
    );
    return {
      act_7315092382398562_closeModal_5426,
      evh_7315092382398562_close_5425,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_5428,
      evh_2248226175642056_close_5427,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_5430,
      evh_1040167445267876_submit_5429,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_5432,
      evh_1419464017721962_clickPreventStop_5431,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_closeComponent_5430,
    }
  }
}

Vue.component("CreateMeterReadingsParameters", CreateMeterReadingsParameters);

</script>