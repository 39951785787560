<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateContract"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <DIV
      class="col"
    >
      <ItpFormGroup
        name="contract_type"
        :label="'Contract type'|gettext"
        row
        labelColsMd="3"
        contentColsMd="4"
        required
      >
        <ItpFormSelect2
          name="contract_type"
          v-model="contract_type"
          :options="contractTypes"
          valueField="value"
          textField="text"
          required
          @optionSelected="evh_7789867021953041_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
    </DIV>
    <hr>
    </hr>
    <ItpForm
      :id="formId"
      :name="formName"
      class="col"
    >
      <b-tabs
        v-model="currentTab"
        vertical
        pills
      >
        <b-tab
          :title="'General'|pgettext('Contract')"
        >
          <FieldsetContractNumbersData
            :contract="contract"
            :labelAlign="labelAlign"
          >
          </FieldsetContractNumbersData>
          <hr>
          </hr>
          <FieldsetContractValidityData
            :contract="contract"
            :labelAlign="labelAlign"
          >
          </FieldsetContractValidityData>
        </b-tab>
        <b-tab
          :title="companyTranslation"
        >
          <FieldsetContractCompanyData
            :contract="contract"
            :labelAlign="labelAlign"
          >
          </FieldsetContractCompanyData>
        </b-tab>
        <b-tab
          :title="counterpartyTranslation"
        >
          <FieldsetContractClientData
            :contract="contract"
            :labelAlign="labelAlign"
          >
          </FieldsetContractClientData>
        </b-tab>
        <b-tab
          :title="'Rental'|pgettext('Contract')"
        >
          <FieldsetContractRentalData
            :contract="contract"
            :labelAlign="labelAlign"
            no-legend
          >
          </FieldsetContractRentalData>
        </b-tab>
        <b-tab
          :title="'Financial'|pgettext('Contract')"
        >
          <FieldsetContractInvoicing
            :contract="contract"
            :labelAlign="labelAlign"
          >
          </FieldsetContractInvoicing>
        </b-tab>
      </b-tabs>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpBox
      class="mr-auto"
    >
      <ItpButton
        :text="'Previous'|gettext"
        :disabled="currentTab == 0"
        @click.prevent.stop="evh_2140198529698299_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <ItpBox>
      <ItpButton
        :text="'Next'|gettext"
        :disabled="currentTab > 3"
        @click.prevent.stop="evh_8790374038998522_clickPreventStop($event, {})"
      >
      </ItpButton>
      <ItpButton
        type="submit"
        :form="formId"
        variant="primary"
        icon="fa-save"
        :text="'Create contract'|gettext"
        :spinning="actions.save.isRunning"
        :disabled="!forms[formName] || !forms[formName].submittable"
        class="ml-3"
        @click.prevent.stop="evh_309864017738714_clickPreventStop($event, {})"
      >
      </ItpButton>
      <ItpButton
        variant="default"
        :text="'Close'|gettext"
        :disabled="actions.save.isRunning"
        class="ml-3"
        @click.prevent.stop="evh_1589624209624921_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateContract extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_contract_type = new ComponentValueCache(this, "contract_type", "CreateContract.contractType");
  }

  mapDefaultContract(...args: any[]) {
    const data = args[0]
    return _.extend(data, { counterparty: {}, rental: {} })
  }

  @Prop({
    type: String,
  })
  rentalId!: string;

  @Watch('rentalId')
  onRental_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rentalId")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  contract_type!: any;
  contract!: any;

  async $$load_contract() {
    return this.$fn.fetch('read_contract', {
      parameters: {
        id: 'defaults',
        type: this.$data.contract_type,
        rental: this.rentalId
      }
    })
  }

  contractTypes!: any;

  async $$load_contractTypes() {
    return this.$fn.getEnumValuesFromSchema('ContractTypes')
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  currentTab!: any;
  categories!: any;

  async $$load_categories() {
    return this.$fn.fetch('list_contract_category')
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['contract_type', 'contract', 'contractTypes', 'formName', 'formId', 'labelAlign', 'currentTab', 'categories', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        contract_type: "rental",
        contract: null,
        contractTypes: null,
        formName: "contract",
        formId: null,
        labelAlign: "left",
        currentTab: 0,
        categories: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get companyTranslation() {
    if (this.loaded) {

      return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
        this.$fn.pgettext('Contract', 'Lessor') :
        this.$fn.pgettext('Contract', 'Company')
    }
    return null;
  }

  get counterpartyTranslation() {
    if (this.loaded) {

      return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
        this.$fn.pgettext('Contract', 'Tenant') :
        this.$fn.pgettext('Contract', 'Counterparty')
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Create Contract"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2154_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2154, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_setData_2160_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7789867021953041_setData_2160, alias=undefined
    return {
      path: "contract",
      value: this.mapDefaultContract($event.data.response.data),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_7789867021953041_request_2158_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7789867021953041_request_2158, alias=undefined
    return {
      operation: "read_contract",
      parameters: {
        id: "defaults",
        type: $event.data.value,
      }
      ,
    }
  }

  async evh_7789867021953041_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_2140198529698299_setData_2162_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2140198529698299_setData_2162, alias=undefined
    return {
      path: "currentTab",
      value: this.currentTab - 1,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2140198529698299_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2140198529698299_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8790374038998522_setData_2164_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8790374038998522_setData_2164, alias=undefined
    return {
      path: "currentTab",
      value: this.currentTab + 1,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_8790374038998522_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8790374038998522_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_309864017738714_reloadSlickgrid_2168_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_309864017738714_reloadSlickgrid_2168, alias=undefined
    return {
      grid: "contracts",
    }
  }

  async act_309864017738714_closeComponent_2170_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_309864017738714_closeComponent_2170, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async act_309864017738714_navigate_2172_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_309864017738714_navigate_2172, alias=undefined
    return {
      location: {
        name: "app.contract",
        params: {
          id: $event.data.id,
        }
        ,
      }
      ,
    }
  }

  async act_309864017738714_crud_2166_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_309864017738714_crud_2166, alias=save
    return {
      objectType: "contract",
      op: "upsert",
      data: this.contract,
    }
  }

  async evh_309864017738714_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_309864017738714_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_1589624209624921_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1589624209624921_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2154: actions.CloseModalAction;
    evh_7315092382398562_close_2153: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2156: actions.CloseComponentAction;
    evh_2248226175642056_close_2155: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_7789867021953041_setData_2160: actions.SetDataAction;
    evh_7789867021953041_success_2159: actions.EventHandlerImpl;
    act_7789867021953041_request_2158: actions.RequestAction;
    evh_7789867021953041_optionSelected_2157: actions.EventHandlerImpl;
    evh_7789867021953041_optionSelected: actions.EventHandlerGroup;
    act_2140198529698299_setData_2162: actions.SetDataAction;
    evh_2140198529698299_clickPreventStop_2161: actions.EventHandlerImpl;
    evh_2140198529698299_clickPreventStop: actions.EventHandlerGroup;
    act_8790374038998522_setData_2164: actions.SetDataAction;
    evh_8790374038998522_clickPreventStop_2163: actions.EventHandlerImpl;
    evh_8790374038998522_clickPreventStop: actions.EventHandlerGroup;
    act_309864017738714_reloadSlickgrid_2168: actions.ReloadSlickgridAction;
    evh_309864017738714_success_2167: actions.EventHandlerImpl;
    act_309864017738714_closeComponent_2170: actions.CloseComponentAction;
    evh_309864017738714_success_2169: actions.EventHandlerImpl;
    act_309864017738714_navigate_2172: actions.NavigateAction;
    evh_309864017738714_success_2171: actions.EventHandlerImpl;
    act_309864017738714_crud_2166: actions.CRUDAction;
    evh_309864017738714_clickPreventStop_2165: actions.EventHandlerImpl;
    evh_309864017738714_clickPreventStop: actions.EventHandlerGroup;
    act_1589624209624921_closeModal_2174: actions.CloseModalAction;
    evh_1589624209624921_clickPreventStop_2173: actions.EventHandlerImpl;
    evh_1589624209624921_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2154 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2154_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2153 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2154,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2153],
      }
    );
    const act_2248226175642056_closeComponent_2156 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2155 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2156,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2155],
      }
    );
    const act_7789867021953041_setData_2160 = new actions.SetDataAction(
      {
        actionArgs: this.act_7789867021953041_setData_2160_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_success_2159 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_setData_2160,
        event: "success",
        displayName: "setData",
      }
    );
    const act_7789867021953041_request_2158 = new actions.RequestAction(
      {
        actionArgs: this.act_7789867021953041_request_2158_getActionArgs,
        events: [evh_7789867021953041_success_2159],
      }
    );
    const evh_7789867021953041_optionSelected_2157 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_request_2158,
        event: "optionSelected",
        displayName: "request",
      }
    );
    const evh_7789867021953041_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_optionSelected_2157],
      }
    );
    const act_2140198529698299_setData_2162 = new actions.SetDataAction(
      {
        actionArgs: this.act_2140198529698299_setData_2162_getActionArgs,
        events: [],
      }
    );
    const evh_2140198529698299_clickPreventStop_2161 = new actions.EventHandlerImpl(
      {
        action: act_2140198529698299_setData_2162,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_2140198529698299_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2140198529698299_clickPreventStop_2161],
      }
    );
    const act_8790374038998522_setData_2164 = new actions.SetDataAction(
      {
        actionArgs: this.act_8790374038998522_setData_2164_getActionArgs,
        events: [],
      }
    );
    const evh_8790374038998522_clickPreventStop_2163 = new actions.EventHandlerImpl(
      {
        action: act_8790374038998522_setData_2164,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_8790374038998522_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8790374038998522_clickPreventStop_2163],
      }
    );
    const act_309864017738714_reloadSlickgrid_2168 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_309864017738714_reloadSlickgrid_2168_getActionArgs,
        events: [],
      }
    );
    const evh_309864017738714_success_2167 = new actions.EventHandlerImpl(
      {
        action: act_309864017738714_reloadSlickgrid_2168,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_309864017738714_closeComponent_2170 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_309864017738714_closeComponent_2170_getActionArgs,
        events: [],
      }
    );
    const evh_309864017738714_success_2169 = new actions.EventHandlerImpl(
      {
        action: act_309864017738714_closeComponent_2170,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_309864017738714_navigate_2172 = new actions.NavigateAction(
      {
        actionArgs: this.act_309864017738714_navigate_2172_getActionArgs,
        events: [],
      }
    );
    const evh_309864017738714_success_2171 = new actions.EventHandlerImpl(
      {
        action: act_309864017738714_navigate_2172,
        event: "success",
        displayName: "navigate",
      }
    );
    const act_309864017738714_crud_2166 = new actions.CRUDAction(
      {
        actionArgs: this.act_309864017738714_crud_2166_getActionArgs,
        displayName: "save",
        events: [evh_309864017738714_success_2167, evh_309864017738714_success_2169, evh_309864017738714_success_2171],
      }
    );
    const evh_309864017738714_clickPreventStop_2165 = new actions.EventHandlerImpl(
      {
        action: act_309864017738714_crud_2166,
        event: "click.prevent.stop",
        displayName: "save",
      }
    );
    const evh_309864017738714_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_309864017738714_clickPreventStop_2165],
      }
    );
    const act_1589624209624921_closeModal_2174 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_1589624209624921_clickPreventStop_2173 = new actions.EventHandlerImpl(
      {
        action: act_1589624209624921_closeModal_2174,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1589624209624921_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1589624209624921_clickPreventStop_2173],
      }
    );
    return {
      act_7315092382398562_closeModal_2154,
      evh_7315092382398562_close_2153,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2156,
      evh_2248226175642056_close_2155,
      evh_2248226175642056_close,
      act_7789867021953041_setData_2160,
      evh_7789867021953041_success_2159,
      act_7789867021953041_request_2158,
      evh_7789867021953041_optionSelected_2157,
      evh_7789867021953041_optionSelected,
      act_2140198529698299_setData_2162,
      evh_2140198529698299_clickPreventStop_2161,
      evh_2140198529698299_clickPreventStop,
      act_8790374038998522_setData_2164,
      evh_8790374038998522_clickPreventStop_2163,
      evh_8790374038998522_clickPreventStop,
      act_309864017738714_reloadSlickgrid_2168,
      evh_309864017738714_success_2167,
      act_309864017738714_closeComponent_2170,
      evh_309864017738714_success_2169,
      act_309864017738714_navigate_2172,
      evh_309864017738714_success_2171,
      act_309864017738714_crud_2166,
      evh_309864017738714_clickPreventStop_2165,
      evh_309864017738714_clickPreventStop,
      act_1589624209624921_closeModal_2174,
      evh_1589624209624921_clickPreventStop_2173,
      evh_1589624209624921_clickPreventStop,
      save: act_309864017738714_crud_2166,
    }
  }
}

Vue.component("CreateContract", CreateContract);

</script>