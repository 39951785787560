"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MCreateFixedAsset = class MCreateFixedAsset extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formName', 'formId', 'item', 'fixedAssetTypes', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onAsset_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("assetType");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.schemaDefaults('CreateFixedAsset', {
                asset_type: this.assetType,
                waste_bin: {
                    bin_type: null,
                    bin_number: null,
                    capacity: {
                        amount: null,
                        unit: 'liter'
                    }
                }
            });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formName: "rental",
            formId: null,
            item: null,
            fixedAssetTypes: [{
                    id: "general",
                    name: this.$fn.pgettext("FixedAssetTypes", "General"),
                },
                {
                    id: "waste_bin",
                    name: this.$fn.pgettext("FixedAssetTypes", "Waste Bin"),
                },
            ],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create Fixed Asset"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1516_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_1522_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1040167445267876_navigate_1524_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.fixedAsset",
                    params: {
                        id: $event.data.response.data.id,
                    },
                },
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_1526_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "fixedAssets",
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_1528_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "wasteBins",
            };
        });
    }
    act_1040167445267876_request_1520_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_fixed_asset",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1516 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1516_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1515 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1516,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1515],
        });
        const act_2248226175642056_closeModal_1518 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_1517 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_1518,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1517],
        });
        const act_1040167445267876_closeModal_1522 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_1522_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1521 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_1522,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_navigate_1524 = new core_1.actions.NavigateAction({
            actionArgs: this.act_1040167445267876_navigate_1524_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1523 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_navigate_1524,
            event: "success",
            displayName: "navigate",
        });
        const act_1040167445267876_reloadSlickgrid_1526 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_1526_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1525 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_1526,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_reloadSlickgrid_1528 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_1528_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1527 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_1528,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_request_1520 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_1520_getActionArgs,
            displayName: "create",
            events: [evh_1040167445267876_success_1521, evh_1040167445267876_success_1523, evh_1040167445267876_success_1525, evh_1040167445267876_success_1527],
        });
        const evh_1040167445267876_submit_1519 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_1520,
            event: "submit",
            displayName: "create",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_1519],
        });
        const act_1419464017721962_closeComponent_1530 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_1529 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_1530,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_1529],
        });
        return {
            act_7315092382398562_closeModal_1516,
            evh_7315092382398562_close_1515,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_1518,
            evh_2248226175642056_close_1517,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_1522,
            evh_1040167445267876_success_1521,
            act_1040167445267876_navigate_1524,
            evh_1040167445267876_success_1523,
            act_1040167445267876_reloadSlickgrid_1526,
            evh_1040167445267876_success_1525,
            act_1040167445267876_reloadSlickgrid_1528,
            evh_1040167445267876_success_1527,
            act_1040167445267876_request_1520,
            evh_1040167445267876_submit_1519,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_1530,
            evh_1419464017721962_clickPreventStop_1529,
            evh_1419464017721962_clickPreventStop,
            create: act_1040167445267876_request_1520,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "general",
    }),
    __metadata("design:type", String)
], MCreateFixedAsset.prototype, "assetType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('assetType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MCreateFixedAsset.prototype, "onAsset_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], MCreateFixedAsset.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MCreateFixedAsset.prototype, "onSize", null);
MCreateFixedAsset = __decorate([
    (0, vue_property_decorator_1.Component)()
], MCreateFixedAsset);
exports.default = MCreateFixedAsset;
vue_property_decorator_1.Vue.component("MCreateFixedAsset", MCreateFixedAsset);
