<template>
<ItpRow>
  <ItpCol
    w="6"
  >
    <BListGroup
      flush
      class="list-group-alt-1"
    >
      <ItpLabeledListGroupItem
        :label="'Invoice For'|pgettext('Invoice')"
        class="mb-2"
      >
        <UcClientMiniCard
          :item="invoice.client"
        >
        </UcClientMiniCard>
        <UcAddressCard
          :item="invoice.client.address"
          compact-display
          class="mb-0"
        >
        </UcAddressCard>
        <ItpBox>
          <span>
            {{ invoice.client.tax_number }}
          </span>
        </ItpBox>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Invoice date'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.invoice_date"
      >
        <ItpText
          strong
        >
          {{ invoice.invoice_date | date }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Fulfillment date'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.fulfillment_date"
      >
        <ItpText
          strong
        >
          {{ invoice.fulfillment_date | date }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Billing period'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.billing_period && (invoice.billing_period.start || invoice.billing_period.end)"
      >
        <DIV
          class="text-nowrap"
        >
          <strong>
            {{ invoice.billing_period.start | date }}
          </strong>
          <strong>
            -
          </strong>
          <strong>
            {{ invoice.billing_period.end | date }}
          </strong>
        </DIV>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Reference date'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.reference_date"
      >
        <ItpText
          strong
        >
          {{ invoice.reference_date | date }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Reference Number'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.reference_number"
      >
        <ItpText
          strong
        >
          {{ invoice.reference_number }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Order Number'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.order_number"
      >
        <ItpText
          strong
        >
          {{ invoice.order_number }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'File Number'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.file_number"
      >
        <ItpText
          strong
        >
          {{ invoice.file_number }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <BListGroup
        flush
        class="list-group-alt-1"
      >
        <ItpLabeledListGroupItem
          :label="'Notes'|pgettext('Invoice')"
        >
          <ItpText
            :text="invoice.notes"
            pre
          >
          </ItpText>
        </ItpLabeledListGroupItem>
      </BListGroup>
      <BListGroup
        flush
        class="list-group-alt-1"
        v-if="invoice.internal_notes"
      >
        <ItpLabeledListGroupItem
          :label="'Internal Notes'|pgettext('Invoice')"
        >
          <ItpText
            :text="invoice.internal_notes"
            pre
          >
          </ItpText>
        </ItpLabeledListGroupItem>
      </BListGroup>
    </BListGroup>
  </ItpCol>
  <ItpCol
    w="6"
  >
    <BListGroup
      flush
      class="list-group-alt-1"
    >
      <ItpLabeledListGroupItem
        :label="'Currency'|pgettext('Invoice')"
        strong
        class="mb-2"
        v-if="invoice.currency"
      >
        <ItpText
          strong
        >
          {{ invoice.currency }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Exchange rate'|pgettext('Invoice')"
        strong
        class="mb-2"
        v-if="invoice.exchange_rate"
      >
        <ItpText
          strong
        >
          {{ invoice.exchange_rate | number }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Due date'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.due_date"
      >
        <ItpText
          strong
        >
          {{ invoice.due_date | date }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Payment Method'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.payment_method"
      >
        <ItpText
          strong
        >
          {{ invoice.payment_method  | pgettext('PaymentMethod', 'Sentence') }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Expense Type'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.expense_type"
      >
        <ItpText
          strong
        >
          {{ invoice.expense_type | pgettext('ExpenseType', 'Sentence') }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Register entries'|pgettext('Invoice')"
        class="mb-2"
        v-if="invoice.register_entries.data && invoice.register_entries.data.length"
      >
        <UcInvoiceRegisterEntryMiniCard
          :key="entry.id"
          :item="entry"
          no-icon
          v-for="entry in invoice.register_entries.data"
        >
        </UcInvoiceRegisterEntryMiniCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'References'|pgettext('Invoice')"
        class="mt-2 mb-2"
      >
        <UcInvoiceReferences
          :invoice="invoice"
          :reload="lastLoaded"
          @reload="evh_4152404563918957_reload($event, {})"
        >
        </UcInvoiceReferences>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Payments'|pgettext('Invoice')"
        class="mt-2 mb-2"
        v-if="invoice.totals"
      >
        <UcInvoicePayments
          :invoice="invoice"
          :reload="lastLoaded"
          @reload="evh_3855552110905499_reload($event, {})"
        >
        </UcInvoicePayments>
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpCol>
</ItpRow>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get isReadonly() {
    if (this.loaded) {

      return false
    }
    return null;
  }

  get invoiceTypeName() {
    if (this.loaded) {

      return this.invoice.invoice_type === "correction" ? this.$fn.pgettext("InvoiceType", "Correction") :
        (this.invoice.invoice_type === "storno" ? this.$fn.pgettext("InvoiceType", "Storno") :
          (this.invoice.invoice_type === "proforma" ? this.$fn.pgettext("InvoiceType", "Pro Forma") : "?"))
    }
    return null;
  }

  async act_4152404563918957_emit_5070_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4152404563918957_emit_5070, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_4152404563918957_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4152404563918957_reload.executeFromDOM(this, event, scope);
  }

  async act_3855552110905499_emit_5072_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3855552110905499_emit_5072, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3855552110905499_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3855552110905499_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_4152404563918957_emit_5070: actions.EmitAction;
    evh_4152404563918957_reload_5069: actions.EventHandlerImpl;
    evh_4152404563918957_reload: actions.EventHandlerGroup;
    act_3855552110905499_emit_5072: actions.EmitAction;
    evh_3855552110905499_reload_5071: actions.EventHandlerImpl;
    evh_3855552110905499_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_4152404563918957_emit_5070 = new actions.EmitAction(
      {
        actionArgs: this.act_4152404563918957_emit_5070_getActionArgs,
        events: [],
      }
    );
    const evh_4152404563918957_reload_5069 = new actions.EventHandlerImpl(
      {
        action: act_4152404563918957_emit_5070,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_4152404563918957_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_4152404563918957_reload_5069],
      }
    );
    const act_3855552110905499_emit_5072 = new actions.EmitAction(
      {
        actionArgs: this.act_3855552110905499_emit_5072_getActionArgs,
        events: [],
      }
    );
    const evh_3855552110905499_reload_5071 = new actions.EventHandlerImpl(
      {
        action: act_3855552110905499_emit_5072,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_3855552110905499_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_3855552110905499_reload_5071],
      }
    );
    return {
      act_4152404563918957_emit_5070,
      evh_4152404563918957_reload_5069,
      evh_4152404563918957_reload,
      act_3855552110905499_emit_5072,
      evh_3855552110905499_reload_5071,
      evh_3855552110905499_reload,
    }
  }
}

Vue.component("UcInvoiceData", UcInvoiceData);

</script>