"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "letterRegister",
    path: "/letter-register",
    component: "LetterRegister"
});
let LetterRegister = class LetterRegister extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'companies', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowColumnSelectorButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
        this.$$cache_selectedRegisterId = new core_1.ComponentValueCache(this, "selectedRegisterId", "LetterRegister.selectedRegisterId");
    }
    getQueryParameters(...args) {
        return {
            sort_mode: 'desc',
            register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
        };
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_registers() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_register')
                .then(data => _.filter(data, d => _.includes(d.register_type, 'letters')));
        });
    }
    $$load_selectedRegister() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0];
        });
    }
    $$load_companies() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_company').then(resp => resp.data);
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            registers: null,
            selectedRegisterId: null,
            selectedRegister: null,
            selectedItems: [],
            companies: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_headerShowColumnSelectorButton: false,
            b_2248226175642056_columnDefaults: {
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "entry_number",
                    name: this.$fn.pgettext("Letter register", "No."),
                    type: "command",
                    formatter: {
                        name: "link",
                        href: "#",
                    },
                },
                {
                    field: "entry_date",
                    name: this.$fn.pgettext("Letter register", "Date"),
                    type: "date",
                },
                {
                    field: "delivery_modes",
                    name: this.$fn.pgettext("Letter register", "Delivery mode"),
                    formatter: {
                        name: "join",
                        separator: ", ",
                    },
                },
                {
                    field: "categories",
                    name: this.$fn.pgettext("Letter register", "Category"),
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("Letter register", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "subject",
                    name: this.$fn.pgettext("Letter register", "Subject"),
                },
                {
                    field: "notes",
                    name: this.$fn.pgettext("Letter register", "Notes"),
                },
                {
                    field: "delivery_date",
                    name: this.$fn.pgettext("Letter register", "Delivery date"),
                },
                {
                    field: "undelivered",
                    name: this.$fn.pgettext("Letter register", "Undelivered"),
                    type: "boolean",
                    formatter: {
                        name: "checkmark",
                    },
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Letter register", "Letter register"),
                icon: this.$config.letterRegister.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2736_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_reloadSlickgrid_2740_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "letterRegister",
            };
        });
    }
    act_2248226175642056_reloadSlickgrid_2742_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "letterRegister",
            };
        });
    }
    evh_2248226175642056_close_2741_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_2248226175642056_showModal_2738_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "LetterRegisterEntry",
                props: {
                    register: this.selectedRegister,
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_2248226175642056_command_2737_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'entry_number';
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_2744_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_reloadSlickgrid_2746_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "letterRegister",
            };
        });
    }
    act_6466705138206980_setData_2748_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedRegisterId",
                value: $event.data.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_6466705138206980_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_reloadSlickgrid_2752_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "letterRegister",
            };
        });
    }
    act_8320016629450276_reloadSlickgrid_2754_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "letterRegister",
            };
        });
    }
    evh_8320016629450276_close_2753_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_8320016629450276_showModal_2750_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "LetterRegisterEntry",
                props: {
                    register: this.selectedRegister,
                },
            };
        });
    }
    evh_8320016629450276_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2736 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2736_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2735 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2736,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2735],
        });
        const act_2248226175642056_reloadSlickgrid_2740 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2740_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_saved_2739 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2740,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_reloadSlickgrid_2742 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2742_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_close_2741 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2742,
            event: "close",
            when: this.evh_2248226175642056_close_2741_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_showModal_2738 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2738_getActionArgs,
            events: [evh_2248226175642056_saved_2739, evh_2248226175642056_close_2741],
        });
        const evh_2248226175642056_command_2737 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2738,
            event: "command",
            when: this.evh_2248226175642056_command_2737_getWhen,
            displayName: "showModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_2737],
        });
        const act_2248226175642056_setData_2744 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_2744_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_2743 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_2744,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_2743],
        });
        const act_6466705138206980_reloadSlickgrid_2746 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_6466705138206980_reloadSlickgrid_2746_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_input_2745 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_reloadSlickgrid_2746,
            event: "input",
            displayName: "reloadSlickgrid",
        });
        const act_6466705138206980_setData_2748 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_2748_getActionArgs,
            displayName: "updateSelectedRegisterId",
            events: [],
        });
        const evh_6466705138206980_input_2747 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_2748,
            event: "input",
            displayName: "updateSelectedRegisterId",
        });
        const evh_6466705138206980_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_input_2745, evh_6466705138206980_input_2747],
        });
        const act_8320016629450276_reloadSlickgrid_2752 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8320016629450276_reloadSlickgrid_2752_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_saved_2751 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadSlickgrid_2752,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_8320016629450276_reloadSlickgrid_2754 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8320016629450276_reloadSlickgrid_2754_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_close_2753 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadSlickgrid_2754,
            event: "close",
            when: this.evh_8320016629450276_close_2753_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_8320016629450276_showModal_2750 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_2750_getActionArgs,
            events: [evh_8320016629450276_saved_2751, evh_8320016629450276_close_2753],
        });
        const evh_8320016629450276_click_2749 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_2750,
            event: "click",
            displayName: "showModal",
        });
        const evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_2749],
        });
        return {
            act_7315092382398562_closeModal_2736,
            evh_7315092382398562_close_2735,
            evh_7315092382398562_close,
            act_2248226175642056_reloadSlickgrid_2740,
            evh_2248226175642056_saved_2739,
            act_2248226175642056_reloadSlickgrid_2742,
            evh_2248226175642056_close_2741,
            act_2248226175642056_showModal_2738,
            evh_2248226175642056_command_2737,
            evh_2248226175642056_command,
            act_2248226175642056_setData_2744,
            evh_2248226175642056_selectedRowsChanged_2743,
            evh_2248226175642056_selectedRowsChanged,
            act_6466705138206980_reloadSlickgrid_2746,
            evh_6466705138206980_input_2745,
            act_6466705138206980_setData_2748,
            evh_6466705138206980_input_2747,
            evh_6466705138206980_input,
            act_8320016629450276_reloadSlickgrid_2752,
            evh_8320016629450276_saved_2751,
            act_8320016629450276_reloadSlickgrid_2754,
            evh_8320016629450276_close_2753,
            act_8320016629450276_showModal_2750,
            evh_8320016629450276_click_2749,
            evh_8320016629450276_click,
            updateSelectedRegisterId: act_6466705138206980_setData_2748,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], LetterRegister.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], LetterRegister.prototype, "onSize", null);
LetterRegister = __decorate([
    (0, vue_property_decorator_1.Component)()
], LetterRegister);
exports.default = LetterRegister;
vue_property_decorator_1.Vue.component("LetterRegister", LetterRegister);
