<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="RentalBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="rentals"
    :headerTitle="'Rentals'|gettext"
    :items="$fn.slickDataSource('list_rental', {parameters: getQueryParameters})"
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    export-to-csv
    :exportToCsvFilename="'rentals'|pgettext('filename')"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <ItpBox
      alignItems="center"
      class="flex-grow-1"
      slot="toolbar"
    >
      <DIV
        class="mx-2"
      >
        <ItpFormCheckbox
          name="actives_only"
          :label="'Display active rentals only'|pgettext('RentalBrowser')"
          inline
          v-model="dsParams.actives_only"
        >
        </ItpFormCheckbox>
      </DIV>
      <ItpButton
        :icon="$config.rental.addRentalIcon"
        :text="'Add rental...'|pgettext('RentalBrowser')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/rentals/rentals.html"
        class="ml-auto"
      >
      </PageHelp>
    </ItpBox>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "rentals",
    path: "/rentals",
    component: "RentalBrowser"
  }
)

@Component()
export default class RentalBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_dsParams = new ComponentValueCache(this, "dsParams", "RentalBrowser.dsParams");
  }

  getQueryParameters(...args: any[]) {
    if (this.dsParams.actives_only) {
      return { filter: 'inactive eq false' };
    }
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  dsParams!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'dsParams', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        dsParams: {
          actives_only: true,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "inactive",
          name: this.$fn.pgettext("Rentals", "Inactive"),
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
        }
          ,
        {
          field: "name",
          name: this.$fn.pgettext("Rentals", "Name"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "description",
          name: this.$fn.pgettext("Rentals", "Description"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "valid_from",
          name: this.$fn.pgettext("Rentals", "Valid From"),
          type: "date",
        }
          ,
        {
          field: "valid_to",
          name: this.$fn.pgettext("Rentals", "Valid To"),
          type: "date",
        }
          ,
        {
          field: "site_name",
          name: this.$fn.pgettext("Rentals", "Site"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "property",
            navigateOnIconClick: true,
            params: {
              id: "site_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "location_code",
          name: this.$fn.pgettext("Rentals", "Location Code"),
        }
          ,
        {
          field: "lot_number",
          name: this.$fn.pgettext("Rentals", "Lot Number"),
        }
          ,
        {
          field: "house_number",
          name: this.$fn.pgettext("Rentals", "House Number"),
        }
          ,
        {
          field: "area",
          name: this.$fn.pgettext("Rentals", "Area"),
          type: "text",
        }
          ,
        {
          field: "company_name",
          name: this.$fn.pgettext("Rentals", "Company"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "company",
            navigateOnIconClick: true,
            params: {
              id: "company_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "current_tenant_name",
          name: this.$fn.pgettext("Rentals", "Tenant"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "current_tenant_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "current_contract_number",
          name: this.$fn.pgettext("Rentals", "Contract"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "contract",
            navigateOnIconClick: true,
            params: {
              id: "current_contract_id",
            }
            ,
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Rentals"),
        icon: this.$config.rental.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4318_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4318, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_4320_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_4320, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_showModal_4322_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_4322, alias=undefined
    return {
      name: "CompanyModal",
      props: {
        id: $event.data.data.company_id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_4321_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_4321, alias=undefined
    return $event.data.column.id === 'company_name'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_4324_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_4324, alias=undefined
    return {
      name: "CreateRental",
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4318: actions.CloseModalAction;
    evh_7315092382398562_close_4317: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_4320: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_4319: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_showModal_4322: actions.ShowModalAction;
    evh_2248226175642056_command_4321: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_8320016629450276_showModal_4324: actions.ShowModalAction;
    evh_8320016629450276_click_4323: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4318 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4318_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4317 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4318,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4317],
      }
    );
    const act_2248226175642056_setData_4320 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_4320_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_4319 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_4320,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_4319],
      }
    );
    const act_2248226175642056_showModal_4322 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_4322_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_command_4321 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_4322,
        event: "command",
        when: this.evh_2248226175642056_command_4321_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_4321],
      }
    );
    const act_8320016629450276_showModal_4324 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_4324_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_click_4323 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_4324,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_4323],
      }
    );
    return {
      act_7315092382398562_closeModal_4318,
      evh_7315092382398562_close_4317,
      evh_7315092382398562_close,
      act_2248226175642056_setData_4320,
      evh_2248226175642056_selectedRowsChanged_4319,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_showModal_4322,
      evh_2248226175642056_command_4321,
      evh_2248226175642056_command,
      act_8320016629450276_showModal_4324,
      evh_8320016629450276_click_4323,
      evh_8320016629450276_click,
    }
  }
}

Vue.component("RentalBrowser", RentalBrowser);

</script>