<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    name="status"
    :label="'Status'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="4"
    required
  >
    <ItpFormSelect2
      name="status"
      v-model="worksheet.status"
      valueField="value"
      textField="text"
      :options="statuses"
      required
      optionTemplate="<UcWorksheetStatus :value=&quot;option.value&quot;></UcWorksheetStatus>"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="number"
    :label="'Worksheet Number'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="4"
    required
  >
    <ItpFormInput
      name="number"
      v-model="worksheet.number"
      :readonly="isReadonly"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="description"
    :label="'Description'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="8"
    required
  >
    <ItpFormInput
      autofocus
      name="description"
      v-model="worksheet.description"
      :readonly="isReadonly"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="gl_account_number"
    :label="'GL Account Number'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="4"
  >
    <ItpFormInput
      name="gl_account_number"
      v-model="worksheet.gl_account_number"
      :readonly="isReadonly"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <hr>
  </hr>
  <ItpFormGroup
    name="worksheet_date"
    :label="'Worksheet Date'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="2"
    required
  >
    <ItpFormDatetime
      name="worksheet_date"
      v-model="worksheet.worksheet_date"
      :readonly="isReadonly"
      required
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="period"
    :label="'Period'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="4"
    contentClass="form-row"
  >
    <ItpFormDatetime
      name="start_date"
      v-model="worksheet.start_date"
      :placeholder="'Start'|pgettext('Worksheet/StartDate/placeholder')"
      :readonly="isReadonly"
      class="col-6"
    >
    </ItpFormDatetime>
    <ItpFormDatetime
      name="end_date"
      v-model="worksheet.end_date"
      :placeholder="'End'|pgettext('Worksheet/EndDate/placeholder')"
      :readonly="isReadonly"
      class="col-6"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <hr>
  </hr>
  <ItpFormGroup
    name="rental"
    :label="'Rental'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="5"
  >
    <ItpFormSelect2
      name="rental"
      v-model="worksheet.rental"
      :dataSource="b_8680084583475136_dataSource"
      valueField="id"
      textField="name"
      bind-object
      :readonly="isReadonly"
      use-option-template-for-selected-option
      optionTemplate="<UcSuggestRentalOption :option=&quot;option&quot;></UcSuggestRentalOption>"
      @optionSelected="evh_8680084583475136_optionSelected($event, {})"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="client"
    :label="'Client'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="5"
  >
    <ItpFormSelect2
      name="client"
      v-model="worksheet.client"
      bind-object
      :dataSource="b_3573211234170528_dataSource"
      textField="name"
      optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="contractor"
    :label="'Contractor'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="5"
  >
    <ItpFormSelect2
      name="contractor"
      v-model="worksheet.contractor"
      bind-object
      :dataSource="b_6639903447389385_dataSource"
      textField="name"
      optionTemplate="<UcSuggestClientOption :option=option></UcSuggestClientOption>"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    name="notes"
    :label="'Notes'|pgettext('Worksheet')"
    :labelAlign="labelAlign"
    labelColsMd="4"
    contentColsMd="8"
  >
    <ItpFormTextarea
      name="notes"
      v-model="worksheet.notes"
      :readonly="isReadonly"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcWorksheetEditorFieldset extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  onRentalSelected(...args: any[]) {
    const { option, value } = args[0];
    if (option && option.client_id && option.client_name) {
      this.worksheet.client = {
        id: option.client_id,
        name: option.client_name
      }
    }
  }

  @Prop({
    required: true,
    type: Object,
  })
  worksheet!: object;

  @Watch('worksheet')
  onWorksheet(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("worksheet")
  }

  labelAlign!: any;
  statuses!: any;

  async $$load_statuses() {
    return this.$fn.getEnumValuesFromSchema('WorksheetStatus', { sort: false })
  }

  ux!: any;
  b_8680084583475136_dataSource!: any;
  b_3573211234170528_dataSource!: any;
  b_6639903447389385_dataSource!: any;
  dataMembers = ['labelAlign', 'statuses', 'ux', 'b_8680084583475136_dataSource', 'b_3573211234170528_dataSource', 'b_6639903447389385_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        statuses: null,
        ux: null,
        b_8680084583475136_dataSource: {
          name: "suggest_rental",
        }
        ,
        b_3573211234170528_dataSource: {
          name: "suggest_client",
        }
        ,
        b_6639903447389385_dataSource: {
          name: "suggest_client",
          params: {
            contractors_only: true,
          }
          ,
        }
        ,
      },
    }
  }

  get isReadonly() {
    if (this.loaded) {

      return this.worksheet === 'locked'
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8680084583475136_script_5692_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8680084583475136_script_5692, alias=undefined
    this.onRentalSelected($event.data)
  }

  async act_8680084583475136_script_5692_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8680084583475136_script_5692, alias=undefined
    return {
      value: () => this.act_8680084583475136_script_5692_getActionArgs_value($event),
    }
  }

  async evh_8680084583475136_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8680084583475136_optionSelected.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5690: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5689: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8680084583475136_script_5692: actions.ScriptAction;
    evh_8680084583475136_optionSelected_5691: actions.EventHandlerImpl;
    evh_8680084583475136_optionSelected: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5690 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5689 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5690,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5689],
      }
    );
    const act_8680084583475136_script_5692 = new actions.ScriptAction(
      {
        actionArgs: this.act_8680084583475136_script_5692_getActionArgs,
        events: [],
      }
    );
    const evh_8680084583475136_optionSelected_5691 = new actions.EventHandlerImpl(
      {
        action: act_8680084583475136_script_5692,
        event: "optionSelected",
        displayName: "script",
      }
    );
    const evh_8680084583475136_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_8680084583475136_optionSelected_5691],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5690,
      evh_7315092382398562_reload_5689,
      evh_7315092382398562_reload,
      act_8680084583475136_script_5692,
      evh_8680084583475136_optionSelected_5691,
      evh_8680084583475136_optionSelected,
    }
  }
}

Vue.component("UcWorksheetEditorFieldset", UcWorksheetEditorFieldset);

</script>