<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    class="input-group"
  >
    <ItpFormInput
      type="number"
      name="payment_terms_value"
      v-model="value.value"
    >
    </ItpFormInput>
    <DIV
      class="input-group-append"
    >
      <ItpFormSelect
        name="payment_terms_type"
        v-model="value.type"
        :options="contractPaymentTermTypes"
      >
      </ItpFormSelect>
    </DIV>
  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractPaymentTermsEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  value!: object;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  contractPaymentTermTypes!: any;

  async $$load_contractPaymentTermTypes() {
    return this.$fn.getEnumValuesFromSchema('ContractPaymentTermTypes')
  }

  ux!: any;
  dataMembers = ['contractPaymentTermTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        contractPaymentTermTypes: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4532: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4531: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4532 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4531 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4532,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4531],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4532,
      evh_7315092382398562_reload_4531,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContractPaymentTermsEditor", UcContractPaymentTermsEditor);

</script>