"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MainMenu = class MainMenu extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    get displayTestBadge() {
        if (this.loaded) {
            return _.includes(this.$app.document.location.hostname, 'test');
        }
        return null;
    }
    get displayDevBadge() {
        if (this.loaded) {
            return _.includes(this.$app.document.location.hostname, 'local');
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_5531922658364914_showModal_1276_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: "outgoing",
                },
            };
        });
    }
    evh_5531922658364914_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5531922658364914_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2681810117781728_showModal_1278_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: "incoming",
                },
            };
        });
    }
    evh_2681810117781728_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2681810117781728_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2931973773564833_showModal_1280_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "deposit",
                },
            };
        });
    }
    evh_2931973773564833_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2931973773564833_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2269742420845197_showModal_1282_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "payment",
                },
            };
        });
    }
    evh_2269742420845197_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2269742420845197_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_3539147720590215_showModal_1284_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "banking",
                },
            };
        });
    }
    evh_3539147720590215_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3539147720590215_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7785075180687896_showModal_1286_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "AccountStatementReport",
            };
        });
    }
    evh_7785075180687896_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7785075180687896_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_1886107574314164_showModal_1288_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MCreateFixedAsset",
                props: {
                    assetType: "general",
                },
            };
        });
    }
    evh_1886107574314164_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1886107574314164_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7260361660686930_showModal_1290_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MCreateFixedAsset",
                props: {
                    assetType: "waste_bin",
                },
            };
        });
    }
    evh_7260361660686930_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7260361660686930_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5449511595610967_showModal_1292_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateMeter",
            };
        });
    }
    evh_5449511595610967_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5449511595610967_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8050584411731670_showModal_1294_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MeterDataExchange",
            };
        });
    }
    evh_8050584411731670_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8050584411731670_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7807097906137782_showModal_1296_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ReplaceMeter",
            };
        });
    }
    evh_7807097906137782_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7807097906137782_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2338624680052783_showModal_1298_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateContract",
            };
        });
    }
    evh_2338624680052783_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2338624680052783_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2596033535867385_showModal_1300_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateRental",
            };
        });
    }
    evh_2596033535867385_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2596033535867385_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6794737306373342_showModal_1302_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProperty",
            };
        });
    }
    evh_6794737306373342_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6794737306373342_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2660996057968802_showModal_1304_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateClient",
            };
        });
    }
    evh_2660996057968802_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2660996057968802_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7567745526909371_showModal_1306_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProduct",
            };
        });
    }
    evh_7567745526909371_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7567745526909371_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7228767045058026_showModal_1308_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Worksheet",
                props: {
                    size: "lg",
                },
            };
        });
    }
    evh_7228767045058026_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7228767045058026_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_298118714321807_script_1310_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$app.toggleConfirmPageClose($event);
        });
    }
    act_298118714321807_script_1310_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_298118714321807_script_1310_getActionArgs_value($event),
            };
        });
    }
    evh_298118714321807_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_298118714321807_change.executeFromDOM(this, event, scope);
        });
    }
    act_7106235911078350_logout_1312_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {};
        });
    }
    evh_7106235911078350_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7106235911078350_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5361165673534032_showModal_1314_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "AboutModal",
            };
        });
    }
    evh_5361165673534032_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5361165673534032_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_1274 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_1273 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1274,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1273],
        });
        const act_5531922658364914_showModal_1276 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5531922658364914_showModal_1276_getActionArgs,
            events: [],
        });
        const evh_5531922658364914_clickPrevent_1275 = new core_1.actions.EventHandlerImpl({
            action: act_5531922658364914_showModal_1276,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5531922658364914_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5531922658364914_clickPrevent_1275],
        });
        const act_2681810117781728_showModal_1278 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2681810117781728_showModal_1278_getActionArgs,
            events: [],
        });
        const evh_2681810117781728_clickPrevent_1277 = new core_1.actions.EventHandlerImpl({
            action: act_2681810117781728_showModal_1278,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2681810117781728_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2681810117781728_clickPrevent_1277],
        });
        const act_2931973773564833_showModal_1280 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2931973773564833_showModal_1280_getActionArgs,
            events: [],
        });
        const evh_2931973773564833_clickPrevent_1279 = new core_1.actions.EventHandlerImpl({
            action: act_2931973773564833_showModal_1280,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2931973773564833_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2931973773564833_clickPrevent_1279],
        });
        const act_2269742420845197_showModal_1282 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2269742420845197_showModal_1282_getActionArgs,
            events: [],
        });
        const evh_2269742420845197_clickPrevent_1281 = new core_1.actions.EventHandlerImpl({
            action: act_2269742420845197_showModal_1282,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2269742420845197_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2269742420845197_clickPrevent_1281],
        });
        const act_3539147720590215_showModal_1284 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3539147720590215_showModal_1284_getActionArgs,
            events: [],
        });
        const evh_3539147720590215_clickPrevent_1283 = new core_1.actions.EventHandlerImpl({
            action: act_3539147720590215_showModal_1284,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_3539147720590215_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3539147720590215_clickPrevent_1283],
        });
        const act_7785075180687896_showModal_1286 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7785075180687896_showModal_1286_getActionArgs,
            events: [],
        });
        const evh_7785075180687896_clickPrevent_1285 = new core_1.actions.EventHandlerImpl({
            action: act_7785075180687896_showModal_1286,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7785075180687896_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7785075180687896_clickPrevent_1285],
        });
        const act_1886107574314164_showModal_1288 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1886107574314164_showModal_1288_getActionArgs,
            events: [],
        });
        const evh_1886107574314164_clickPrevent_1287 = new core_1.actions.EventHandlerImpl({
            action: act_1886107574314164_showModal_1288,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_1886107574314164_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1886107574314164_clickPrevent_1287],
        });
        const act_7260361660686930_showModal_1290 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7260361660686930_showModal_1290_getActionArgs,
            events: [],
        });
        const evh_7260361660686930_clickPrevent_1289 = new core_1.actions.EventHandlerImpl({
            action: act_7260361660686930_showModal_1290,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7260361660686930_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7260361660686930_clickPrevent_1289],
        });
        const act_5449511595610967_showModal_1292 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5449511595610967_showModal_1292_getActionArgs,
            events: [],
        });
        const evh_5449511595610967_clickPrevent_1291 = new core_1.actions.EventHandlerImpl({
            action: act_5449511595610967_showModal_1292,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5449511595610967_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5449511595610967_clickPrevent_1291],
        });
        const act_8050584411731670_showModal_1294 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8050584411731670_showModal_1294_getActionArgs,
            events: [],
        });
        const evh_8050584411731670_clickPrevent_1293 = new core_1.actions.EventHandlerImpl({
            action: act_8050584411731670_showModal_1294,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8050584411731670_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8050584411731670_clickPrevent_1293],
        });
        const act_7807097906137782_showModal_1296 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7807097906137782_showModal_1296_getActionArgs,
            events: [],
        });
        const evh_7807097906137782_clickPrevent_1295 = new core_1.actions.EventHandlerImpl({
            action: act_7807097906137782_showModal_1296,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7807097906137782_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7807097906137782_clickPrevent_1295],
        });
        const act_2338624680052783_showModal_1298 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2338624680052783_showModal_1298_getActionArgs,
            events: [],
        });
        const evh_2338624680052783_clickPrevent_1297 = new core_1.actions.EventHandlerImpl({
            action: act_2338624680052783_showModal_1298,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2338624680052783_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2338624680052783_clickPrevent_1297],
        });
        const act_2596033535867385_showModal_1300 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2596033535867385_showModal_1300_getActionArgs,
            events: [],
        });
        const evh_2596033535867385_clickPrevent_1299 = new core_1.actions.EventHandlerImpl({
            action: act_2596033535867385_showModal_1300,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2596033535867385_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2596033535867385_clickPrevent_1299],
        });
        const act_6794737306373342_showModal_1302 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6794737306373342_showModal_1302_getActionArgs,
            events: [],
        });
        const evh_6794737306373342_clickPrevent_1301 = new core_1.actions.EventHandlerImpl({
            action: act_6794737306373342_showModal_1302,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6794737306373342_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6794737306373342_clickPrevent_1301],
        });
        const act_2660996057968802_showModal_1304 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2660996057968802_showModal_1304_getActionArgs,
            events: [],
        });
        const evh_2660996057968802_clickPrevent_1303 = new core_1.actions.EventHandlerImpl({
            action: act_2660996057968802_showModal_1304,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2660996057968802_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2660996057968802_clickPrevent_1303],
        });
        const act_7567745526909371_showModal_1306 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7567745526909371_showModal_1306_getActionArgs,
            events: [],
        });
        const evh_7567745526909371_clickPrevent_1305 = new core_1.actions.EventHandlerImpl({
            action: act_7567745526909371_showModal_1306,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7567745526909371_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7567745526909371_clickPrevent_1305],
        });
        const act_7228767045058026_showModal_1308 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7228767045058026_showModal_1308_getActionArgs,
            events: [],
        });
        const evh_7228767045058026_clickPrevent_1307 = new core_1.actions.EventHandlerImpl({
            action: act_7228767045058026_showModal_1308,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7228767045058026_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7228767045058026_clickPrevent_1307],
        });
        const act_298118714321807_script_1310 = new core_1.actions.ScriptAction({
            actionArgs: this.act_298118714321807_script_1310_getActionArgs,
            events: [],
        });
        const evh_298118714321807_change_1309 = new core_1.actions.EventHandlerImpl({
            action: act_298118714321807_script_1310,
            event: "change",
            displayName: "script",
        });
        const evh_298118714321807_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_298118714321807_change_1309],
        });
        const act_7106235911078350_logout_1312 = new core_1.actions.LogoutAction({
            actionArgs: this.act_7106235911078350_logout_1312_getActionArgs,
            events: [],
        });
        const evh_7106235911078350_clickPrevent_1311 = new core_1.actions.EventHandlerImpl({
            action: act_7106235911078350_logout_1312,
            event: "click.prevent",
            displayName: "logout",
        });
        const evh_7106235911078350_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7106235911078350_clickPrevent_1311],
        });
        const act_5361165673534032_showModal_1314 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5361165673534032_showModal_1314_getActionArgs,
            events: [],
        });
        const evh_5361165673534032_clickPrevent_1313 = new core_1.actions.EventHandlerImpl({
            action: act_5361165673534032_showModal_1314,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5361165673534032_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5361165673534032_clickPrevent_1313],
        });
        return {
            act_7315092382398562_reloadComponentData_1274,
            evh_7315092382398562_reload_1273,
            evh_7315092382398562_reload,
            act_5531922658364914_showModal_1276,
            evh_5531922658364914_clickPrevent_1275,
            evh_5531922658364914_clickPrevent,
            act_2681810117781728_showModal_1278,
            evh_2681810117781728_clickPrevent_1277,
            evh_2681810117781728_clickPrevent,
            act_2931973773564833_showModal_1280,
            evh_2931973773564833_clickPrevent_1279,
            evh_2931973773564833_clickPrevent,
            act_2269742420845197_showModal_1282,
            evh_2269742420845197_clickPrevent_1281,
            evh_2269742420845197_clickPrevent,
            act_3539147720590215_showModal_1284,
            evh_3539147720590215_clickPrevent_1283,
            evh_3539147720590215_clickPrevent,
            act_7785075180687896_showModal_1286,
            evh_7785075180687896_clickPrevent_1285,
            evh_7785075180687896_clickPrevent,
            act_1886107574314164_showModal_1288,
            evh_1886107574314164_clickPrevent_1287,
            evh_1886107574314164_clickPrevent,
            act_7260361660686930_showModal_1290,
            evh_7260361660686930_clickPrevent_1289,
            evh_7260361660686930_clickPrevent,
            act_5449511595610967_showModal_1292,
            evh_5449511595610967_clickPrevent_1291,
            evh_5449511595610967_clickPrevent,
            act_8050584411731670_showModal_1294,
            evh_8050584411731670_clickPrevent_1293,
            evh_8050584411731670_clickPrevent,
            act_7807097906137782_showModal_1296,
            evh_7807097906137782_clickPrevent_1295,
            evh_7807097906137782_clickPrevent,
            act_2338624680052783_showModal_1298,
            evh_2338624680052783_clickPrevent_1297,
            evh_2338624680052783_clickPrevent,
            act_2596033535867385_showModal_1300,
            evh_2596033535867385_clickPrevent_1299,
            evh_2596033535867385_clickPrevent,
            act_6794737306373342_showModal_1302,
            evh_6794737306373342_clickPrevent_1301,
            evh_6794737306373342_clickPrevent,
            act_2660996057968802_showModal_1304,
            evh_2660996057968802_clickPrevent_1303,
            evh_2660996057968802_clickPrevent,
            act_7567745526909371_showModal_1306,
            evh_7567745526909371_clickPrevent_1305,
            evh_7567745526909371_clickPrevent,
            act_7228767045058026_showModal_1308,
            evh_7228767045058026_clickPrevent_1307,
            evh_7228767045058026_clickPrevent,
            act_298118714321807_script_1310,
            evh_298118714321807_change_1309,
            evh_298118714321807_change,
            act_7106235911078350_logout_1312,
            evh_7106235911078350_clickPrevent_1311,
            evh_7106235911078350_clickPrevent,
            act_5361165673534032_showModal_1314,
            evh_5361165673534032_clickPrevent_1313,
            evh_5361165673534032_clickPrevent,
        };
    }
};
MainMenu = __decorate([
    (0, vue_property_decorator_1.Component)()
], MainMenu);
exports.default = MainMenu;
vue_property_decorator_1.Vue.component("MainMenu", MainMenu);
