"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "invoice",
    path: "/invoices/:id",
    component: "Invoice",
    params: [{
            name: "id",
            prop: "oid",
        },
    ]
});
(0, core_1.addRoute)({
    name: "incomingInvoiceCreate",
    path: "/invoices-in/create",
    component: "Invoice",
    componentProps: [{
            name: "newInvoiceKind",
            value: "incoming",
        },
    ]
});
(0, core_1.addRoute)({
    name: "outgoingInvoiceCreate",
    path: "/invoices-out/create",
    component: "Invoice",
    componentProps: [{
            name: "newInvoiceKind",
            value: "outgoing",
        },
    ]
});
let Invoice = class Invoice extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['blankItem', 'item', 'currencies', 'formContext', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_class'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onInvoice_register_entry(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceRegisterEntry");
    }
    onForm_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    }
    onNew_invoice_kind(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("newInvoiceKind");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_blankItem() {
        return __awaiter(this, void 0, void 0, function* () {
            return (this.newInvoiceKind == null && this.invoice_register_entry == null) ?
                null :
                this.$fn.fetch('get_invoice_template', {
                    parameters: {
                        invoice_kind: this.newInvoiceKind,
                        invoice_register_entry: this.invoiceRegisterEntry
                    },
                    asResource: true
                });
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.blankItem ||
                this.$fn.fetch('read_invoice', { parameters: { id: this.oid } })
                    .then(d => {
                    if (d.billing_period == null) {
                        d.billing_period = {
                            start: null,
                            end: null
                        };
                    }
                    return new this.$fn.Resource(d);
                });
        });
    }
    $$load_currencies() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_currency')
                .then(data => _.reduce(data, (m, v) => (m[v.code] = v, m), {}));
        });
    }
    $$load_b_8564662037462133_class() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                "bg-transparent": this.card,
                "shadow-sm": this.card,
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            blankItem: null,
            item: null,
            currencies: null,
            formContext: {},
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "full-screen",
            },
            b_8564662037462133_class: null,
        });
    }
    get isNew() {
        if (this.loaded) {
            return !this.item || !this.item.id;
        }
        return null;
    }
    get invoiceTypeName() {
        if (this.loaded) {
            return this.item.invoice_type === "correction" ? this.$fn.pgettext("InvoiceType", "Correction") :
                (this.item.invoice_type === "storno" ? this.$fn.pgettext("InvoiceType", "Storno") :
                    (this.item.invoice_type === "proforma" ? this.$fn.pgettext("InvoiceType", "Pro Forma") : "?"));
        }
        return null;
    }
    get currency() {
        if (this.loaded) {
            return this.currencies[this.item.currency];
        }
        return null;
    }
    get exchangedCurrency() {
        if (this.loaded) {
            return this.currencies[this.item.exchanged_currency];
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.item.id ? this.item.invoice_number : this.$fn.gettext('Create Invoice'),
                icon: this.$config.invoice.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2356_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_navigate_2360_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.invoice",
                    params: {
                        id: $event.data.id,
                    },
                },
            };
        });
    }
    evh_8564662037462133_created(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_created.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_5534025912102772_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_1468474219929533_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1468474219929533_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_1548630417156826_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2356 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2356_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2355 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2356,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2355],
        });
        const act_2248226175642056_closeModal_2358 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_2357 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_2358,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2357],
        });
        const act_8564662037462133_navigate_2360 = new core_1.actions.NavigateAction({
            actionArgs: this.act_8564662037462133_navigate_2360_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_created_2359 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_navigate_2360,
            event: "created",
            displayName: "navigate",
        });
        const evh_8564662037462133_created = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_created_2359],
        });
        const act_8564662037462133_closeComponent_2362 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_8564662037462133_deleted_2361 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeComponent_2362,
            event: "deleted",
            displayName: "closeComponent",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_2361],
        });
        const act_8564662037462133_reloadComponentData_2364 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_2363 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_2364,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_2363],
        });
        const act_5534025912102772_reloadComponentData_2366 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5534025912102772_reload_2365 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_reloadComponentData_2366,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5534025912102772_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_reload_2365],
        });
        const act_1468474219929533_reloadComponentData_2368 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_1468474219929533_reload_2367 = new core_1.actions.EventHandlerImpl({
            action: act_1468474219929533_reloadComponentData_2368,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_1468474219929533_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1468474219929533_reload_2367],
        });
        const act_1548630417156826_reloadComponentData_2370 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_1548630417156826_reload_2369 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_reloadComponentData_2370,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_1548630417156826_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_reload_2369],
        });
        return {
            act_7315092382398562_closeModal_2356,
            evh_7315092382398562_close_2355,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_2358,
            evh_2248226175642056_close_2357,
            evh_2248226175642056_close,
            act_8564662037462133_navigate_2360,
            evh_8564662037462133_created_2359,
            evh_8564662037462133_created,
            act_8564662037462133_closeComponent_2362,
            evh_8564662037462133_deleted_2361,
            evh_8564662037462133_deleted,
            act_8564662037462133_reloadComponentData_2364,
            evh_8564662037462133_reload_2363,
            evh_8564662037462133_reload,
            act_5534025912102772_reloadComponentData_2366,
            evh_5534025912102772_reload_2365,
            evh_5534025912102772_reload,
            act_1468474219929533_reloadComponentData_2368,
            evh_1468474219929533_reload_2367,
            evh_1468474219929533_reload,
            act_1548630417156826_reloadComponentData_2370,
            evh_1548630417156826_reload_2369,
            evh_1548630417156826_reload,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Invoice.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Invoice.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Invoice.prototype, "invoiceRegisterEntry", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoiceRegisterEntry'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Invoice.prototype, "onInvoice_register_entry", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: function () {
            return this.$fn.randomElementId();
        },
    }),
    __metadata("design:type", String)
], Invoice.prototype, "formId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Invoice.prototype, "onForm_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Invoice.prototype, "newInvoiceKind", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('newInvoiceKind'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Invoice.prototype, "onNew_invoice_kind", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], Invoice.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Invoice.prototype, "onSize", null);
Invoice = __decorate([
    (0, vue_property_decorator_1.Component)()
], Invoice);
exports.default = Invoice;
vue_property_decorator_1.Vue.component("Invoice", Invoice);
