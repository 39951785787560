"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ConfirmSyncInvoice = class ConfirmSyncInvoice extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['labelAlign', 'payload', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onForm_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_payload() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                id: this.invoice.id,
                print: true,
                print_copies: 1,
                target: "remote",
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            labelAlign: "right",
            payload: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("ConfirmSyncInvoice.Title", "Create invoice"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4996_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_4186377094414664_closeModal_5002_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data.response.data.sync_job,
                },
            };
        });
    }
    act_4186377094414664_request_5000_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "sync_invoice",
                data: this.payload,
            };
        });
    }
    evh_4186377094414664_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4186377094414664_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4996 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4996_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4995 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4996,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4995],
        });
        const act_2248226175642056_closeComponent_4998 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_4997 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_4998,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_4997],
        });
        const act_4186377094414664_closeModal_5002 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_4186377094414664_closeModal_5002_getActionArgs,
            events: [],
        });
        const evh_4186377094414664_success_5001 = new core_1.actions.EventHandlerImpl({
            action: act_4186377094414664_closeModal_5002,
            event: "success",
            displayName: "closeModal",
        });
        const act_4186377094414664_request_5000 = new core_1.actions.RequestAction({
            actionArgs: this.act_4186377094414664_request_5000_getActionArgs,
            displayName: "submit",
            events: [evh_4186377094414664_success_5001],
        });
        const evh_4186377094414664_submit_4999 = new core_1.actions.EventHandlerImpl({
            action: act_4186377094414664_request_5000,
            event: "submit",
            displayName: "submit",
        });
        const evh_4186377094414664_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4186377094414664_submit_4999],
        });
        const act_1419464017721962_closeComponent_5004 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_5003 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_5004,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_5003],
        });
        return {
            act_7315092382398562_closeModal_4996,
            evh_7315092382398562_close_4995,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_4998,
            evh_2248226175642056_close_4997,
            evh_2248226175642056_close,
            act_4186377094414664_closeModal_5002,
            evh_4186377094414664_success_5001,
            act_4186377094414664_request_5000,
            evh_4186377094414664_submit_4999,
            evh_4186377094414664_submit,
            act_1419464017721962_closeComponent_5004,
            evh_1419464017721962_clickPreventStop_5003,
            evh_1419464017721962_clickPreventStop,
            submit: act_4186377094414664_request_5000,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], ConfirmSyncInvoice.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ConfirmSyncInvoice.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: function () {
            return this.$fn.randomElementId();
        },
    }),
    __metadata("design:type", String)
], ConfirmSyncInvoice.prototype, "formId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ConfirmSyncInvoice.prototype, "onForm_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ConfirmSyncInvoice.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ConfirmSyncInvoice.prototype, "onSize", null);
ConfirmSyncInvoice = __decorate([
    (0, vue_property_decorator_1.Component)()
], ConfirmSyncInvoice);
exports.default = ConfirmSyncInvoice;
vue_property_decorator_1.Vue.component("ConfirmSyncInvoice", ConfirmSyncInvoice);
