"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let BtnDeleteResource = class BtnDeleteResource extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux'];
    }
    beforeCreate() {
    }
    onResource(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("resource");
    }
    onDisabled(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("disabled");
    }
    onNo_icon(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("noIcon");
    }
    onNo_text(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("noText");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    onTooltip(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("tooltip");
    }
    onDialog_header(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("dialogHeader");
    }
    onDropdown_item(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("dropdownItem");
    }
    onVariant(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("variant");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                text: this.$fn.pgettext("BtnDeleteResource", "Delete"),
            },
            ux: null,
        });
    }
    act_7315092382398562_emit_1786_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "success",
            };
        });
    }
    act_7315092382398562_resource_1784_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "delete",
                target: this.resource,
                data: { etag: this.resource.etag },
            };
        });
    }
    act_7315092382398562_resource_1784_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: this.dialogHeader,
                message: this.$fn.gettext("Please confirm delete."),
                btnOk: this.$fn.gettext("Delete"),
                severity: "warning",
            };
        });
    }
    evh_7315092382398562_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_emit_1786 = new core_1.actions.EmitAction({
            actionArgs: this.act_7315092382398562_emit_1786_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_success_1785 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_emit_1786,
            event: "success",
            displayName: "emit",
        });
        const act_7315092382398562_resource_1784 = new core_1.actions.ResourceAction({
            actionArgs: this.act_7315092382398562_resource_1784_getActionArgs,
            confirm: this.act_7315092382398562_resource_1784_getConfirm,
            displayName: "delete",
            events: [evh_7315092382398562_success_1785],
        });
        const evh_7315092382398562_clickPreventStop_1783 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_resource_1784,
            event: "click.prevent.stop",
            displayName: "delete",
        });
        const evh_7315092382398562_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_clickPreventStop_1783],
        });
        return {
            act_7315092382398562_emit_1786,
            evh_7315092382398562_success_1785,
            act_7315092382398562_resource_1784,
            evh_7315092382398562_clickPreventStop_1783,
            evh_7315092382398562_clickPreventStop,
            delete: act_7315092382398562_resource_1784,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], BtnDeleteResource.prototype, "resource", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('resource'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onResource", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], BtnDeleteResource.prototype, "disabled", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('disabled'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onDisabled", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], BtnDeleteResource.prototype, "noIcon", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('noIcon'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onNo_icon", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], BtnDeleteResource.prototype, "noText", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('noText'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onNo_text", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "sm",
    }),
    __metadata("design:type", String)
], BtnDeleteResource.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onSize", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], BtnDeleteResource.prototype, "tooltip", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('tooltip'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onTooltip", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], BtnDeleteResource.prototype, "dialogHeader", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('dialogHeader'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onDialog_header", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], BtnDeleteResource.prototype, "dropdownItem", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('dropdownItem'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onDropdown_item", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "light",
    }),
    __metadata("design:type", String)
], BtnDeleteResource.prototype, "variant", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('variant'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnDeleteResource.prototype, "onVariant", null);
BtnDeleteResource = __decorate([
    (0, vue_property_decorator_1.Component)()
], BtnDeleteResource);
exports.default = BtnDeleteResource;
vue_property_decorator_1.Vue.component("BtnDeleteResource", BtnDeleteResource);
