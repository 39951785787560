"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractGeneralInfo = class UcContractGeneralInfo extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux', 'b_6379803495938895_to', 'b_8906220998092919_to'];
    }
    beforeCreate() {
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_b_6379803495938895_to() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "app.company",
                params: {
                    id: this.contract.company.id,
                },
            };
        });
    }
    $$load_b_8906220998092919_to() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "app.client",
                params: {
                    id: this.contract.counterparty.id,
                },
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
            b_6379803495938895_to: null,
            b_8906220998092919_to: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_emit_6208_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_8320016629450276_close_6207_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_8320016629450276_showModal_6206_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractEditor",
                props: {
                    target: "general",
                    contract: _.cloneDeep(this.contract),
                },
            };
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7349226261686150_emit_6210_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7349226261686150_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7349226261686150_reload.executeFromDOM(this, event, scope);
        });
    }
    act_5937240531526321_emit_6214_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_5937240531526321_close_6213_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_5937240531526321_showModal_6212_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractEditor",
                props: {
                    target: "company",
                    contract: _.cloneDeep(this.contract),
                },
            };
        });
    }
    evh_5937240531526321_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5937240531526321_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7527485200259320_emit_6218_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7527485200259320_close_6217_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_7527485200259320_showModal_6216_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractEditor",
                props: {
                    target: "counterparty",
                    contract: _.cloneDeep(this.contract),
                },
            };
        });
    }
    evh_7527485200259320_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7527485200259320_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7127218473441393_emit_6222_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7127218473441393_close_6221_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_7127218473441393_showModal_6220_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractEditor",
                props: {
                    target: "rental",
                    contract: this.contract.rental
                        ? _.cloneDeep(this.contract)
                        : _.extend(_.cloneDeep(this.contract), {
                            rental: {
                                id: null,
                                name: null,
                                number: null,
                                description: null,
                                house_number: null,
                                inactive: false,
                                location_code: null,
                                lot_number: null,
                            }
                        }),
                },
            };
        });
    }
    evh_7127218473441393_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7127218473441393_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_4198689235226718_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4198689235226718_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6204 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6203 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6204,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6203],
        });
        const act_8320016629450276_emit_6208 = new core_1.actions.EmitAction({
            actionArgs: this.act_8320016629450276_emit_6208_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_close_6207 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_emit_6208,
            event: "close",
            when: this.evh_8320016629450276_close_6207_getWhen,
            displayName: "emit",
        });
        const act_8320016629450276_showModal_6206 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_6206_getActionArgs,
            events: [evh_8320016629450276_close_6207],
        });
        const evh_8320016629450276_clickPreventStop_6205 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_6206,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_6205],
        });
        const act_7349226261686150_emit_6210 = new core_1.actions.EmitAction({
            actionArgs: this.act_7349226261686150_emit_6210_getActionArgs,
            events: [],
        });
        const evh_7349226261686150_reload_6209 = new core_1.actions.EventHandlerImpl({
            action: act_7349226261686150_emit_6210,
            event: "reload",
            displayName: "emit",
        });
        const evh_7349226261686150_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7349226261686150_reload_6209],
        });
        const act_5937240531526321_emit_6214 = new core_1.actions.EmitAction({
            actionArgs: this.act_5937240531526321_emit_6214_getActionArgs,
            events: [],
        });
        const evh_5937240531526321_close_6213 = new core_1.actions.EventHandlerImpl({
            action: act_5937240531526321_emit_6214,
            event: "close",
            when: this.evh_5937240531526321_close_6213_getWhen,
            displayName: "emit",
        });
        const act_5937240531526321_showModal_6212 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5937240531526321_showModal_6212_getActionArgs,
            events: [evh_5937240531526321_close_6213],
        });
        const evh_5937240531526321_clickPreventStop_6211 = new core_1.actions.EventHandlerImpl({
            action: act_5937240531526321_showModal_6212,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_5937240531526321_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5937240531526321_clickPreventStop_6211],
        });
        const act_7527485200259320_emit_6218 = new core_1.actions.EmitAction({
            actionArgs: this.act_7527485200259320_emit_6218_getActionArgs,
            events: [],
        });
        const evh_7527485200259320_close_6217 = new core_1.actions.EventHandlerImpl({
            action: act_7527485200259320_emit_6218,
            event: "close",
            when: this.evh_7527485200259320_close_6217_getWhen,
            displayName: "emit",
        });
        const act_7527485200259320_showModal_6216 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7527485200259320_showModal_6216_getActionArgs,
            events: [evh_7527485200259320_close_6217],
        });
        const evh_7527485200259320_clickPreventStop_6215 = new core_1.actions.EventHandlerImpl({
            action: act_7527485200259320_showModal_6216,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_7527485200259320_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7527485200259320_clickPreventStop_6215],
        });
        const act_7127218473441393_emit_6222 = new core_1.actions.EmitAction({
            actionArgs: this.act_7127218473441393_emit_6222_getActionArgs,
            events: [],
        });
        const evh_7127218473441393_close_6221 = new core_1.actions.EventHandlerImpl({
            action: act_7127218473441393_emit_6222,
            event: "close",
            when: this.evh_7127218473441393_close_6221_getWhen,
            displayName: "emit",
        });
        const act_7127218473441393_showModal_6220 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7127218473441393_showModal_6220_getActionArgs,
            events: [evh_7127218473441393_close_6221],
        });
        const evh_7127218473441393_clickPreventStop_6219 = new core_1.actions.EventHandlerImpl({
            action: act_7127218473441393_showModal_6220,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_7127218473441393_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7127218473441393_clickPreventStop_6219],
        });
        const act_4198689235226718_reloadComponentData_6224 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_4198689235226718_reload_6223 = new core_1.actions.EventHandlerImpl({
            action: act_4198689235226718_reloadComponentData_6224,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_4198689235226718_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4198689235226718_reload_6223],
        });
        return {
            act_7315092382398562_reloadComponentData_6204,
            evh_7315092382398562_reload_6203,
            evh_7315092382398562_reload,
            act_8320016629450276_emit_6208,
            evh_8320016629450276_close_6207,
            act_8320016629450276_showModal_6206,
            evh_8320016629450276_clickPreventStop_6205,
            evh_8320016629450276_clickPreventStop,
            act_7349226261686150_emit_6210,
            evh_7349226261686150_reload_6209,
            evh_7349226261686150_reload,
            act_5937240531526321_emit_6214,
            evh_5937240531526321_close_6213,
            act_5937240531526321_showModal_6212,
            evh_5937240531526321_clickPreventStop_6211,
            evh_5937240531526321_clickPreventStop,
            act_7527485200259320_emit_6218,
            evh_7527485200259320_close_6217,
            act_7527485200259320_showModal_6216,
            evh_7527485200259320_clickPreventStop_6215,
            evh_7527485200259320_clickPreventStop,
            act_7127218473441393_emit_6222,
            evh_7127218473441393_close_6221,
            act_7127218473441393_showModal_6220,
            evh_7127218473441393_clickPreventStop_6219,
            evh_7127218473441393_clickPreventStop,
            act_4198689235226718_reloadComponentData_6224,
            evh_4198689235226718_reload_6223,
            evh_4198689235226718_reload,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractGeneralInfo.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractGeneralInfo.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcContractGeneralInfo.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractGeneralInfo.prototype, "onReadonly", null);
UcContractGeneralInfo = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractGeneralInfo);
exports.default = UcContractGeneralInfo;
vue_property_decorator_1.Vue.component("UcContractGeneralInfo", UcContractGeneralInfo);
