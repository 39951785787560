<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="StatData"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="overflow-auto h-100 p-3"
  >
    <ItpDataTable
      tableId="Settings.StatData"
      editable
      :isRowEditable="row => !row.locked"
      :value="_.orderBy(items, ['year', 'month', 'created_at'], ['desc', 'desc', 'desc'])"
      :rowTemplate="schemaDefaults('CreateStatDataCommand', {
      data_type: 'ksh_domestic_industrial_sales_price_index',
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
  })"
      :columns="b_8564662037462133_columns"
      class="h-100"
      v-if="loaded"
      @save="evh_8564662037462133_save($event, {})"
      @delete="evh_8564662037462133_delete($event, {})"
    >
    </ItpDataTable>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "settings.statData",
    path: "external-stat-data",
    component: "StatData"
  }
)

@Component()
export default class StatData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  statDataTypes!: any;
  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_stat_data').then(p => p.data)
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_8564662037462133_columns!: any;

  async $$load_b_8564662037462133_columns() {
    return [{
      name: "data_type",
      type: "select",
      header: this.$fn.gettext("Data type"),
      width: "280px",
      required: true,
      options: this.statDataTypes,
    }
      ,
    {
      name: "year",
      type: "number",
      header: this.$fn.gettext("Year"),
      width: "120px",
      required: true,
    }
      ,
    {
      name: "month",
      type: "number",
      header: this.$fn.gettext("Month"),
      width: "120px",
      required: true,
    }
      ,
    {
      name: "value",
      type: "number",
      header: this.$fn.gettext("Value"),
      width: "120px",
      required: true,
    }
      ,
    {
      name: "unit",
      header: this.$fn.gettext("Unit"),
      width: "120px",
      required: true,
    }
      ,
    ]
  }

  dataMembers = ['statDataTypes', 'items', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        statDataTypes: [{
          id: "ksh_domestic_industrial_sales_price_index",
          text: "P_(\"StatDataType\", \"ksh_domestic_industrial_sales_price_index\", \"Sentence\")",
        }
          ,
        ]
        ,
        items: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_8564662037462133_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_closeModal_4146_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4146, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_script_4148_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8564662037462133_script_4148, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('stat_data').upsert({
        ...$event.data.row,
        start: $event.data.row.lastvalue
      })
    )
  }

  async act_8564662037462133_script_4148_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8564662037462133_script_4148, alias=undefined
    return {
      value: () => this.act_8564662037462133_script_4148_getActionArgs_value($event),
    }
  }

  async evh_8564662037462133_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_save.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_script_4150_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8564662037462133_script_4150, alias=undefined
    $event.data.waitFor(this.$fn.crud('stat_data').delete($event.data.row))
  }

  async act_8564662037462133_script_4150_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8564662037462133_script_4150, alias=undefined
    return {
      value: () => this.act_8564662037462133_script_4150_getActionArgs_value($event),
    }
  }

  async evh_8564662037462133_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4146: actions.CloseModalAction;
    evh_7315092382398562_close_4145: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_8564662037462133_script_4148: actions.ScriptAction;
    evh_8564662037462133_save_4147: actions.EventHandlerImpl;
    evh_8564662037462133_save: actions.EventHandlerGroup;
    act_8564662037462133_script_4150: actions.ScriptAction;
    evh_8564662037462133_delete_4149: actions.EventHandlerImpl;
    evh_8564662037462133_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4146 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4146_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4145 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4146,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4145],
      }
    );
    const act_8564662037462133_script_4148 = new actions.ScriptAction(
      {
        actionArgs: this.act_8564662037462133_script_4148_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_save_4147 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_script_4148,
        event: "save",
        displayName: "script",
      }
    );
    const evh_8564662037462133_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_save_4147],
      }
    );
    const act_8564662037462133_script_4150 = new actions.ScriptAction(
      {
        actionArgs: this.act_8564662037462133_script_4150_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_delete_4149 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_script_4150,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_8564662037462133_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_delete_4149],
      }
    );
    return {
      act_7315092382398562_closeModal_4146,
      evh_7315092382398562_close_4145,
      evh_7315092382398562_close,
      act_8564662037462133_script_4148,
      evh_8564662037462133_save_4147,
      evh_8564662037462133_save,
      act_8564662037462133_script_4150,
      evh_8564662037462133_delete_4149,
      evh_8564662037462133_delete,
    }
  }
}

Vue.component("StatData", StatData);

</script>