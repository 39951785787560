var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "ImportInvoices",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpAlert",
                { attrs: { variant: "info", show: "" } },
                [
                  _c("ItpIcon", { attrs: { fa: "info-circle" } }),
                  _vm._v(" "),
                  _c("ItpText", { attrs: { wrap: "" } }, [
                    _vm._v(
                      "\n        A funkció külső állományokból importál számlákat.\n\n      "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "DIV",
                { staticClass: "container" },
                [
                  _vm.loaded
                    ? _c(
                        "ItpForm",
                        {
                          attrs: { name: _vm.formName, id: _vm.formId },
                          on: {
                            submit: function($event) {
                              return _vm.evh_648747096704084_submit($event, {})
                            }
                          }
                        },
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "format",
                                label: _vm._f("pgettext")(
                                  "File format",
                                  "ImportInvoices"
                                ),
                                labelFor: "format",
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormSelect", {
                                attrs: {
                                  name: "format",
                                  required: "",
                                  options: _vm.formats
                                },
                                model: {
                                  value: _vm.format,
                                  callback: function($$v) {
                                    _vm.format = $$v
                                  },
                                  expression: "format"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.format
                            ? _c(
                                "ItpFormGroup",
                                {
                                  attrs: {
                                    name: "file",
                                    label: _vm._f("pgettext")(
                                      "Export file",
                                      "ImportInvoices"
                                    ),
                                    labelFor: "file",
                                    description: _vm._f("pgettext")(
                                      "The file can be obtained from the respective providers's application.",
                                      "ImportInvoices"
                                    ),
                                    required: ""
                                  }
                                },
                                [
                                  _c("ItpFormFile", {
                                    attrs: {
                                      name: "file",
                                      accept: _vm.format.accept,
                                      required: "",
                                      validation:
                                        _vm.b_4823665167724729_validation
                                    },
                                    model: {
                                      value: _vm.file,
                                      callback: function($$v) {
                                        _vm.file = $$v
                                      },
                                      expression: "file"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.result && _vm.result.message
                    ? _c("ItpAlert", { attrs: { show: "" } }, [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.result.message) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.result && _vm.result.details
                    ? _c("ItpFormTextarea", {
                        attrs: { name: "details", monospace: "", readonly: "" },
                        model: {
                          value: _vm.result.details,
                          callback: function($$v) {
                            _vm.$set(_vm.result, "details", $$v)
                          },
                          expression: "result.details"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.formId,
                  text: _vm._f("pgettext")("Submit file", "ImportInvoices"),
                  type: "submit",
                  variant: "primary",
                  icon: "fa-file-import",
                  spinning: _vm.actions.submit.isRunning,
                  disabled:
                    !_vm.forms[_vm.formName] ||
                    !_vm.forms[_vm.formName].submittable
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }