<template>
<ItpPage
  headerClass="bg-light"
  no-footer
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="AboutModal"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <h4>
      Integro v{{ $app.PRODUCT_VERSION }}

    </h4>
    <DIV
      class="mt-3"
    >
      Copyright © 2006-2021 EuroSand Computer Kft.
    </DIV>
    <DIV>
      License type: Commercial
    </DIV>
    <table
      class="table table-sm table-striped table-borderless mt-3"
    >
      <thead>
  <tr>
    <th>{{ 'Component'|gettext }}</th>
    <th>{{ 'Version'|gettext }}</th>
  </tr>
</thead>
<tbody>
  <tr v-for="v in versions" :key="v.name">
    <td>{{ v.name }}</td>
    <td>{{ v.version }}</td>
  </tr>
</tbody>

    </table>
  </UcBody>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class AboutModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  apiVersion!: any;

  async $$load_apiVersion() {
    return this.$fn.fetch('version')
  }

  versions!: any;

  async $$load_versions() {
    return [
      { name: 'Frontend', version: this.$app.PACKAGE_VERSION },
      { name: 'API', version: this.apiVersion },
      { name: 'Docs', version: 'installed' },
      { name: 'Auth / Keycloak', version: 'installed' },
    ]
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['apiVersion', 'versions', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        apiVersion: null,
        versions: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          headerClass: "bg-light",
          noFooter: true,
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("About Integro"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1776_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1776, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1776: actions.CloseModalAction;
    evh_7315092382398562_close_1775: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_1778: actions.CloseComponentAction;
    evh_2248226175642056_close_1777: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1776 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1776_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1775 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1776,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1775],
      }
    );
    const act_2248226175642056_closeComponent_1778 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1777 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_1778,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1777],
      }
    );
    return {
      act_7315092382398562_closeModal_1776,
      evh_7315092382398562_close_1775,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_1778,
      evh_2248226175642056_close_1777,
      evh_2248226175642056_close,
    }
  }
}

Vue.component("AboutModal", AboutModal);

</script>