<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceRegisterEntry"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.invoiceRegister.color"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <InvoiceRegisterEntryHeader
      :register="resource.register || registerObj"
      :entry="resource"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </InvoiceRegisterEntryHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      :name="formName"
      :readonly="resource.isReadOnly"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetInvoiceRegisterEntry
        :item="editor"
        :form="forms[formName]"
        @reload="evh_5534025912102772_reload($event, {})"
        @close="evh_5534025912102772_close($event, {})"
        @changed="evh_5534025912102772_changed($event, {})"
      >
      </FieldsetInvoiceRegisterEntry>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.invoiceRegister.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Create another entry'|pgettext('InvoiceRegisterEntry/Command')"
      :disabled="!forms[formName] || !forms[formName].submittable"
      variant="light"
      icon="mdi:forward"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="!resource.isNew"
      @click="evh_1548630417156826_click($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceRegisterEntry extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Object,
  })
  register!: object;

  @Watch('register')
  onRegister(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("register")
  }

  @Prop({
    type: String,
  })
  companyId!: string;

  @Watch('companyId')
  onCompany_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("companyId")
  }

  @Prop({
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  container!: any;

  async $$load_container() {
    return {
      self: this.$fn.urlFor('create_invoice_register_entry'),
      object: 'list',
      data: []
    }
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  createTitle!: any;
  defaultTitle!: any;
  registerObj!: any;

  async $$load_registerObj() {
    return this.register || this.$fn.fetch('read_register', { parameters: { id: 'current', register_type: 'incoming_invoice' } })
  }

  resource!: any;

  async $$load_resource() {
    return this.oid
      ? this.$fn.fetch('read_invoice_register_entry', { parameters: { id: this.oid }, asResource: true })
      : this.$fn.schemaDefaults('CreateInvoiceRegisterEntryCommand',
        this.invoice ?
          {
            id: null,
            register_name: this.registerObj.id,
            invoice_kind: this.invoice.invoice_kind,
            invoice_type: this.invoice.invoice_type,
            received_date: this.$fn.today(),
            categories: this.invoice.tags,
            description: this.invoice.description || this.invoice.notes,
            company_id: this.invoice.company.id,
            company: this.invoice.company,
            client_id: this.invoice.client.id,
            client: this.invoice.client,
            invoice_id: this.invoice.id,
            reference_invoice_number: this.invoice.invoice_number,
            invoice_number: this.invoice.invoice_number,
            invoice_date: this.invoice.invoice_date,
            due_date: this.invoice.due_date,
            fulfillment_date: this.invoice.fulfillment_date,
            payment_method: this.invoice.payment_method,
            notes: this.invoice.notes,
            amount: {
              amount: this.invoice.totals.total,
              currency: this.invoice.currency
            }
          } :
          {
            id: null,
            register_name: this.registerObj.id,
            invoice_kind: 'incoming',
            invoice_type: 'normal',
            company_id: this.companyId,
            received_date: this.$fn.today(),
            payment_method: 'bank_transfer',
            amount: {
              amount: 0,
              currency: 'HUF'
            }
          }
      )
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['container', 'formId', 'formName', 'createTitle', 'defaultTitle', 'registerObj', 'resource', 'editor', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        container: null,
        formId: null,
        formName: "invoiceRegisterEntry",
        createTitle: this.$fn.pgettext("InvoiceRegisterEntry", "New Registry Entry"),
        defaultTitle: this.$fn.pgettext("InvoiceRegisterEntry", "Edit Invoice Registry Information"),
        registerObj: null,
        resource: null,
        editor: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get isDeletable() {
    if (this.loaded) {

      return this.oid != null
    }
    return null;
  }

  get creatingTitle() {
    if (this.loaded) {

      return this.$fn.pgettext("InvoiceRegisterEntry", "Create new entry")
    }
    return null;
  }

  get editingTitle() {
    if (this.loaded) {

      return '#' + this.resource.entry_number
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.resource.isNew ? this.creatingTitle : this.editingTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2618_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2618, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_2626_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_2626, alias=undefined
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_emit_2630_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_2630, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async act_1040167445267876_reloadSlickgrid_2632_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_2632, alias=undefined
    return {
      grid: "invoiceRegister",
    }
  }

  async act_1040167445267876_rest_2628_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_2628, alias=save
    return {
      method: "save",
      container: this.container,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_emit_2638_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5534025912102772_emit_2638, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async evh_5534025912102772_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_changed.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_script_2640_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1548630417156826_script_2640, alias=undefined
    (
      this.setData('resource', this.resource.clone({
        id: null,
        rev: null,
        register_name: this.resource.register ?
          this.resource.register.id :
          this.registerObj.id,
        invoice_number: null,
        entry_number: null,
        received_date: this.$fn.today(),
        links: null,
        etag: null,
        amount: { amount: 0, currency: this.resource.amount.currency }
      })),
      this.setData('editor', this.resource.editor())
    )
  }

  async act_1548630417156826_script_2640_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1548630417156826_script_2640, alias=undefined
    return {
      value: () => this.act_1548630417156826_script_2640_getActionArgs_value($event),
    }
  }

  async evh_1548630417156826_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2618: actions.CloseModalAction;
    evh_7315092382398562_close_2617: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2620: actions.CloseComponentAction;
    evh_2248226175642056_close_2619: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_2622: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_2621: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeComponent_2624: actions.CloseComponentAction;
    evh_8564662037462133_deleted_2623: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_2626: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_2625: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_emit_2630: actions.EmitAction;
    evh_1040167445267876_saved_2629: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_2632: actions.ReloadSlickgridAction;
    evh_1040167445267876_saved_2631: actions.EventHandlerImpl;
    act_1040167445267876_rest_2628: actions.RestAction;
    evh_1040167445267876_submit_2627: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_5534025912102772_reloadComponentData_2634: actions.ReloadComponentDataAction;
    evh_5534025912102772_reload_2633: actions.EventHandlerImpl;
    evh_5534025912102772_reload: actions.EventHandlerGroup;
    act_5534025912102772_closeComponent_2636: actions.CloseComponentAction;
    evh_5534025912102772_close_2635: actions.EventHandlerImpl;
    evh_5534025912102772_close: actions.EventHandlerGroup;
    act_5534025912102772_emit_2638: actions.EmitAction;
    evh_5534025912102772_changed_2637: actions.EventHandlerImpl;
    evh_5534025912102772_changed: actions.EventHandlerGroup;
    act_1548630417156826_script_2640: actions.ScriptAction;
    evh_1548630417156826_click_2639: actions.EventHandlerImpl;
    evh_1548630417156826_click: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_2642: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_2641: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2618 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2618_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2617 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2618,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2617],
      }
    );
    const act_2248226175642056_closeComponent_2620 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2619 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2620,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2619],
      }
    );
    const act_8564662037462133_reloadComponentData_2622 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_2621 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_2622,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_2621],
      }
    );
    const act_8564662037462133_closeComponent_2624 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_2623 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeComponent_2624,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const act_8564662037462133_reloadSlickgrid_2626 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_2626_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_2625 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_2626,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_2623, evh_8564662037462133_deleted_2625],
      }
    );
    const act_1040167445267876_emit_2630 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_2630_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_2629 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_2630,
        event: "saved",
        displayName: "emit",
      }
    );
    const act_1040167445267876_reloadSlickgrid_2632 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_2632_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_2631 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_2632,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_rest_2628 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_2628_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_saved_2629, evh_1040167445267876_saved_2631],
      }
    );
    const evh_1040167445267876_submit_2627 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_2628,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2627],
      }
    );
    const act_5534025912102772_reloadComponentData_2634 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_reload_2633 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_reloadComponentData_2634,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5534025912102772_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_reload_2633],
      }
    );
    const act_5534025912102772_closeComponent_2636 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_close_2635 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_closeComponent_2636,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_5534025912102772_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_close_2635],
      }
    );
    const act_5534025912102772_emit_2638 = new actions.EmitAction(
      {
        actionArgs: this.act_5534025912102772_emit_2638_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_changed_2637 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_emit_2638,
        event: "changed",
        displayName: "emit",
      }
    );
    const evh_5534025912102772_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_changed_2637],
      }
    );
    const act_1548630417156826_script_2640 = new actions.ScriptAction(
      {
        actionArgs: this.act_1548630417156826_script_2640_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_click_2639 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_script_2640,
        event: "click",
        displayName: "script",
      }
    );
    const evh_1548630417156826_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_click_2639],
      }
    );
    const act_1419464017721962_closeComponent_2642 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2641 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_2642,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2641],
      }
    );
    return {
      act_7315092382398562_closeModal_2618,
      evh_7315092382398562_close_2617,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2620,
      evh_2248226175642056_close_2619,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_2622,
      evh_8564662037462133_reload_2621,
      evh_8564662037462133_reload,
      act_8564662037462133_closeComponent_2624,
      evh_8564662037462133_deleted_2623,
      act_8564662037462133_reloadSlickgrid_2626,
      evh_8564662037462133_deleted_2625,
      evh_8564662037462133_deleted,
      act_1040167445267876_emit_2630,
      evh_1040167445267876_saved_2629,
      act_1040167445267876_reloadSlickgrid_2632,
      evh_1040167445267876_saved_2631,
      act_1040167445267876_rest_2628,
      evh_1040167445267876_submit_2627,
      evh_1040167445267876_submit,
      act_5534025912102772_reloadComponentData_2634,
      evh_5534025912102772_reload_2633,
      evh_5534025912102772_reload,
      act_5534025912102772_closeComponent_2636,
      evh_5534025912102772_close_2635,
      evh_5534025912102772_close,
      act_5534025912102772_emit_2638,
      evh_5534025912102772_changed_2637,
      evh_5534025912102772_changed,
      act_1548630417156826_script_2640,
      evh_1548630417156826_click_2639,
      evh_1548630417156826_click,
      act_1419464017721962_closeComponent_2642,
      evh_1419464017721962_clickPreventStop_2641,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_rest_2628,
    }
  }
}

Vue.component("InvoiceRegisterEntry", InvoiceRegisterEntry);

</script>