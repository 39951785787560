"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoiceLineGroup = class InvoiceLineGroup extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'formId', 'formName', 'expenseTypes', 'titles', 'ux', 'b_7315092382398562_modalBindings', 'b_7307096920626688_dataSource', 'b_3119842801924129_dataSource', 'b_4994395583501828_dataSource', 'b_2260153975480357_dataSource'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onGroup_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("groupId");
    }
    onIs_default_group(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("isDefaultGroup");
    }
    onLabel_align(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.groupId
                ? this.$fn.fetch('read_invoice_line_group', { asResource: true, parameters: { id: this.groupId } })
                : this.$fn.schemaDefaults('CreateInvoiceLineGroup', {
                    invoice_id: this.invoice.id,
                    is_default_group: this.isDefaultGroup
                });
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_expenseTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } }).then(d => _.orderBy(d, ['name']));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            formId: null,
            formName: "invoiceLineGroup",
            expenseTypes: null,
            titles: {
                incoming: this.$fn.pgettext("Invoice", "Create Incoming Invoice"),
                outgoing: this.$fn.pgettext("Invoice", "Create Outgoing Invoice"),
            },
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
            b_7307096920626688_dataSource: {
                name: "suggest_rental",
            },
            b_3119842801924129_dataSource: {
                name: "suggest_property",
            },
            b_4994395583501828_dataSource: {
                name: "suggest_worksheet",
            },
            b_2260153975480357_dataSource: {
                name: "suggest_client",
                parameters: {
                    contractors_only: true,
                },
            },
        });
    }
    get incomingInvoice() {
        if (this.loaded) {
            return this.invoiceKind === 'incoming';
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.item.isNew && this.$fn.pgettext("InvoiceLineGroup", "New line group"),
                    !this.item.isNew && this.$fn.pgettext("InvoiceLineGroup", "Edit line group"),
                ],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5034_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_5040_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_5038_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "invoice_line_group",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeModal_5042_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    value: {
                        deleted: true,
                    },
                },
            };
        });
    }
    evh_1548630417156826_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_success.executeFromDOM(this, event, scope);
        });
    }
    act_2186679009640457_closeModal_5044_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5034 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5034_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5033 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5034,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5033],
        });
        const act_2248226175642056_closeModal_5036 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_5035 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_5036,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_5035],
        });
        const act_1040167445267876_closeModal_5040 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_5040_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_5039 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_5040,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_crud_5038 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_5038_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_5039],
        });
        const evh_1040167445267876_submit_5037 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_5038,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_5037],
        });
        const act_1548630417156826_closeModal_5042 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1548630417156826_closeModal_5042_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_5041 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeModal_5042,
            event: "success",
            displayName: "closeModal",
        });
        const evh_1548630417156826_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_success_5041],
        });
        const act_2186679009640457_closeModal_5044 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2186679009640457_closeModal_5044_getActionArgs,
            events: [],
        });
        const evh_2186679009640457_clickPreventStop_5043 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeModal_5044,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_5043],
        });
        return {
            act_7315092382398562_closeModal_5034,
            evh_7315092382398562_close_5033,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_5036,
            evh_2248226175642056_close_5035,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_5040,
            evh_1040167445267876_success_5039,
            act_1040167445267876_crud_5038,
            evh_1040167445267876_submit_5037,
            evh_1040167445267876_submit,
            act_1548630417156826_closeModal_5042,
            evh_1548630417156826_success_5041,
            evh_1548630417156826_success,
            act_2186679009640457_closeModal_5044,
            evh_2186679009640457_clickPreventStop_5043,
            evh_2186679009640457_clickPreventStop,
            submit: act_1040167445267876_crud_5038,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], InvoiceLineGroup.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineGroup.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceLineGroup.prototype, "groupId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('groupId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineGroup.prototype, "onGroup_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], InvoiceLineGroup.prototype, "isDefaultGroup", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('isDefaultGroup'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineGroup.prototype, "onIs_default_group", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "right",
    }),
    __metadata("design:type", String)
], InvoiceLineGroup.prototype, "labelAlign", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('labelAlign'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineGroup.prototype, "onLabel_align", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], InvoiceLineGroup.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLineGroup.prototype, "onSize", null);
InvoiceLineGroup = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceLineGroup);
exports.default = InvoiceLineGroup;
vue_property_decorator_1.Vue.component("InvoiceLineGroup", InvoiceLineGroup);
