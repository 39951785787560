<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ProductBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="products"
    :headerTitle="'Products'|pgettext('ProductsView')"
    :items="$fn.slickDataSource('list_product')"
    checkbox-row-selection
    export-to-csv
    :exportToCsvFilename="'products'|pgettext('ExportFilename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        :icon="$config.product.addProductIcon"
        :text="'New Product/Service...'|pgettext('ProductsView')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/products/products.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "products",
    path: "/products",
    component: "ProductBrowser"
  }
)

@Component()
export default class ProductBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "number",
          name: this.$fn.pgettext("ProductsView", "Code"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "product",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "name",
          name: this.$fn.pgettext("ProductsView", "Name"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "product",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "product_type",
          name: this.$fn.pgettext("ProductsView", "Product type"),
          formatter: {
            name: "gettext",
            context: "ProductTypes",
            conversion: "StartCase",
            filterInput: true,
          }
          ,
        }
          ,
        {
          field: "invoice_group",
          name: this.$fn.pgettext("ProductsView", "Invoice group"),
        }
          ,
        {
          field: "unit",
          name: this.$fn.pgettext("ProductsView", "Unit"),
        }
          ,
        {
          field: "sales_vat_code",
          name: this.$fn.pgettext("ProductsView", "Sales VAT code"),
        }
          ,
        {
          field: "purchase_vat_code",
          name: this.$fn.pgettext("ProductsView", "Purchase VAT code"),
        }
          ,
        {
          field: "groups",
          name: this.$fn.pgettext("ProductsView", "Groups"),
        }
          ,
        {
          field: "external_key",
          name: this.$fn.pgettext("ProductsView", "External key"),
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("ProductsView", "Products"),
        icon: this.$config.product.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3206_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3206, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_3208_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_3208, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_3210_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_3210, alias=undefined
    return {
      name: "CreateProduct",
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3206: actions.CloseModalAction;
    evh_7315092382398562_close_3205: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_3208: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_3207: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_showModal_3210: actions.ShowModalAction;
    evh_6466705138206980_click_3209: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3206 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3206_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3205 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3206,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3205],
      }
    );
    const act_2248226175642056_setData_3208 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_3208_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_3207 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_3208,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_3207],
      }
    );
    const act_6466705138206980_showModal_3210 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_3210_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_3209 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_3210,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_3209],
      }
    );
    return {
      act_7315092382398562_closeModal_3206,
      evh_7315092382398562_close_3205,
      evh_7315092382398562_close,
      act_2248226175642056_setData_3208,
      evh_2248226175642056_selectedRowsChanged_3207,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_showModal_3210,
      evh_6466705138206980_click_3209,
      evh_6466705138206980_click,
    }
  }
}

Vue.component("ProductBrowser", ProductBrowser);

</script>