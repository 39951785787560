"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let RentalProperty = class RentalProperty extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'formId', 'formName', 'ux', 'b_7315092382398562_modalBindings', 'b_7789867021953041_dataSource'];
    }
    beforeCreate() {
    }
    onRental_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rentalId");
    }
    onResource_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("resourceId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.resourceId
                ? this.$fn.fetch('read_rental_property', { parameters: { id: this.resourceId, rental_id: this.rentalId } })
                : this.$fn.schemaDefaults('CreateRentalProperty', { valid_from: this.$fn.today() });
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            formId: null,
            formName: "rentalProperyt",
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
            b_7789867021953041_dataSource: {
                name: "suggest_property",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.resourceId ? this.$fn.gettext('Edit Rental Property') : this.$fn.gettext('Add Rental Property'),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6956_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_6962_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_request_6960_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: this.item.id ? 'update_rental_property' : 'create_rental_property',
                data: Object.assign(Object.assign({}, this.item), { rental_id: this.rentalId }),
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeModal_6966_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1548630417156826_request_6964_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_rental_property",
                data: {
                    id: this.item.id,
                    rental_id: this.rentalId,
                    locked: true,
                    etag: this.item.etag
                },
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2186679009640457_closeModal_6968_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    cancel: true,
                },
            };
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6956 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6956_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6955 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6956,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6955],
        });
        const act_2248226175642056_closeComponent_6958 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6957 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6958,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6957],
        });
        const act_1040167445267876_closeModal_6962 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_6962_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6961 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_6962,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_request_6960 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_6960_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_6961],
        });
        const evh_1040167445267876_submit_6959 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_6960,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6959],
        });
        const act_1548630417156826_closeModal_6966 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1548630417156826_closeModal_6966_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_6965 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeModal_6966,
            event: "success",
            displayName: "closeModal",
        });
        const act_1548630417156826_request_6964 = new core_1.actions.RequestAction({
            actionArgs: this.act_1548630417156826_request_6964_getActionArgs,
            displayName: "lock",
            events: [evh_1548630417156826_success_6965],
        });
        const evh_1548630417156826_clickPreventStop_6963 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_request_6964,
            event: "click.prevent.stop",
            displayName: "lock",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_6963],
        });
        const act_2186679009640457_closeModal_6968 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2186679009640457_closeModal_6968_getActionArgs,
            events: [],
        });
        const evh_2186679009640457_clickPreventStop_6967 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeModal_6968,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_6967],
        });
        return {
            act_7315092382398562_closeModal_6956,
            evh_7315092382398562_close_6955,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6958,
            evh_2248226175642056_close_6957,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_6962,
            evh_1040167445267876_success_6961,
            act_1040167445267876_request_6960,
            evh_1040167445267876_submit_6959,
            evh_1040167445267876_submit,
            act_1548630417156826_closeModal_6966,
            evh_1548630417156826_success_6965,
            act_1548630417156826_request_6964,
            evh_1548630417156826_clickPreventStop_6963,
            evh_1548630417156826_clickPreventStop,
            act_2186679009640457_closeModal_6968,
            evh_2186679009640457_clickPreventStop_6967,
            evh_2186679009640457_clickPreventStop,
            submit: act_1040167445267876_request_6960,
            lock: act_1548630417156826_request_6964,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], RentalProperty.prototype, "rentalId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rentalId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalProperty.prototype, "onRental_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], RentalProperty.prototype, "resourceId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('resourceId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalProperty.prototype, "onResource_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], RentalProperty.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalProperty.prototype, "onSize", null);
RentalProperty = __decorate([
    (0, vue_property_decorator_1.Component)()
], RentalProperty);
exports.default = RentalProperty;
vue_property_decorator_1.Vue.component("RentalProperty", RentalProperty);
