import { render, staticRenderFns } from "./InflationIndexedPriceChange.yaml?vue&type=template&id=70d33d04&component"
import script from "./InflationIndexedPriceChange.yaml?vue&type=script&lang=ts&component"
export * from "./InflationIndexedPriceChange.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports