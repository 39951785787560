"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let RentalService = class RentalService extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'units', 'formName', 'formId', 'labelAlign', 'isReadOnly', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource', 'b_2070266589801014_dataSource', 'b_5074589820235598_dataSource'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onRental(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rental");
    }
    onMeter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    }
    onPrevent_edit_rental(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("preventEditRental");
    }
    onPrevent_edit_meter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("preventEditMeter");
    }
    onAssign_to_rental(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("assignToRental");
    }
    onAssign_to_meter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("assignToMeter");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid
                ? this.$fn.fetch('read_rental_service', { parameters: { id: this.oid } })
                : this.$fn.fetch('get_rental_service_defaults', {
                    parameters: {
                        meter_id: this.meter ? this.meter.id : null,
                        rental_id: this.rental ? this.rental.id : null,
                    }
                });
        });
    }
    $$load_units() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_unit').then(d => _.orderBy(d, [p => p.symbol.toLowerCase()]));
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_isReadOnly() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item.locked;
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            units: null,
            formName: "rentalService",
            formId: null,
            labelAlign: "right",
            isReadOnly: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
            b_5731695935601903_dataSource: {
                name: "suggest_rental",
            },
            b_2070266589801014_dataSource: {
                name: "suggest_meter",
            },
            b_5074589820235598_dataSource: {
                name: "suggest_product",
                parameters: {
                    product_types: "service",
                },
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.oid ? this.$fn.gettext('Edit Rental Service') : this.$fn.gettext('Add Rental Service'),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_7018_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_7024_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    ok: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_request_7022_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: this.item.id ? 'update_rental_service' : 'create_rental_service',
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_2070266589801014_script_7028_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            _.extend(this.item, $event.data.response.data);
        });
    }
    act_2070266589801014_script_7028_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2070266589801014_script_7028_getActionArgs_value($event),
            };
        });
    }
    act_2070266589801014_request_7026_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "get_rental_service_defaults",
                data: {
                    rental_id: this.rental ? this.rental.id : this.item.rental_id,
                    meter_id: this.item.meter_id,
                },
            };
        });
    }
    evh_2070266589801014_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2070266589801014_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_5074589820235598_setData_7030_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.service_id",
                value: this.item.service.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_5074589820235598_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5074589820235598_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_2140198529698299_closeModal_7032_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_2140198529698299_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2140198529698299_success.executeFromDOM(this, event, scope);
        });
    }
    act_2434514588044777_closeModal_7034_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_2434514588044777_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2434514588044777_success.executeFromDOM(this, event, scope);
        });
    }
    act_2186679009640457_closeModal_7036_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    cancel: true,
                },
            };
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_7018 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_7018_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_7017 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_7018,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_7017],
        });
        const act_2248226175642056_closeComponent_7020 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_7019 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_7020,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_7019],
        });
        const act_1040167445267876_closeModal_7024 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_7024_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_7023 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_7024,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_request_7022 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_7022_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_7023],
        });
        const evh_1040167445267876_submit_7021 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_7022,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_7021],
        });
        const act_2070266589801014_script_7028 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2070266589801014_script_7028_getActionArgs,
            events: [],
        });
        const evh_2070266589801014_success_7027 = new core_1.actions.EventHandlerImpl({
            action: act_2070266589801014_script_7028,
            event: "success",
            displayName: "script",
        });
        const act_2070266589801014_request_7026 = new core_1.actions.RequestAction({
            actionArgs: this.act_2070266589801014_request_7026_getActionArgs,
            displayName: "getDefaultsOnMeterSelected",
            events: [evh_2070266589801014_success_7027],
        });
        const evh_2070266589801014_optionSelected_7025 = new core_1.actions.EventHandlerImpl({
            action: act_2070266589801014_request_7026,
            event: "optionSelected",
            displayName: "getDefaultsOnMeterSelected",
        });
        const evh_2070266589801014_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2070266589801014_optionSelected_7025],
        });
        const act_5074589820235598_setData_7030 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5074589820235598_setData_7030_getActionArgs,
            events: [],
        });
        const evh_5074589820235598_optionSelected_7029 = new core_1.actions.EventHandlerImpl({
            action: act_5074589820235598_setData_7030,
            event: "optionSelected",
            displayName: "setData",
        });
        const evh_5074589820235598_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5074589820235598_optionSelected_7029],
        });
        const act_2140198529698299_closeModal_7032 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2140198529698299_closeModal_7032_getActionArgs,
            events: [],
        });
        const evh_2140198529698299_success_7031 = new core_1.actions.EventHandlerImpl({
            action: act_2140198529698299_closeModal_7032,
            event: "success",
            displayName: "closeModal",
        });
        const evh_2140198529698299_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2140198529698299_success_7031],
        });
        const act_2434514588044777_closeModal_7034 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2434514588044777_closeModal_7034_getActionArgs,
            events: [],
        });
        const evh_2434514588044777_success_7033 = new core_1.actions.EventHandlerImpl({
            action: act_2434514588044777_closeModal_7034,
            event: "success",
            displayName: "closeModal",
        });
        const evh_2434514588044777_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2434514588044777_success_7033],
        });
        const act_2186679009640457_closeModal_7036 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2186679009640457_closeModal_7036_getActionArgs,
            events: [],
        });
        const evh_2186679009640457_clickPreventStop_7035 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeModal_7036,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_7035],
        });
        return {
            act_7315092382398562_closeModal_7018,
            evh_7315092382398562_close_7017,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_7020,
            evh_2248226175642056_close_7019,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_7024,
            evh_1040167445267876_success_7023,
            act_1040167445267876_request_7022,
            evh_1040167445267876_submit_7021,
            evh_1040167445267876_submit,
            act_2070266589801014_script_7028,
            evh_2070266589801014_success_7027,
            act_2070266589801014_request_7026,
            evh_2070266589801014_optionSelected_7025,
            evh_2070266589801014_optionSelected,
            act_5074589820235598_setData_7030,
            evh_5074589820235598_optionSelected_7029,
            evh_5074589820235598_optionSelected,
            act_2140198529698299_closeModal_7032,
            evh_2140198529698299_success_7031,
            evh_2140198529698299_success,
            act_2434514588044777_closeModal_7034,
            evh_2434514588044777_success_7033,
            evh_2434514588044777_success,
            act_2186679009640457_closeModal_7036,
            evh_2186679009640457_clickPreventStop_7035,
            evh_2186679009640457_clickPreventStop,
            submit: act_1040167445267876_request_7022,
            getDefaultsOnMeterSelected: act_2070266589801014_request_7026,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], RentalService.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
        default: null,
    }),
    __metadata("design:type", Object)
], RentalService.prototype, "rental", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rental'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onRental", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
        default: null,
    }),
    __metadata("design:type", Object)
], RentalService.prototype, "meter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('meter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onMeter", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], RentalService.prototype, "preventEditRental", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('preventEditRental'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onPrevent_edit_rental", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], RentalService.prototype, "preventEditMeter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('preventEditMeter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onPrevent_edit_meter", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], RentalService.prototype, "assignToRental", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('assignToRental'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onAssign_to_rental", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: false,
    }),
    __metadata("design:type", Boolean)
], RentalService.prototype, "assignToMeter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('assignToMeter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onAssign_to_meter", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], RentalService.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], RentalService.prototype, "onSize", null);
RentalService = __decorate([
    (0, vue_property_decorator_1.Component)()
], RentalService);
exports.default = RentalService;
vue_property_decorator_1.Vue.component("RentalService", RentalService);
