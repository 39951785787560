"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let BtnSyncInvoice = class BtnSyncInvoice extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['jobData', 'invoiceSyncPoller', 'messages', 'statuses', 'ux'];
    }
    beforeCreate() {
    }
    beforeDestroy(...args) {
        {
            {
                this.invoiceSyncPoller && this.invoiceSyncPoller.stop();
            }
        }
    }
    checkStatus(...args) {
        if (!this.jobData) {
            console.log('JobData is null, stop polling.');
            this.invoiceSyncPoller.stop();
            return;
        }
        const status = this.jobData.status;
        if (status !== 'pending' && status !== 'running') {
            console.log(`JobStatus is ${status}, stop polling.`, this.invoiceSyncPoller);
            this.invoiceSyncPoller.stop();
            this.$emit('success');
            return;
        }
        this.$fn
            .fetch('read_a_job', { parameters: { id: this.jobData.id } })
            .then(data => this.jobData = data)
            .catch(err => {
            console.error(err);
            this.invoiceSyncPoller.stop();
        });
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    $$load_invoiceSyncPoller() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.poller({ id: 'InvoiceSync', callback: this.checkStatus });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            jobData: null,
            invoiceSyncPoller: null,
            messages: {
                createButtonText: "Számla kiállítása",
                createButtonTitle: "A számla kiállítása a számlázó szolgáltatónál",
                syncButtonTooltip: "Szinkronizálás",
                confirmSyncDialog: {
                    header: "Számla szinkronizálása",
                    message: "A számlát csak egyszer lehet feltölteni a szamlazz.hu rendszerébe, feltöltés után a számlát már nem lehet módosítani.",
                    btnOk: "Szinkronizálás",
                    btnCancel: "Mégsem",
                },
                cancelSyncDialog: {
                    header: "Szinkronizálás megszakítása",
                    message: "Megszakítás esetén a számla nem lesz szinkronizálva.\n\
Tartalma módosítható, szerkesztés után az új adattartalommal újra szinkronizálható.\n\
A sor feldolgozása a megaszakított elemnél szünetel.\n\
",
                    btnOk: "Megszakítás",
                    btnCancel: "Mégsem",
                },
                showLogMessagesTooltip: "Naplófile megtekintése.",
            },
            statuses: {
                default: {
                    variant: "primary",
                    text: "Számla kiállítása",
                    tooltip: "A számla kiállítása a számlázó szolgáltatónál",
                },
                pending: {
                    icon: "material-symbols:lock-clock-outline-sharp",
                    color: "bg-yellow-200",
                    variant: "warning",
                    text: "Számla kiállítása folyamatban",
                    tooltip: "A számla szinkronizálásra vár, ebben az állapotában nem módosítható.",
                },
                retry: {
                    icon: "material-symbols:lock-clock-outline-sharp",
                    color: "bg-yellow-200",
                    variant: "warning",
                    text: "Számla kiállítása folyamatban",
                    tooltip: "A számla szinkronizálás alatt.",
                },
                processing: {
                    icon: "material-symbols:cloud-sync-rounded",
                    color: "bg-blue-200",
                    variant: "warning",
                    text: "Számla kiállítása folyamatban",
                    tooltip: "A számla szinkronizálás alatt.",
                },
                failed: {
                    icon: "material-symbols:sync-problem-outline-rounded",
                    color: "bg-red-200",
                    variant: "danger",
                    text: "Számla kiállítás hiba",
                    tooltip: "Hiba történt a szinkronizálás során.",
                },
                cancelled: {
                    icon: "material-symbols:sync-disabled",
                    color: "bg-gray-200",
                    variant: "secondary",
                    text: "Számla kiállítás megszakítva",
                    tooltip: "A számla törölve lett a szinkronizálási sorból.",
                },
            },
            ux: null,
        });
    }
    get status() {
        if (this.loaded) {
            return this.jobData ? this.jobData.status : (this.invoice.sync_job ? this.invoice.sync_job.status : null);
        }
        return null;
    }
    get context() {
        if (this.loaded) {
            return this.statuses[this.status || 'default'];
        }
        return null;
    }
    get isJobRunning() {
        if (this.loaded) {
            return this.status === 'running' || this.status === 'pending';
        }
        return null;
    }
    act_2248226175642056_setData_5016_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "jobData",
                value: $event.data.modalResult && $event.data.modalResult.value,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_close_5015_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult && $event.data.modalResult.ok;
        });
    }
    act_2248226175642056_script_5018_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.invoiceSyncPoller.start();
        });
    }
    act_2248226175642056_script_5018_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2248226175642056_script_5018_getActionArgs_value($event),
            };
        });
    }
    evh_2248226175642056_close_5017_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult && $event.data.modalResult.ok;
        });
    }
    act_2248226175642056_showModal_5014_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ConfirmSyncInvoice",
                props: {
                    invoice: this.invoice,
                },
            };
        });
    }
    evh_2248226175642056_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_showModal_5020_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Job",
                props: {
                    oid: this.invoice.sync_job.id,
                },
            };
        });
    }
    evh_5962862420439144_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_2248226175642056_setData_5016 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5016_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_close_5015 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5016,
            event: "close",
            when: this.evh_2248226175642056_close_5015_getWhen,
            displayName: "setData",
        });
        const act_2248226175642056_script_5018 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2248226175642056_script_5018_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_close_5017 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_script_5018,
            event: "close",
            when: this.evh_2248226175642056_close_5017_getWhen,
            displayName: "script",
        });
        const act_2248226175642056_showModal_5014 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_5014_getActionArgs,
            displayName: "sync",
            events: [evh_2248226175642056_close_5015, evh_2248226175642056_close_5017],
        });
        const evh_2248226175642056_clickPreventStop_5013 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_5014,
            event: "click.prevent.stop",
            displayName: "sync",
        });
        const evh_2248226175642056_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_clickPreventStop_5013],
        });
        const act_5962862420439144_showModal_5020 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5962862420439144_showModal_5020_getActionArgs,
            displayName: "showJob",
            events: [],
        });
        const evh_5962862420439144_clickPreventStop_5019 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_showModal_5020,
            event: "click.prevent.stop",
            displayName: "showJob",
        });
        const evh_5962862420439144_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_clickPreventStop_5019],
        });
        return {
            act_2248226175642056_setData_5016,
            evh_2248226175642056_close_5015,
            act_2248226175642056_script_5018,
            evh_2248226175642056_close_5017,
            act_2248226175642056_showModal_5014,
            evh_2248226175642056_clickPreventStop_5013,
            evh_2248226175642056_clickPreventStop,
            act_5962862420439144_showModal_5020,
            evh_5962862420439144_clickPreventStop_5019,
            evh_5962862420439144_clickPreventStop,
            sync: act_2248226175642056_showModal_5014,
            showJob: act_5962862420439144_showModal_5020,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], BtnSyncInvoice.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], BtnSyncInvoice.prototype, "onInvoice", null);
BtnSyncInvoice = __decorate([
    (0, vue_property_decorator_1.Component)()
], BtnSyncInvoice);
exports.default = BtnSyncInvoice;
vue_property_decorator_1.Vue.component("BtnSyncInvoice", BtnSyncInvoice);
