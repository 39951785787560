"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let Numbering = class Numbering extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['resourceTypes', 'item', 'formId', 'ux', 'b_7315092382398562_modalBindings', 'b_3083451129821185_paging', 'b_3083451129821185_columns'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onObject_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("objectType");
    }
    onCreate_params(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("createParams");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid
                ? this.$fn.fetch('read_numbering', { parameters: { id: this.oid }, asResource: true })
                : this.$fn.schemaDefaults('CreateNumbering', _.extend({
                    resource_type: this.objectType,
                    start: 1,
                    increment: 1,
                    variables: []
                }, this.createParams));
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            resourceTypes: ["account",
                "contract",
                "dunning_letter",
                "fixed_asset",
                "invoice:out",
                "invoice:in",
                "invoice_register",
                "letter_register",
                "part",
                "product",
                "register:incoming_invoice",
                "register:outgoing_invoice",
                "register:incoming_letters",
                "register:outgoing_letters",
                "rental",
                "rental_contract",
                "worksheet",
            ],
            item: null,
            formId: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
            b_3083451129821185_paging: false,
            b_3083451129821185_columns: [{
                    name: "id",
                    header: this.$fn.pgettext("Numbering", "ID"),
                    width: "120px",
                    required: true,
                },
                {
                    name: "name",
                    header: this.$fn.pgettext("Numbering", "Name"),
                    width: "120px",
                    required: true,
                },
                {
                    name: "data_type",
                    header: this.$fn.pgettext("Numbering", "Data type"),
                    width: "120px",
                    required: true,
                },
                {
                    name: "value",
                    header: this.$fn.pgettext("Numbering", "Value"),
                    width: "120px",
                    required: true,
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.oid == null ? this.$fn.gettext("New Numbering") : this.item.snapshot("key"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3014_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_3020_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_3018_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "numbering",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeModal_3022_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3014 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3014_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3013 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3014,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3013],
        });
        const act_2248226175642056_closeComponent_3016 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_3015 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_3016,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3015],
        });
        const act_1040167445267876_closeModal_3020 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_3020_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_3019 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_3020,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_crud_3018 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_3018_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_3019],
        });
        const evh_1040167445267876_submit_3017 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_3018,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_3017],
        });
        const act_1419464017721962_closeModal_3022 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_3022_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_3021 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_3022,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_3021],
        });
        return {
            act_7315092382398562_closeModal_3014,
            evh_7315092382398562_close_3013,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_3016,
            evh_2248226175642056_close_3015,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_3020,
            evh_1040167445267876_success_3019,
            act_1040167445267876_crud_3018,
            evh_1040167445267876_submit_3017,
            evh_1040167445267876_submit,
            act_1419464017721962_closeModal_3022,
            evh_1419464017721962_clickPreventStop_3021,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_crud_3018,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Numbering.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Numbering.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Numbering.prototype, "objectType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('objectType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Numbering.prototype, "onObject_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
        default: null,
    }),
    __metadata("design:type", Object)
], Numbering.prototype, "createParams", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('createParams'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Numbering.prototype, "onCreate_params", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], Numbering.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Numbering.prototype, "onSize", null);
Numbering = __decorate([
    (0, vue_property_decorator_1.Component)()
], Numbering);
exports.default = Numbering;
vue_property_decorator_1.Vue.component("Numbering", Numbering);
