<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <BListGroup
    flush
  >
    <BListGroupItem
      :key="fee.id"
      v-for="fee in filterFees(fees, displayAll)"
    >
      <ItpBox>
        <ItpBox>
          <ItpText>
            <ItpText>{{ fee.valid_from|date }}</ItpText>
<ItpText v-if="fee.valid_to"> - {{ fee.valid_to|date }}</ItpText>

<ItpText class="px-3">
  <span v-if="fee.quantity != null">
    {{ fee.quantity|number }} x
  </span>
  {{ fee.amount|number }} {{ fee.currency }}/{{ fee.unit }}
</ItpText>

          </ItpText>
          <ItpText
            :text="fee.notes"
            pre
            v-if="fee.notes"
          >
          </ItpText>
        </ItpBox>
        <ItpButton
          icon="fa-pen"
          :tooltip="'Edit'|gettext"
          variant="light"
          size="sm"
          v-if="!readonly"
          @click.prevent.stop="evh_2095089031469157_clickPreventStop($event, {fee})"
        >
        </ItpButton>
      </ItpBox>
    </BListGroupItem>
  </BListGroup>
  <ItpBox
    class="pl-4"
  >
    <ItpLink
      :text="'Show less'|gettext"
      v-if="canHideItems"
      @click.prevent.stop="evh_1040167445267876_clickPreventStop($event, {})"
    >
    </ItpLink>
    <ItpLink
      :text="'Show more'|gettext"
      v-if="canDisplayMoreItems"
      @click.prevent.stop="evh_4186377094414664_clickPreventStop($event, {})"
    >
    </ItpLink>
    <ItpButton
      :text="'Add service fee...'|pgettext('Contract')"
      variant="light"
      size="sm"
      class="ml-auto"
      v-if="!readonly"
      @click.prevent.stop="evh_3083451129821185_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractServiceFees extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  mapFees(...args: any[]) {
    return _
      .chain(args[0])
      .orderBy(['valid_from', 'valid_to'], ['desc', 'desc'])
      .map((item, index, arr) => {
        const
          future = item.valid_from > this.$fn.today,
          current = !future && (index == 0 || arr[index - 1].valid_from > this.$fn.today),
          stale = !future && !current;

        return { item, future, current, stale }
      })
      .value();
  }

  filterFees(...args: any[]) {
    const [fees, displayAll] = args;
    return _
      .filter(fees, p => displayAll || p.future || p.current)
      .map(p => p.item);
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    required: true,
    type: Object,
  })
  service!: object;

  @Watch('service')
  onService(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("service")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  today!: any;

  async $$load_today() {
    return new Date()
  }

  displayAll!: any;
  fees!: any;

  async $$load_fees() {
    return this.mapFees(this.service.fees)
  }

  hasStaleItems!: any;

  async $$load_hasStaleItems() {
    return _.some(this.fees, { stale: true })
  }

  ux!: any;
  dataMembers = ['today', 'displayAll', 'fees', 'hasStaleItems', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        today: null,
        displayAll: false,
        fees: null,
        hasStaleItems: null,
        ux: null,
      },
    }
  }

  get canDisplayMoreItems() {
    if (this.loaded) {

      return !this.displayAll && this.hasStaleItems
    }
    return null;
  }

  get canHideItems() {
    if (this.loaded) {

      return this.displayAll && this.hasStaleItems
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2095089031469157_emit_6480_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2095089031469157_emit_6480, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_2095089031469157_close_6479_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2095089031469157_close_6479, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2095089031469157_showModal_6478_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2095089031469157_showModal_6478, alias=undefined
    return {
      name: "ContractServiceEditor",
      props: {
        mode: "editServiceFee",
        contract: this.contract,
        service: _.cloneDeep(this.service),
        fee: _.cloneDeep($event.scope.fee),
      }
      ,
    }
  }

  async evh_2095089031469157_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_6482_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_6482, alias=undefined
    return {
      path: "displayAll",
      value: false,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1040167445267876_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_4186377094414664_setData_6484_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4186377094414664_setData_6484, alias=undefined
    return {
      path: "displayAll",
      value: true,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_4186377094414664_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4186377094414664_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3083451129821185_emit_6488_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3083451129821185_emit_6488, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_3083451129821185_close_6487_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3083451129821185_close_6487, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_3083451129821185_showModal_6486_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3083451129821185_showModal_6486, alias=undefined
    return {
      name: "ContractServiceEditor",
      props: {
        mode: "addServiceFee",
        contract: this.contract,
        service: _.cloneDeep(this.service),
      }
      ,
    }
  }

  async evh_3083451129821185_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3083451129821185_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6476: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6475: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2095089031469157_emit_6480: actions.EmitAction;
    evh_2095089031469157_close_6479: actions.EventHandlerImpl;
    act_2095089031469157_showModal_6478: actions.ShowModalAction;
    evh_2095089031469157_clickPreventStop_6477: actions.EventHandlerImpl;
    evh_2095089031469157_clickPreventStop: actions.EventHandlerGroup;
    act_1040167445267876_setData_6482: actions.SetDataAction;
    evh_1040167445267876_clickPreventStop_6481: actions.EventHandlerImpl;
    evh_1040167445267876_clickPreventStop: actions.EventHandlerGroup;
    act_4186377094414664_setData_6484: actions.SetDataAction;
    evh_4186377094414664_clickPreventStop_6483: actions.EventHandlerImpl;
    evh_4186377094414664_clickPreventStop: actions.EventHandlerGroup;
    act_3083451129821185_emit_6488: actions.EmitAction;
    evh_3083451129821185_close_6487: actions.EventHandlerImpl;
    act_3083451129821185_showModal_6486: actions.ShowModalAction;
    evh_3083451129821185_clickPreventStop_6485: actions.EventHandlerImpl;
    evh_3083451129821185_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6476 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6475 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6476,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6475],
      }
    );
    const act_2095089031469157_emit_6480 = new actions.EmitAction(
      {
        actionArgs: this.act_2095089031469157_emit_6480_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_2095089031469157_close_6479 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_emit_6480,
        event: "close",
        when: this.evh_2095089031469157_close_6479_getWhen,
        displayName: "emitReload",
      }
    );
    const act_2095089031469157_showModal_6478 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2095089031469157_showModal_6478_getActionArgs,
        events: [evh_2095089031469157_close_6479],
      }
    );
    const evh_2095089031469157_clickPreventStop_6477 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_showModal_6478,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2095089031469157_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_clickPreventStop_6477],
      }
    );
    const act_1040167445267876_setData_6482 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_6482_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_clickPreventStop_6481 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_6482,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_1040167445267876_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_clickPreventStop_6481],
      }
    );
    const act_4186377094414664_setData_6484 = new actions.SetDataAction(
      {
        actionArgs: this.act_4186377094414664_setData_6484_getActionArgs,
        events: [],
      }
    );
    const evh_4186377094414664_clickPreventStop_6483 = new actions.EventHandlerImpl(
      {
        action: act_4186377094414664_setData_6484,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_4186377094414664_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_4186377094414664_clickPreventStop_6483],
      }
    );
    const act_3083451129821185_emit_6488 = new actions.EmitAction(
      {
        actionArgs: this.act_3083451129821185_emit_6488_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_3083451129821185_close_6487 = new actions.EventHandlerImpl(
      {
        action: act_3083451129821185_emit_6488,
        event: "close",
        when: this.evh_3083451129821185_close_6487_getWhen,
        displayName: "emitReload",
      }
    );
    const act_3083451129821185_showModal_6486 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3083451129821185_showModal_6486_getActionArgs,
        events: [evh_3083451129821185_close_6487],
      }
    );
    const evh_3083451129821185_clickPreventStop_6485 = new actions.EventHandlerImpl(
      {
        action: act_3083451129821185_showModal_6486,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3083451129821185_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3083451129821185_clickPreventStop_6485],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6476,
      evh_7315092382398562_reload_6475,
      evh_7315092382398562_reload,
      act_2095089031469157_emit_6480,
      evh_2095089031469157_close_6479,
      act_2095089031469157_showModal_6478,
      evh_2095089031469157_clickPreventStop_6477,
      evh_2095089031469157_clickPreventStop,
      act_1040167445267876_setData_6482,
      evh_1040167445267876_clickPreventStop_6481,
      evh_1040167445267876_clickPreventStop,
      act_4186377094414664_setData_6484,
      evh_4186377094414664_clickPreventStop_6483,
      evh_4186377094414664_clickPreventStop,
      act_3083451129821185_emit_6488,
      evh_3083451129821185_close_6487,
      act_3083451129821185_showModal_6486,
      evh_3083451129821185_clickPreventStop_6485,
      evh_3083451129821185_clickPreventStop,
      emitReload: act_3083451129821185_emit_6488,
    }
  }
}

Vue.component("UcContractServiceFees", UcContractServiceFees);

</script>