"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let WorksheetEditor = class WorksheetEditor extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "modal",
        };
        this.dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux'];
    }
    beforeCreate() {
    }
    getPayload(...args) {
        const [data] = args;
        const fields = this.target && this.targetConfig[this.target].fieldsToSubmit;
        return fields ? _.pick(data, fields) : data;
    }
    onWorksheet(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("worksheet");
    }
    onTarget(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("target");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            defaultTitle: this.$fn.pgettext("Worksheet", "Edit Worksheet"),
            targetConfig: {
                header: {
                    title: this.$fn.pgettext("Worksheet", "Edit Worksheet Header"),
                    fieldsToSubmit: ["id",
                        "revision_id",
                        "etag",
                        "company",
                    ],
                },
            },
            ux: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4300_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_closeComponent_4302_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    cancel: true,
                },
            };
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_5534025912102772_closeModal_4306_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_5534025912102772_crud_4304_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "worksheet",
                op: "upsert",
                data: this.getPayload(this.worksheet),
            };
        });
    }
    evh_5534025912102772_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeComponent_4308_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    cancel: true,
                },
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4300 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4300_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4299 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4300,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4299],
        });
        const act_2248226175642056_closeComponent_4302 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_2248226175642056_closeComponent_4302_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_close_4301 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_4302,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_4301],
        });
        const act_5534025912102772_closeModal_4306 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_5534025912102772_closeModal_4306_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_success_4305 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_closeModal_4306,
            event: "success",
            displayName: "closeModal",
        });
        const act_5534025912102772_crud_4304 = new core_1.actions.CRUDAction({
            actionArgs: this.act_5534025912102772_crud_4304_getActionArgs,
            displayName: "save",
            events: [evh_5534025912102772_success_4305],
        });
        const evh_5534025912102772_submit_4303 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_crud_4304,
            event: "submit",
            displayName: "save",
        });
        const evh_5534025912102772_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_submit_4303],
        });
        const act_1419464017721962_closeComponent_4308 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1419464017721962_closeComponent_4308_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_4307 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_4308,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_4307],
        });
        return {
            act_7315092382398562_closeModal_4300,
            evh_7315092382398562_close_4299,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_4302,
            evh_2248226175642056_close_4301,
            evh_2248226175642056_close,
            act_5534025912102772_closeModal_4306,
            evh_5534025912102772_success_4305,
            act_5534025912102772_crud_4304,
            evh_5534025912102772_submit_4303,
            evh_5534025912102772_submit,
            act_1419464017721962_closeComponent_4308,
            evh_1419464017721962_clickPreventStop_4307,
            evh_1419464017721962_clickPreventStop,
            save: act_5534025912102772_crud_4304,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], WorksheetEditor.prototype, "worksheet", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('worksheet'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WorksheetEditor.prototype, "onWorksheet", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], WorksheetEditor.prototype, "target", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('target'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WorksheetEditor.prototype, "onTarget", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], WorksheetEditor.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WorksheetEditor.prototype, "onSize", null);
WorksheetEditor = __decorate([
    (0, vue_property_decorator_1.Component)()
], WorksheetEditor);
exports.default = WorksheetEditor;
vue_property_decorator_1.Vue.component("WorksheetEditor", WorksheetEditor);
