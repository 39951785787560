<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ resourceTitle }}
    </ItpText>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <UcInvoiceTypeBadge
    :value="invoice.invoice_type"
  >
  </UcInvoiceTypeBadge>
  <UcInvoiceStatusBadge
    :value="invoice.status"
  >
  </UcInvoiceStatusBadge>
  <DIV
    v-if="invoice.tags && invoice.tags.length"
  >
    <span
      class="badge badge-secondary mx-1 shadow-none"
      v-for="tag in invoice.tags"
    >
      {{ tag }}
    </span>
  </DIV>
  <DIV
    :title="'Closed'|gettext"
    v-if="invoice.isReadOnly"
  >
    <ItpIcon
      :icon="$config.lockIcon"
    >
    </ItpIcon>
  </DIV>
  <ItpBox
    direction="column"
    v-if="invoice.reference_invoice"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Reference invoice'|pgettext('Invoice') }}
    </ItpText>
    <UcInvoiceMiniCard
      :item="invoice.reference_invoice"
      no-icon
    >
    </UcInvoiceMiniCard>
  </ItpBox>
  <ItpBox
    direction="column"
    v-if="invoice.referencing_invoices && invoice.referencing_invoices.length"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Referencing invoices'|pgettext('Invoice') }}
    </ItpText>
    <ItpBox>
      <UcInvoiceMiniCard
        :key="obj.id"
        :item="obj"
        no-icon
        class="mr-2"
        v-for="obj in invoice.referencing_invoices"
      >
      </UcInvoiceMiniCard>
    </ItpBox>
  </ItpBox>
  <Icon
    :icon="'flagpack:' + invoice.language"
    :title="'Invoice Language'|pgettext('Invoice')"
    v-if="!invoice.isNew"
  >
  </Icon>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <BtnSyncInvoice
      :invoice="invoice"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_8680084583475136_success($event, {})"
    >
    </BtnSyncInvoice>
    <BtnSyncResource
      :resource="invoice"
      target="remote"
      confirmMessage="A számlát csak egyszer lehet felölteni a régi rendszerbe,
feltöltés után a számlát már nem lehet módosítani."
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_3282321863127091_success($event, {})"
    >
    </BtnSyncResource>
    <ItpButton
      :icon="$config.editIcon"
      :tooltip="'Edit'|gettext"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew && !invoice.isReadOnly"
      @click.prevent.stop="evh_2386378572842701_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @click.prevent.stop="evh_6305342467955186_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:printer-one"
      :href="invoice.print_url"
      :tooltip="'Open print document'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.print_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="invoice.preview_url"
      :tooltip="'Open preview'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.preview_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:log"
      :tooltip="'Show log messages'|pgettext('Invoice')"
      class="ml-auto mr-2"
      v-if="invoice.log_messages"
      @click.prevent.stop="evh_6493473528837603_clickPreventStop($event, {})"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      no-caret
      class="border"
      v-if="!invoice.isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <ItpButton
        dropdown-item
        :text="'Register invoice'|pgettext('Invoice')"
        :tooltip="'Add this invoice to the invoice register'|pgettext('Invoice')"
        variant="default"
        v-if="invoiceIsRegistrable"
        @click="evh_5796712150329752_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Copy'|pgettext('BtnCopyResource')"
        :tooltip="'Create new invoice from this one'|pgettext('Invoice')"
        variant="default"
        @click="evh_4125470329773403_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Create storno'|pgettext('Invoice')"
        :tooltip="createStornoTooltip"
        variant="default"
        :disabled="!invoiceIsReversible"
        v-if="!invoice.isNew"
        @click="evh_3785918277944093_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        variant="default"
        :text="'Create corrector'|pgettext('Invoice')"
        :tooltip="createCorrectorTooltip"
        :disabled="!invoiceIsModifiable"
        v-if="!invoice.isNew"
        @click="evh_2191919750581405_click($event, {})"
      >
      </ItpButton>
      <BtnLockResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Lock invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_3699289115122175_success($event, {})"
      >
      </BtnLockResource>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Delete invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_5417874158095965_success($event, {})"
      >
      </BtnDeleteResource>
      <hr>
      </hr>
      <ItpButton
        dropdown-item
        :text="'History'|pgettext('BtnResourceHistory')"
        :tooltip="'Invoice history'|pgettext('Invoice')"
        variant="default"
        @click.prevent.stop="evh_8518465037810560_clickPreventStop($event, {})"
      >
      </ItpButton>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
  })
  invoiceNumber!: string;

  @Watch('invoiceNumber')
  onInvoice_number(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceNumber")
  }

  @Prop({
    required: true,
    type: Object,
  })
  formContext!: object;

  @Watch('formContext')
  onForm_context(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formContext")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          newTransaction: this.$fn.pgettext("Invoice", "New Invoice"),
          invoiceKind: {
            incoming: this.$fn.pgettext("InvoiceKind", "Incoming Invoice"),
            outgoing: this.$fn.pgettext("InvoiceKind", "Outgoing Invoice"),
          }
          ,
          createCorrectorTooltip: this.$fn.pgettext("Invoice", "Create corrector invoice"),
          createStornoTooltip: this.$fn.pgettext("Invoice", "Create storno invoice"),
        }
        ,
        ux: null,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.invoiceKind[this.invoice.invoice_kind]
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.invoiceNumber || (this.invoice.isNew ? this.messages.newTransaction : this.invoice.invoice_number)
    }
    return null;
  }

  get createCorrectorTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.message') || this.messages.createCorrectorTooltip
    }
    return null;
  }

  get createStornoTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.message') || this.messages.createStornoTooltip
    }
    return null;
  }

  get invoiceIsReversible() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.result')
    }
    return null;
  }

  get invoiceIsModifiable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.result')
    }
    return null;
  }

  get invoiceIsRegistrable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.registrable.result')
    }
    return null;
  }

  async act_8680084583475136_emit_5112_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8680084583475136_emit_5112, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8680084583475136_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8680084583475136_success.executeFromDOM(this, event, scope);
  }

  async act_3282321863127091_emit_5114_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3282321863127091_emit_5114, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3282321863127091_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3282321863127091_success.executeFromDOM(this, event, scope);
  }

  async act_2386378572842701_emit_5118_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2386378572842701_emit_5118, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2386378572842701_close_5117_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2386378572842701_close_5117, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_2386378572842701_showModal_5116_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2386378572842701_showModal_5116, alias=undefined
    return {
      name: "InvoiceEditor",
      props: {
        invoice: _.cloneDeep(this.invoice),
      }
      ,
    }
  }

  async evh_2386378572842701_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2386378572842701_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6305342467955186_emit_5120_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6305342467955186_emit_5120, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_6305342467955186_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6305342467955186_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6493473528837603_showModal_5122_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6493473528837603_showModal_5122, alias=undefined
    return {
      name: "InvoiceLogMessageViewer",
      props: {
        invoiceId: this.invoice.id,
      }
      ,
    }
  }

  async evh_6493473528837603_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6493473528837603_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5796712150329752_emit_5126_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5796712150329752_emit_5126, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5796712150329752_close_5125_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_5796712150329752_close_5125, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_5796712150329752_showModal_5124_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5796712150329752_showModal_5124, alias=registerInvoice
    return {
      name: "InvoiceRegisterEntry",
      props: {
        invoice: this.invoice,
      }
      ,
    }
  }

  async evh_5796712150329752_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5796712150329752_click.executeFromDOM(this, event, scope);
  }

  async act_4125470329773403_emit_5130_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4125470329773403_emit_5130, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_4125470329773403_request_5128_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_4125470329773403_request_5128, alias=createInvoicecopy
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'copy' },
    }
  }

  async evh_4125470329773403_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4125470329773403_click.executeFromDOM(this, event, scope);
  }

  async act_3785918277944093_emit_5134_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3785918277944093_emit_5134, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_3785918277944093_request_5132_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3785918277944093_request_5132, alias=createStornoInvoice
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'storno' },
    }
  }

  async evh_3785918277944093_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3785918277944093_click.executeFromDOM(this, event, scope);
  }

  async act_2191919750581405_emit_5138_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2191919750581405_emit_5138, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_2191919750581405_request_5136_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2191919750581405_request_5136, alias=createCorrectorIvoice
    return {
      operation: "create_invoice",
      data: {
        invoice_id: this.invoice.id,
        invoice_type: 'correction',
        invoice_lines: _.map(this.formContext.selectedInvoiceLines, p => p.id).join(',')
      },
    }
  }

  async evh_2191919750581405_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2191919750581405_click.executeFromDOM(this, event, scope);
  }

  async act_3699289115122175_emit_5140_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3699289115122175_emit_5140, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3699289115122175_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3699289115122175_success.executeFromDOM(this, event, scope);
  }

  async act_5417874158095965_emit_5142_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5417874158095965_emit_5142, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_5417874158095965_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5417874158095965_success.executeFromDOM(this, event, scope);
  }

  async act_8518465037810560_showModal_5144_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8518465037810560_showModal_5144, alias=undefined
    return {
      name: "entityEvents",
      props: {
        entityId: this.invoice.id,
      }
      ,
    }
  }

  async evh_8518465037810560_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8518465037810560_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8680084583475136_emit_5112: actions.EmitAction;
    evh_8680084583475136_success_5111: actions.EventHandlerImpl;
    evh_8680084583475136_success: actions.EventHandlerGroup;
    act_3282321863127091_emit_5114: actions.EmitAction;
    evh_3282321863127091_success_5113: actions.EventHandlerImpl;
    evh_3282321863127091_success: actions.EventHandlerGroup;
    act_2386378572842701_emit_5118: actions.EmitAction;
    evh_2386378572842701_close_5117: actions.EventHandlerImpl;
    act_2386378572842701_showModal_5116: actions.ShowModalAction;
    evh_2386378572842701_clickPreventStop_5115: actions.EventHandlerImpl;
    evh_2386378572842701_clickPreventStop: actions.EventHandlerGroup;
    act_6305342467955186_emit_5120: actions.EmitAction;
    evh_6305342467955186_clickPreventStop_5119: actions.EventHandlerImpl;
    evh_6305342467955186_clickPreventStop: actions.EventHandlerGroup;
    act_6493473528837603_showModal_5122: actions.ShowModalAction;
    evh_6493473528837603_clickPreventStop_5121: actions.EventHandlerImpl;
    evh_6493473528837603_clickPreventStop: actions.EventHandlerGroup;
    act_5796712150329752_emit_5126: actions.EmitAction;
    evh_5796712150329752_close_5125: actions.EventHandlerImpl;
    act_5796712150329752_showModal_5124: actions.ShowModalAction;
    evh_5796712150329752_click_5123: actions.EventHandlerImpl;
    evh_5796712150329752_click: actions.EventHandlerGroup;
    act_4125470329773403_emit_5130: actions.EmitAction;
    evh_4125470329773403_success_5129: actions.EventHandlerImpl;
    act_4125470329773403_request_5128: actions.RequestAction;
    evh_4125470329773403_click_5127: actions.EventHandlerImpl;
    evh_4125470329773403_click: actions.EventHandlerGroup;
    act_3785918277944093_emit_5134: actions.EmitAction;
    evh_3785918277944093_success_5133: actions.EventHandlerImpl;
    act_3785918277944093_request_5132: actions.RequestAction;
    evh_3785918277944093_click_5131: actions.EventHandlerImpl;
    evh_3785918277944093_click: actions.EventHandlerGroup;
    act_2191919750581405_emit_5138: actions.EmitAction;
    evh_2191919750581405_success_5137: actions.EventHandlerImpl;
    act_2191919750581405_request_5136: actions.RequestAction;
    evh_2191919750581405_click_5135: actions.EventHandlerImpl;
    evh_2191919750581405_click: actions.EventHandlerGroup;
    act_3699289115122175_emit_5140: actions.EmitAction;
    evh_3699289115122175_success_5139: actions.EventHandlerImpl;
    evh_3699289115122175_success: actions.EventHandlerGroup;
    act_5417874158095965_emit_5142: actions.EmitAction;
    evh_5417874158095965_success_5141: actions.EventHandlerImpl;
    evh_5417874158095965_success: actions.EventHandlerGroup;
    act_8518465037810560_showModal_5144: actions.ShowModalAction;
    evh_8518465037810560_clickPreventStop_5143: actions.EventHandlerImpl;
    evh_8518465037810560_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8680084583475136_emit_5112 = new actions.EmitAction(
      {
        actionArgs: this.act_8680084583475136_emit_5112_getActionArgs,
        events: [],
      }
    );
    const evh_8680084583475136_success_5111 = new actions.EventHandlerImpl(
      {
        action: act_8680084583475136_emit_5112,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_8680084583475136_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_8680084583475136_success_5111],
      }
    );
    const act_3282321863127091_emit_5114 = new actions.EmitAction(
      {
        actionArgs: this.act_3282321863127091_emit_5114_getActionArgs,
        events: [],
      }
    );
    const evh_3282321863127091_success_5113 = new actions.EventHandlerImpl(
      {
        action: act_3282321863127091_emit_5114,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3282321863127091_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3282321863127091_success_5113],
      }
    );
    const act_2386378572842701_emit_5118 = new actions.EmitAction(
      {
        actionArgs: this.act_2386378572842701_emit_5118_getActionArgs,
        events: [],
      }
    );
    const evh_2386378572842701_close_5117 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_emit_5118,
        event: "close",
        when: this.evh_2386378572842701_close_5117_getWhen,
        displayName: "emit",
      }
    );
    const act_2386378572842701_showModal_5116 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2386378572842701_showModal_5116_getActionArgs,
        events: [evh_2386378572842701_close_5117],
      }
    );
    const evh_2386378572842701_clickPreventStop_5115 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_showModal_5116,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2386378572842701_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2386378572842701_clickPreventStop_5115],
      }
    );
    const act_6305342467955186_emit_5120 = new actions.EmitAction(
      {
        actionArgs: this.act_6305342467955186_emit_5120_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_6305342467955186_clickPreventStop_5119 = new actions.EventHandlerImpl(
      {
        action: act_6305342467955186_emit_5120,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6305342467955186_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6305342467955186_clickPreventStop_5119],
      }
    );
    const act_6493473528837603_showModal_5122 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6493473528837603_showModal_5122_getActionArgs,
        events: [],
      }
    );
    const evh_6493473528837603_clickPreventStop_5121 = new actions.EventHandlerImpl(
      {
        action: act_6493473528837603_showModal_5122,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6493473528837603_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6493473528837603_clickPreventStop_5121],
      }
    );
    const act_5796712150329752_emit_5126 = new actions.EmitAction(
      {
        actionArgs: this.act_5796712150329752_emit_5126_getActionArgs,
        events: [],
      }
    );
    const evh_5796712150329752_close_5125 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_emit_5126,
        event: "close",
        when: this.evh_5796712150329752_close_5125_getWhen,
        displayName: "emit",
      }
    );
    const act_5796712150329752_showModal_5124 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5796712150329752_showModal_5124_getActionArgs,
        displayName: "registerInvoice",
        events: [evh_5796712150329752_close_5125],
      }
    );
    const evh_5796712150329752_click_5123 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_showModal_5124,
        event: "click",
        displayName: "registerInvoice",
      }
    );
    const evh_5796712150329752_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5796712150329752_click_5123],
      }
    );
    const act_4125470329773403_emit_5130 = new actions.EmitAction(
      {
        actionArgs: this.act_4125470329773403_emit_5130_getActionArgs,
        events: [],
      }
    );
    const evh_4125470329773403_success_5129 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_emit_5130,
        event: "success",
        displayName: "emit",
      }
    );
    const act_4125470329773403_request_5128 = new actions.RequestAction(
      {
        actionArgs: this.act_4125470329773403_request_5128_getActionArgs,
        displayName: "createInvoicecopy",
        events: [evh_4125470329773403_success_5129],
      }
    );
    const evh_4125470329773403_click_5127 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_request_5128,
        event: "click",
        displayName: "createInvoicecopy",
      }
    );
    const evh_4125470329773403_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4125470329773403_click_5127],
      }
    );
    const act_3785918277944093_emit_5134 = new actions.EmitAction(
      {
        actionArgs: this.act_3785918277944093_emit_5134_getActionArgs,
        events: [],
      }
    );
    const evh_3785918277944093_success_5133 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_emit_5134,
        event: "success",
        displayName: "emit",
      }
    );
    const act_3785918277944093_request_5132 = new actions.RequestAction(
      {
        actionArgs: this.act_3785918277944093_request_5132_getActionArgs,
        displayName: "createStornoInvoice",
        events: [evh_3785918277944093_success_5133],
      }
    );
    const evh_3785918277944093_click_5131 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_request_5132,
        event: "click",
        displayName: "createStornoInvoice",
      }
    );
    const evh_3785918277944093_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3785918277944093_click_5131],
      }
    );
    const act_2191919750581405_emit_5138 = new actions.EmitAction(
      {
        actionArgs: this.act_2191919750581405_emit_5138_getActionArgs,
        events: [],
      }
    );
    const evh_2191919750581405_success_5137 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_emit_5138,
        event: "success",
        displayName: "emit",
      }
    );
    const act_2191919750581405_request_5136 = new actions.RequestAction(
      {
        actionArgs: this.act_2191919750581405_request_5136_getActionArgs,
        displayName: "createCorrectorIvoice",
        events: [evh_2191919750581405_success_5137],
      }
    );
    const evh_2191919750581405_click_5135 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_request_5136,
        event: "click",
        displayName: "createCorrectorIvoice",
      }
    );
    const evh_2191919750581405_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2191919750581405_click_5135],
      }
    );
    const act_3699289115122175_emit_5140 = new actions.EmitAction(
      {
        actionArgs: this.act_3699289115122175_emit_5140_getActionArgs,
        events: [],
      }
    );
    const evh_3699289115122175_success_5139 = new actions.EventHandlerImpl(
      {
        action: act_3699289115122175_emit_5140,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3699289115122175_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3699289115122175_success_5139],
      }
    );
    const act_5417874158095965_emit_5142 = new actions.EmitAction(
      {
        actionArgs: this.act_5417874158095965_emit_5142_getActionArgs,
        events: [],
      }
    );
    const evh_5417874158095965_success_5141 = new actions.EventHandlerImpl(
      {
        action: act_5417874158095965_emit_5142,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_5417874158095965_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_5417874158095965_success_5141],
      }
    );
    const act_8518465037810560_showModal_5144 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8518465037810560_showModal_5144_getActionArgs,
        events: [],
      }
    );
    const evh_8518465037810560_clickPreventStop_5143 = new actions.EventHandlerImpl(
      {
        action: act_8518465037810560_showModal_5144,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8518465037810560_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8518465037810560_clickPreventStop_5143],
      }
    );
    return {
      act_8680084583475136_emit_5112,
      evh_8680084583475136_success_5111,
      evh_8680084583475136_success,
      act_3282321863127091_emit_5114,
      evh_3282321863127091_success_5113,
      evh_3282321863127091_success,
      act_2386378572842701_emit_5118,
      evh_2386378572842701_close_5117,
      act_2386378572842701_showModal_5116,
      evh_2386378572842701_clickPreventStop_5115,
      evh_2386378572842701_clickPreventStop,
      act_6305342467955186_emit_5120,
      evh_6305342467955186_clickPreventStop_5119,
      evh_6305342467955186_clickPreventStop,
      act_6493473528837603_showModal_5122,
      evh_6493473528837603_clickPreventStop_5121,
      evh_6493473528837603_clickPreventStop,
      act_5796712150329752_emit_5126,
      evh_5796712150329752_close_5125,
      act_5796712150329752_showModal_5124,
      evh_5796712150329752_click_5123,
      evh_5796712150329752_click,
      act_4125470329773403_emit_5130,
      evh_4125470329773403_success_5129,
      act_4125470329773403_request_5128,
      evh_4125470329773403_click_5127,
      evh_4125470329773403_click,
      act_3785918277944093_emit_5134,
      evh_3785918277944093_success_5133,
      act_3785918277944093_request_5132,
      evh_3785918277944093_click_5131,
      evh_3785918277944093_click,
      act_2191919750581405_emit_5138,
      evh_2191919750581405_success_5137,
      act_2191919750581405_request_5136,
      evh_2191919750581405_click_5135,
      evh_2191919750581405_click,
      act_3699289115122175_emit_5140,
      evh_3699289115122175_success_5139,
      evh_3699289115122175_success,
      act_5417874158095965_emit_5142,
      evh_5417874158095965_success_5141,
      evh_5417874158095965_success,
      act_8518465037810560_showModal_5144,
      evh_8518465037810560_clickPreventStop_5143,
      evh_8518465037810560_clickPreventStop,
      reload: act_6305342467955186_emit_5120,
      registerInvoice: act_5796712150329752_showModal_5124,
      createInvoicecopy: act_4125470329773403_request_5128,
      createStornoInvoice: act_3785918277944093_request_5132,
      createCorrectorIvoice: act_2191919750581405_request_5136,
    }
  }
}

Vue.component("UcInvoiceHeader", UcInvoiceHeader);

</script>