"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "claims",
    path: "/claims",
    component: "Claims"
});
let Claims = class Claims extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
    }
    invoiceTypeBadgeClass(...args) {
        const [row] = args;
        const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
        return `bg-${cc.bg} text-${cc.text}`;
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_b_2248226175642056_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    field: "invoice_number",
                    name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
                    type: "command",
                    formatter: [{
                            name: "link",
                            href: "#",
                            className: "command-link",
                        },
                        {
                            name: "cellclass",
                            value: (row) => ({
                                'font-weight-bold': true,
                                'bg-yellow-200': !row.locked,
                                'bg-yellow-500 text-yellow-800': row.is_corrected,
                                'bg-red-300 text-red-800': row.is_reversed,
                            }),
                        },
                    ],
                },
                {
                    field: "invoice_type",
                    name: this.$fn.pgettext("InvoicesView", "Type"),
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "gettext",
                                context: "InvoiceType",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                dot: true,
                                pill: true,
                                badgeClass: this.invoiceTypeBadgeClass,
                            },
                        ],
                    },
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("Claims", "Client"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "dunning_letters",
                    name: this.$fn.pgettext("Claims", "Dunning letters"),
                    flags: {
                        preventCellActivate: true,
                    },
                    type: "command",
                    formatter: {
                        name: "dunningLetterBadges",
                    },
                },
                {
                    field: "uncollectible",
                    name: this.$fn.pgettext("Claims", "Uncollectible"),
                    formatter: {
                        name: "bool",
                        trueValue: this.$fn.gettext("Yes"),
                    },
                },
                {
                    field: "days_past_due",
                    name: this.$fn.pgettext("Claims", "Days past due"),
                    formatter: {
                        name: "overdueHighlight",
                    },
                },
                {
                    field: "amount",
                    name: this.$fn.pgettext("Claims", "Amount"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "debt",
                    name: this.$fn.pgettext("Claims", "Claim"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "paid",
                    name: this.$fn.pgettext("Claims", "Paid"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("InvoicesView", "Currency"),
                },
                {
                    field: "invoice_date",
                    name: this.$fn.pgettext("InvoicesView", "Invoice date"),
                },
                {
                    field: "fulfillment_date",
                    name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
                },
                {
                    field: "due_date",
                    name: this.$fn.pgettext("InvoicesView", "Due date"),
                },
                {
                    field: "payment_method",
                    name: this.$fn.pgettext("InvoicesView", "Payment method"),
                    formatter: {
                        name: "gettext",
                        context: "PaymentMethod",
                        conversion: "Sentence",
                        filterInput: true,
                    },
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Claims", "Claims"),
                icon: this.$config.claim.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2250_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_2252_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_showModal_2254_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "DunningLetter",
                props: {
                    id: $event.data.element.dataset.value,
                },
            };
        });
    }
    evh_2248226175642056_command_2253_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'dunning_letters';
        });
    }
    act_2248226175642056_showModal_2256_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Invoice",
                props: {
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_2248226175642056_command_2255_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'invoice_number';
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_showModal_2258_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateDunningLetter",
                props: {
                    items: this.selectedItems,
                },
            };
        });
    }
    evh_6466705138206980_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2250 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2250_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2249 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2250,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2249],
        });
        const act_2248226175642056_setData_2252 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_2252_getActionArgs,
            displayName: "setSelectedItems",
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_2251 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_2252,
            event: "selectedRowsChanged",
            displayName: "setSelectedItems",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_2251],
        });
        const act_2248226175642056_showModal_2254 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2254_getActionArgs,
            displayName: "showDunningLetter",
            events: [],
        });
        const evh_2248226175642056_command_2253 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2254,
            event: "command",
            when: this.evh_2248226175642056_command_2253_getWhen,
            displayName: "showDunningLetter",
        });
        const act_2248226175642056_showModal_2256 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2256_getActionArgs,
            displayName: "showInvoiceModal",
            events: [],
        });
        const evh_2248226175642056_command_2255 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2256,
            event: "command",
            when: this.evh_2248226175642056_command_2255_getWhen,
            displayName: "showInvoiceModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_2253, evh_2248226175642056_command_2255],
        });
        const act_6466705138206980_showModal_2258 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6466705138206980_showModal_2258_getActionArgs,
            displayName: "showCreateDunningLetter",
            events: [],
        });
        const evh_6466705138206980_click_2257 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_showModal_2258,
            event: "click",
            displayName: "showCreateDunningLetter",
        });
        const evh_6466705138206980_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_click_2257],
        });
        return {
            act_7315092382398562_closeModal_2250,
            evh_7315092382398562_close_2249,
            evh_7315092382398562_close,
            act_2248226175642056_setData_2252,
            evh_2248226175642056_selectedRowsChanged_2251,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_showModal_2254,
            evh_2248226175642056_command_2253,
            act_2248226175642056_showModal_2256,
            evh_2248226175642056_command_2255,
            evh_2248226175642056_command,
            act_6466705138206980_showModal_2258,
            evh_6466705138206980_click_2257,
            evh_6466705138206980_click,
            setSelectedItems: act_2248226175642056_setData_2252,
            showDunningLetter: act_2248226175642056_showModal_2254,
            showInvoiceModal: act_2248226175642056_showModal_2256,
            showCreateDunningLetter: act_6466705138206980_showModal_2258,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Claims.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Claims.prototype, "onSize", null);
Claims = __decorate([
    (0, vue_property_decorator_1.Component)()
], Claims);
exports.default = Claims;
vue_property_decorator_1.Vue.component("Claims", Claims);
