"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let Address = class Address extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['resource', 'editor', 'addressTypes', 'createTitle', 'editTitle', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onParent(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("parent");
    }
    onItem(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_resource() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item || this.$fn.schemaDefaults('Create' + _.upperFirst(this.parent.object) + 'AddressCommand');
        });
    }
    $$load_editor() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.resource.editor();
        });
    }
    $$load_addressTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('AddressType');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            resource: null,
            editor: null,
            addressTypes: null,
            createTitle: this.$fn.pgettext("Addresses", "Add address"),
            editTitle: this.$fn.pgettext("Addresses", "Edit address"),
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.resource.id == null ? this.createTitle : this.editTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1396_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeComponent_1402_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_closeComponent_1404_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: "deleted",
                },
            };
        });
    }
    act_1040167445267876_rest_1400_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.parent.addresses,
                resource: this.editor,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1396 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1396_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1395 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1396,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1395],
        });
        const act_2248226175642056_closeComponent_1398 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_1397 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_1398,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1397],
        });
        const act_1040167445267876_closeComponent_1402 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_1402_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_1401 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_1402,
            event: "saved",
            displayName: "closeComponent",
        });
        const act_1040167445267876_closeComponent_1404 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_1404_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_deleted_1403 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_1404,
            event: "deleted",
            displayName: "closeComponent",
        });
        const act_1040167445267876_rest_1400 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_1400_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_saved_1401, evh_1040167445267876_deleted_1403],
        });
        const evh_1040167445267876_submit_1399 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_1400,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_1399],
        });
        const act_1419464017721962_closeComponent_1406 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_1405 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_1406,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_1405],
        });
        return {
            act_7315092382398562_closeModal_1396,
            evh_7315092382398562_close_1395,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_1398,
            evh_2248226175642056_close_1397,
            evh_2248226175642056_close,
            act_1040167445267876_closeComponent_1402,
            evh_1040167445267876_saved_1401,
            act_1040167445267876_closeComponent_1404,
            evh_1040167445267876_deleted_1403,
            act_1040167445267876_rest_1400,
            evh_1040167445267876_submit_1399,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_1406,
            evh_1419464017721962_clickPreventStop_1405,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_rest_1400,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], Address.prototype, "parent", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Address.prototype, "onParent", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], Address.prototype, "item", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Address.prototype, "onItem", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Address.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Address.prototype, "onSize", null);
Address = __decorate([
    (0, vue_property_decorator_1.Component)()
], Address);
exports.default = Address;
vue_property_decorator_1.Vue.component("Address", Address);
