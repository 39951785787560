"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "client",
    path: "/clients/:id",
    component: "Client",
    params: [{
            name: "id",
            prop: "oid",
        },
    ]
});
let Client = class Client extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onForm_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_tabManager() {
        return __awaiter(this, void 0, void 0, function* () {
            return new this.$app.utils.LazyTabManager();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_client', { parameters: { id: this.oid }, asResource: true });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            tabManager: null,
            item: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_modalBindings: {
                noHeader: true,
                contentClass: "p-0",
                noPrimaryButton: true,
                noCloseButton: true,
                size: "full-screen",
            },
            b_4615685607825208_to: {
                name: "app.clients",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.oid == null ? this.$fn.gettext("Client") : `${this.item.snapshot("name")}`,
                icon: this.$config.client.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1650_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_6705964765214521_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6705964765214521_success.executeFromDOM(this, event, scope);
        });
    }
    evh_5477420070495986_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_6337614691287098_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6337614691287098_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_2406129194387753_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2406129194387753_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_4071170783051919_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4071170783051919_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_7713630524832656_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7713630524832656_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1650 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1650_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1649 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1650,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1649],
        });
        const act_2248226175642056_closeModal_1652 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_1651 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_1652,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1651],
        });
        const act_8320016629450276_reloadComponentData_1654 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_8320016629450276_clickPreventStop_1653 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadComponentData_1654,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_1653],
        });
        const act_6705964765214521_reloadComponentData_1656 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6705964765214521_success_1655 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_reloadComponentData_1656,
            event: "success",
            displayName: "reloadComponentData",
        });
        const evh_6705964765214521_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6705964765214521_success_1655],
        });
        const act_5477420070495986_reloadComponentData_1658 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5477420070495986_reload_1657 = new core_1.actions.EventHandlerImpl({
            action: act_5477420070495986_reloadComponentData_1658,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5477420070495986_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5477420070495986_reload_1657],
        });
        const act_6337614691287098_reloadComponentData_1660 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6337614691287098_reload_1659 = new core_1.actions.EventHandlerImpl({
            action: act_6337614691287098_reloadComponentData_1660,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_6337614691287098_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6337614691287098_reload_1659],
        });
        const act_2406129194387753_reloadComponentData_1662 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2406129194387753_reload_1661 = new core_1.actions.EventHandlerImpl({
            action: act_2406129194387753_reloadComponentData_1662,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_2406129194387753_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2406129194387753_reload_1661],
        });
        const act_4071170783051919_reloadComponentData_1664 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_4071170783051919_reload_1663 = new core_1.actions.EventHandlerImpl({
            action: act_4071170783051919_reloadComponentData_1664,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_4071170783051919_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4071170783051919_reload_1663],
        });
        const act_7713630524832656_reloadComponentData_1666 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7713630524832656_reload_1665 = new core_1.actions.EventHandlerImpl({
            action: act_7713630524832656_reloadComponentData_1666,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7713630524832656_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7713630524832656_reload_1665],
        });
        return {
            act_7315092382398562_closeModal_1650,
            evh_7315092382398562_close_1649,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_1652,
            evh_2248226175642056_close_1651,
            evh_2248226175642056_close,
            act_8320016629450276_reloadComponentData_1654,
            evh_8320016629450276_clickPreventStop_1653,
            evh_8320016629450276_clickPreventStop,
            act_6705964765214521_reloadComponentData_1656,
            evh_6705964765214521_success_1655,
            evh_6705964765214521_success,
            act_5477420070495986_reloadComponentData_1658,
            evh_5477420070495986_reload_1657,
            evh_5477420070495986_reload,
            act_6337614691287098_reloadComponentData_1660,
            evh_6337614691287098_reload_1659,
            evh_6337614691287098_reload,
            act_2406129194387753_reloadComponentData_1662,
            evh_2406129194387753_reload_1661,
            evh_2406129194387753_reload,
            act_4071170783051919_reloadComponentData_1664,
            evh_4071170783051919_reload_1663,
            evh_4071170783051919_reload,
            act_7713630524832656_reloadComponentData_1666,
            evh_7713630524832656_reload_1665,
            evh_7713630524832656_reload,
            reload: act_8320016629450276_reloadComponentData_1654,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Client.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Client.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: function () {
            return this.$fn.randomElementId();
        },
    }),
    __metadata("design:type", String)
], Client.prototype, "formId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Client.prototype, "onForm_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], Client.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Client.prototype, "onSize", null);
Client = __decorate([
    (0, vue_property_decorator_1.Component)()
], Client);
exports.default = Client;
vue_property_decorator_1.Vue.component("Client", Client);
