var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoicesView",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "VueSlickgrid",
            {
              ref: "grid",
              staticClass: "page--grid",
              attrs: {
                name: "invoices",
                headerTitle: _vm.titles[_vm.kind],
                items: _vm.getItems,
                headerShowReloadButton:
                  _vm.b_2248226175642056_headerShowReloadButton,
                "checkbox-row-selection": "",
                "footer-totals": "",
                "export-to-csv": "",
                exportToCsvFilename: _vm._f("pgettext")(
                  "invoices",
                  "ExportFilename"
                ),
                columnDefaults: _vm.b_2248226175642056_columnDefaults,
                columns: _vm.b_2248226175642056_columns
              },
              on: {
                selectedRowsChanged: function($event) {
                  return _vm.evh_2248226175642056_selectedRowsChanged(
                    $event,
                    {}
                  )
                },
                command: function($event) {
                  return _vm.evh_2248226175642056_command($event, {})
                }
              }
            },
            [
              _c(
                "DIV",
                {
                  staticClass: "flex-grow-1 d-flex flex-wrap",
                  attrs: { slot: "toolbar" },
                  slot: "toolbar"
                },
                [
                  _c("ItpButton", {
                    staticClass: "mx-2 my-1",
                    attrs: {
                      icon: "fa-plus",
                      text: _vm._f("pgettext")(
                        "New Invoice...",
                        "InvoicesView"
                      ),
                      size: "sm",
                      variant: "light"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_6466705138206980_click($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.kind === "incoming"
                    ? _c("ItpButton", {
                        staticClass: "mx-2 my-1",
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Import Invoices...",
                            "InvoicesView"
                          ),
                          icon: _vm.$config.uploadIcon,
                          variant: "light"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_8320016629450276_clickPrevent(
                              $event,
                              {}
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("JobHandlerControl", {
                    staticClass: "mx-2 my-1",
                    attrs: {
                      jobType: "invoice_print",
                      "load-current": "",
                      text: _vm._f("pgettext")(
                        "Print invoices...",
                        "InvoicesView"
                      ),
                      icon: "icon-park-outline:printer-one",
                      payload: function() {
                        return _vm.onPrintInvoicesPayload()
                      },
                      disabled: !_vm.selectedItems || !_vm.selectedItems.length
                    }
                  }),
                  _vm._v(" "),
                  _c("InvoicesViewFilters", {
                    attrs: { kind: _vm.kind },
                    on: {
                      submit: function($event) {
                        return _vm.evh_6806515264385235_submit($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("PageHelp", { attrs: { path: "/finance/invoices.html" } })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }