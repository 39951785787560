<template>
<ItpPage
  :noHeader="b_7315092382398562_noHeader"
  no-primary-button
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="EntityEvents"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    class="h-100"
    v-if="loaded"
  >
    <DIV
      class="row h-100"
    >
      <DIV
        class="col-6 h-100"
      >
        <VueSlickgrid
          name="events"
          :headerTitle="'Events'|pgettext('EntityEvents')"
          :items="$fn.slickDataSource('list_entity_events', {parameters: {id: entityId}})"
          checkbox-row-selection
          :headerShowColumnSelectorButton="b_7789867021953041_headerShowColumnSelectorButton"
          :headerShowReloadButton="b_7789867021953041_headerShowReloadButton"
          export-to-csv
          :exportToCsvFilename="'payments'|pgettext('ExportFilename')"
          :columns="b_7789867021953041_columns"
          @command:event_name="evh_7789867021953041_commandEventName($event, {})"
        >
        </VueSlickgrid>
      </DIV>
      <DIV
        class="col-6 h-100 overflow-hidden"
      >
        <CodeMirror
          mode="json"
          :options="b_2070266589801014_options"
          v-model="eventData"
          class="h-100 v-100"
        >
        </CodeMirror>
      </DIV>
    </DIV>
  </UcBody>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class EntityEvents extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  entityId!: string;

  @Watch('entityId')
  onEntity_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("entityId")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedEventId!: any;
  eventData!: any;
  ux!: any;
  b_7315092382398562_noHeader!: any;
  b_7315092382398562_modalBindings!: any;
  b_7789867021953041_headerShowColumnSelectorButton!: any;
  b_7789867021953041_headerShowReloadButton!: any;
  b_7789867021953041_columns!: any;
  b_2070266589801014_options!: any;
  dataMembers = ['selectedEventId', 'eventData', 'ux', 'b_7315092382398562_noHeader', 'b_7315092382398562_modalBindings', 'b_7789867021953041_headerShowColumnSelectorButton', 'b_7789867021953041_headerShowReloadButton', 'b_7789867021953041_columns', 'b_2070266589801014_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedEventId: null,
        eventData: null,
        ux: null,
        b_7315092382398562_noHeader: false,
        b_7315092382398562_modalBindings: {
          noHeader: false,
          noPrimaryButton: true,
          size: "full-screen",
        }
        ,
        b_7789867021953041_headerShowColumnSelectorButton: false,
        b_7789867021953041_headerShowReloadButton: false,
        b_7789867021953041_columns: [{
          field: "event_name",
          name: this.$fn.pgettext("EntityEvents", "Event"),
          type: "command",
        }
          ,
        {
          field: "timestamp",
          name: this.$fn.pgettext("EntityEvents", "Timestamp"),
          formatter: {
            name: "datetime",
          }
          ,
        }
          ,
        {
          field: "identity",
          name: this.$fn.pgettext("EntityEvents", "User"),
          formatter: {
            name: "user",
          }
          ,
        }
          ,
        ]
        ,
        b_2070266589801014_options: {
          lineNumbers: true,
          matchBrackets: true,
          continueComments: "Enter",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("EntityEvents/header", "Entity events"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1838_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1838, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_setData_1842_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7789867021953041_setData_1842, alias=undefined
    return {
      path: "selectedEventId",
      value: $event.data.data.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_7789867021953041_setData_1846_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7789867021953041_setData_1846, alias=undefined
    return {
      path: "eventData",
      value: JSON.stringify($event.data.response.data, null, 2),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_7789867021953041_request_1844_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7789867021953041_request_1844, alias=loadEventData
    return {
      operation: "read_entity_event_data",
      data: { id: this.entityId, event_id: this.selectedEventId },
    }
  }

  async evh_7789867021953041_commandEventName(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_commandEventName.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1838: actions.CloseModalAction;
    evh_7315092382398562_close_1837: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1840: actions.CloseModalAction;
    evh_2248226175642056_close_1839: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_7789867021953041_setData_1842: actions.SetDataAction;
    evh_7789867021953041_commandEventName_1841: actions.EventHandlerImpl;
    act_7789867021953041_setData_1846: actions.SetDataAction;
    evh_7789867021953041_success_1845: actions.EventHandlerImpl;
    act_7789867021953041_request_1844: actions.RequestAction;
    evh_7789867021953041_commandEventName_1843: actions.EventHandlerImpl;
    evh_7789867021953041_commandEventName: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1838 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1838_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1837 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1838,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1837],
      }
    );
    const act_2248226175642056_closeModal_1840 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1839 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1840,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1839],
      }
    );
    const act_7789867021953041_setData_1842 = new actions.SetDataAction(
      {
        actionArgs: this.act_7789867021953041_setData_1842_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_commandEventName_1841 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_setData_1842,
        event: "command:event_name",
        displayName: "setData",
      }
    );
    const act_7789867021953041_setData_1846 = new actions.SetDataAction(
      {
        actionArgs: this.act_7789867021953041_setData_1846_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_success_1845 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_setData_1846,
        event: "success",
        displayName: "setData",
      }
    );
    const act_7789867021953041_request_1844 = new actions.RequestAction(
      {
        actionArgs: this.act_7789867021953041_request_1844_getActionArgs,
        displayName: "loadEventData",
        events: [evh_7789867021953041_success_1845],
      }
    );
    const evh_7789867021953041_commandEventName_1843 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_request_1844,
        event: "command:event_name",
        displayName: "loadEventData",
      }
    );
    const evh_7789867021953041_commandEventName = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_commandEventName_1841, evh_7789867021953041_commandEventName_1843],
      }
    );
    return {
      act_7315092382398562_closeModal_1838,
      evh_7315092382398562_close_1837,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1840,
      evh_2248226175642056_close_1839,
      evh_2248226175642056_close,
      act_7789867021953041_setData_1842,
      evh_7789867021953041_commandEventName_1841,
      act_7789867021953041_setData_1846,
      evh_7789867021953041_success_1845,
      act_7789867021953041_request_1844,
      evh_7789867021953041_commandEventName_1843,
      evh_7789867021953041_commandEventName,
      loadEventData: act_7789867021953041_request_1844,
    }
  }
}

Vue.component("EntityEvents", EntityEvents);

</script>