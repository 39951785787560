<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="HandoverProtocol"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcContractProtocolHeader
      :protocol="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcContractProtocolHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      :name="formName"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetHandoverProtocol
        :item="item"
        :form="forms[formName]"
        @reload="evh_5534025912102772_reload($event, {})"
        @close="evh_5534025912102772_close($event, {})"
        @changed="evh_5534025912102772_changed($event, {})"
      >
      </FieldsetHandoverProtocol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="formId"
      variant="warning"
      icon="fa-times"
      :text="'Cancel protocol'|pgettext('HandoverProtocol')"
      :spinning="actions.cancel.isRunning"
      v-if="loaded && !item.isNew && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :form="formId"
      variant="success"
      icon="fa-save"
      :text="'Complete'|gettext"
      :spinning="actions.complete.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      class="ml-auto"
      v-if="loaded && !item.isNew && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :text="'Save'|gettext"
      :spinning="actions.save.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      v-if="loaded && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="default"
      :text="'Close'|gettext"
      :disabled="actions.save.isRunning"
      class="ml-3"
      @click.prevent.stop="evh_5566617329548203_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class HandoverProtocol extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
  })
  contractId!: string;

  @Watch('contractId')
  onContract_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contractId")
  }

  @Prop({
    type: String,
  })
  revisionId!: string;

  @Watch('revisionId')
  onRevision_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("revisionId")
  }

  @Prop({
    type: String,
  })
  handoverType!: string;

  @Watch('handoverType')
  onHandover_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("handoverType")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.oid ?
      this.$fn.fetch('read_contract_protocol', {
        asResource: true,
        parameters: { id: this.oid }
      }) :
      this.$fn.fetch('get_contract_protocol_template', {
        asResource: true,
        parameters: {
          contract_id: this.contractId,
          revision_id: this.revisionId,
          handover_type: this.handoverType,
        }
      })
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  handoverTypes!: any;

  async $$load_handoverTypes() {
    return this.$fn.getEnumValuesFromSchema('HandoverType')
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['item', 'formName', 'formId', 'labelAlign', 'handoverTypes', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formName: "handoverProtocol",
        formId: null,
        labelAlign: "left",
        handoverTypes: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.$fn.pgettext("Contract", "Handover protocol")
          ,
        this.$fn.pgettext("HandoverType", this.item.handover_type, "Sentence")
          ,
        ]
        ,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6282_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6282, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_closeModal_6288_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_8564662037462133_closeModal_6288, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_script_6292_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1040167445267876_script_6292, alias=undefined
    this.item.reset($event.data)
  }

  async act_1040167445267876_script_6292_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1040167445267876_script_6292, alias=undefined
    return {
      value: () => this.act_1040167445267876_script_6292_getActionArgs_value($event),
    }
  }

  async evh_1040167445267876_success_6291_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_6291, alias=undefined
    return $event.data
  }

  async act_1040167445267876_crud_6290_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_6290, alias=save
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_emit_6298_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5534025912102772_emit_6298, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async evh_5534025912102772_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_changed.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeComponent_6302_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1548630417156826_closeComponent_6302, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1548630417156826_crud_6300_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1548630417156826_crud_6300, alias=cancel
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: { ...this.item, protocol_status: 'cancelled' },
    }
  }

  async act_1548630417156826_crud_6300_getConfirm($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1548630417156826_crud_6300, alias=cancel
    return {
      header: "Jegyőkönyv érvénytelenítése",
      message: "A jegyzőkönyv érvénytelenítés után már nem módosítható.",
      btnOk: "Érvénytelenítés",
      severity: "warning",
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeComponent_6306_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1419464017721962_closeComponent_6306, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1419464017721962_crud_6304_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1419464017721962_crud_6304, alias=complete
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: { ...this.item, protocol_status: 'completed' },
    }
  }

  async act_1419464017721962_crud_6304_getConfirm($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1419464017721962_crud_6304, alias=complete
    return {
      header: "Jegyőkönyv véglegesítése",
      message: "A jegyzőkönyv véglegesítés után már nem módosítható.",
      btnOk: "Véglegesítés",
      severity: "warning",
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2186679009640457_script_6310_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2186679009640457_script_6310, alias=undefined
    this.item.reset($event.data)
  }

  async act_2186679009640457_script_6310_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2186679009640457_script_6310, alias=undefined
    return {
      value: () => this.act_2186679009640457_script_6310_getActionArgs_value($event),
    }
  }

  async evh_2186679009640457_success_6309_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2186679009640457_success_6309, alias=undefined
    return $event.data
  }

  async act_2186679009640457_crud_6308_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_2186679009640457_crud_6308, alias=save
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5566617329548203_closeComponent_6312_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_5566617329548203_closeComponent_6312, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_5566617329548203_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6282: actions.CloseModalAction;
    evh_7315092382398562_close_6281: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_6284: actions.CloseModalAction;
    evh_2248226175642056_close_6283: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_6286: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_6285: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_6288: actions.CloseModalAction;
    evh_8564662037462133_deleted_6287: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_script_6292: actions.ScriptAction;
    evh_1040167445267876_success_6291: actions.EventHandlerImpl;
    act_1040167445267876_crud_6290: actions.CRUDAction;
    evh_1040167445267876_submit_6289: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_5534025912102772_reloadComponentData_6294: actions.ReloadComponentDataAction;
    evh_5534025912102772_reload_6293: actions.EventHandlerImpl;
    evh_5534025912102772_reload: actions.EventHandlerGroup;
    act_5534025912102772_closeComponent_6296: actions.CloseComponentAction;
    evh_5534025912102772_close_6295: actions.EventHandlerImpl;
    evh_5534025912102772_close: actions.EventHandlerGroup;
    act_5534025912102772_emit_6298: actions.EmitAction;
    evh_5534025912102772_changed_6297: actions.EventHandlerImpl;
    evh_5534025912102772_changed: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_6302: actions.CloseComponentAction;
    evh_1548630417156826_success_6301: actions.EventHandlerImpl;
    act_1548630417156826_crud_6300: actions.CRUDAction;
    evh_1548630417156826_clickPreventStop_6299: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_6306: actions.CloseComponentAction;
    evh_1419464017721962_success_6305: actions.EventHandlerImpl;
    act_1419464017721962_crud_6304: actions.CRUDAction;
    evh_1419464017721962_clickPreventStop_6303: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
    act_2186679009640457_script_6310: actions.ScriptAction;
    evh_2186679009640457_success_6309: actions.EventHandlerImpl;
    act_2186679009640457_crud_6308: actions.CRUDAction;
    evh_2186679009640457_clickPreventStop_6307: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
    act_5566617329548203_closeComponent_6312: actions.CloseComponentAction;
    evh_5566617329548203_clickPreventStop_6311: actions.EventHandlerImpl;
    evh_5566617329548203_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6282 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6282_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6281 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6282,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6281],
      }
    );
    const act_2248226175642056_closeModal_6284 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6283 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_6284,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6283],
      }
    );
    const act_8564662037462133_reloadComponentData_6286 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_6285 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_6286,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_6285],
      }
    );
    const act_8564662037462133_closeModal_6288 = new actions.CloseModalAction(
      {
        actionArgs: this.act_8564662037462133_closeModal_6288_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_6287 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_6288,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_6287],
      }
    );
    const act_1040167445267876_script_6292 = new actions.ScriptAction(
      {
        actionArgs: this.act_1040167445267876_script_6292_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6291 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_script_6292,
        event: "success",
        when: this.evh_1040167445267876_success_6291_getWhen,
        displayName: "script",
      }
    );
    const act_1040167445267876_crud_6290 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_6290_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_6291],
      }
    );
    const evh_1040167445267876_submit_6289 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_6290,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6289],
      }
    );
    const act_5534025912102772_reloadComponentData_6294 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_reload_6293 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_reloadComponentData_6294,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5534025912102772_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_reload_6293],
      }
    );
    const act_5534025912102772_closeComponent_6296 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_close_6295 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_closeComponent_6296,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_5534025912102772_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_close_6295],
      }
    );
    const act_5534025912102772_emit_6298 = new actions.EmitAction(
      {
        actionArgs: this.act_5534025912102772_emit_6298_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_changed_6297 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_emit_6298,
        event: "changed",
        displayName: "emit",
      }
    );
    const evh_5534025912102772_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_changed_6297],
      }
    );
    const act_1548630417156826_closeComponent_6302 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1548630417156826_closeComponent_6302_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_6301 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_6302,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1548630417156826_crud_6300 = new actions.CRUDAction(
      {
        actionArgs: this.act_1548630417156826_crud_6300_getActionArgs,
        confirm: this.act_1548630417156826_crud_6300_getConfirm,
        displayName: "cancel",
        events: [evh_1548630417156826_success_6301],
      }
    );
    const evh_1548630417156826_clickPreventStop_6299 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_crud_6300,
        event: "click.prevent.stop",
        displayName: "cancel",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_6299],
      }
    );
    const act_1419464017721962_closeComponent_6306 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1419464017721962_closeComponent_6306_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_6305 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_6306,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1419464017721962_crud_6304 = new actions.CRUDAction(
      {
        actionArgs: this.act_1419464017721962_crud_6304_getActionArgs,
        confirm: this.act_1419464017721962_crud_6304_getConfirm,
        displayName: "complete",
        events: [evh_1419464017721962_success_6305],
      }
    );
    const evh_1419464017721962_clickPreventStop_6303 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_crud_6304,
        event: "click.prevent.stop",
        displayName: "complete",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_6303],
      }
    );
    const act_2186679009640457_script_6310 = new actions.ScriptAction(
      {
        actionArgs: this.act_2186679009640457_script_6310_getActionArgs,
        events: [],
      }
    );
    const evh_2186679009640457_success_6309 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_script_6310,
        event: "success",
        when: this.evh_2186679009640457_success_6309_getWhen,
        displayName: "script",
      }
    );
    const act_2186679009640457_crud_6308 = new actions.CRUDAction(
      {
        actionArgs: this.act_2186679009640457_crud_6308_getActionArgs,
        displayName: "save",
        events: [evh_2186679009640457_success_6309],
      }
    );
    const evh_2186679009640457_clickPreventStop_6307 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_crud_6308,
        event: "click.prevent.stop",
        displayName: "save",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_6307],
      }
    );
    const act_5566617329548203_closeComponent_6312 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_5566617329548203_closeComponent_6312_getActionArgs,
        events: [],
      }
    );
    const evh_5566617329548203_clickPreventStop_6311 = new actions.EventHandlerImpl(
      {
        action: act_5566617329548203_closeComponent_6312,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_5566617329548203_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5566617329548203_clickPreventStop_6311],
      }
    );
    return {
      act_7315092382398562_closeModal_6282,
      evh_7315092382398562_close_6281,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_6284,
      evh_2248226175642056_close_6283,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_6286,
      evh_8564662037462133_reload_6285,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_6288,
      evh_8564662037462133_deleted_6287,
      evh_8564662037462133_deleted,
      act_1040167445267876_script_6292,
      evh_1040167445267876_success_6291,
      act_1040167445267876_crud_6290,
      evh_1040167445267876_submit_6289,
      evh_1040167445267876_submit,
      act_5534025912102772_reloadComponentData_6294,
      evh_5534025912102772_reload_6293,
      evh_5534025912102772_reload,
      act_5534025912102772_closeComponent_6296,
      evh_5534025912102772_close_6295,
      evh_5534025912102772_close,
      act_5534025912102772_emit_6298,
      evh_5534025912102772_changed_6297,
      evh_5534025912102772_changed,
      act_1548630417156826_closeComponent_6302,
      evh_1548630417156826_success_6301,
      act_1548630417156826_crud_6300,
      evh_1548630417156826_clickPreventStop_6299,
      evh_1548630417156826_clickPreventStop,
      act_1419464017721962_closeComponent_6306,
      evh_1419464017721962_success_6305,
      act_1419464017721962_crud_6304,
      evh_1419464017721962_clickPreventStop_6303,
      evh_1419464017721962_clickPreventStop,
      act_2186679009640457_script_6310,
      evh_2186679009640457_success_6309,
      act_2186679009640457_crud_6308,
      evh_2186679009640457_clickPreventStop_6307,
      evh_2186679009640457_clickPreventStop,
      act_5566617329548203_closeComponent_6312,
      evh_5566617329548203_clickPreventStop_6311,
      evh_5566617329548203_clickPreventStop,
      save: act_2186679009640457_crud_6308,
      cancel: act_1548630417156826_crud_6300,
      complete: act_1419464017721962_crud_6304,
    }
  }
}

Vue.component("HandoverProtocol", HandoverProtocol);

</script>