"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FieldsetInvoiceHeader = class FieldsetInvoiceHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'duplicateInvoiceNumber', 'currencies', 'paymentMethods', 'expenseTypes', 'invoiceStatuses', 'incomingInvoiceStatuses', 'invoiceTypes', 'languages', 'tags', 'ux', 'b_6917646200545441_dataSource'];
    }
    beforeCreate() {
    }
    onReferenceInvoiceSelected(...args) {
        const { option, value } = args[0];
        this.selectClient({ clientId: option.client.id });
    }
    selectClient(...args) {
        const { clientId } = args[0];
        return this.$fn
            .fetch('get_invoice_party_details', { parameters: { id: clientId } })
            .then(data => this.setData('invoice.client', data))
            .catch(err => this.onError(err));
    }
    checkInvoiceNumber(...args) {
        const invoiceNumber = args[0];
        return this.$fn
            .fetch('read_invoice', { parameters: { id: invoiceNumber, client: _.get(this.invoice, 'client.id') } })
            .then(d => {
            if (d.id != this.invoice.id) {
                this.duplicateInvoiceNumber = true;
            }
            else {
                this.duplicateInvoiceNumber = false;
            }
        })
            .catch(err => {
            this.duplicateInvoiceNumber = false;
        });
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onLabel_align(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    }
    $$load_currencies() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_currency');
        });
    }
    $$load_paymentMethods() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_payment_method')
                .then(data => _.map(data, p => ({
                value: p.id,
                text: this.$fn.pgettext('PaymentMethod', p.name)
            })));
        });
    }
    $$load_expenseTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'expense-type' } });
        });
    }
    $$load_invoiceStatuses() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema(this.isIncomingInvoice ? 'IncomingInvoiceStatus' : 'OutgoingInvoiceStatus', { sort: false });
        });
    }
    $$load_incomingInvoiceStatuses() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('IncomingInvoiceStatus', { sort: false });
        });
    }
    $$load_invoiceTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('InvoiceType', { sort: false });
        });
    }
    $$load_tags() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice' } }).then(data => _.map(data, p => p.name));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                invoiceTypes: {
                    correction: this.$fn.pgettext("InvoiceType", "Correction"),
                    storno: this.$fn.pgettext("InvoiceType", "Storno"),
                    proforma: this.$fn.pgettext("InvoiceType", "Pro Forma"),
                },
                invoiceTypeHelp: {
                    normal: this.$fn.pgettext("Invoice|Reference|description", "Normal invoices can reference Pro Forma invoices."),
                    correction: this.$fn.pgettext("Invoice|Reference|description", "A correction must reference the invoice being corrected."),
                    storno: this.$fn.pgettext("Invoice|Reference|description", "The reversing invoice must reference the invoice being reversed."),
                    proforma: this.$fn.pgettext("Invoice|Reference|description", "Pro Forma invoices can reference former Pro Forma invoices."),
                },
                duplicateInvoiceNumber: this.$fn.pgettext("Invoice", "The given invoice number already exists."),
            },
            duplicateInvoiceNumber: false,
            currencies: null,
            paymentMethods: null,
            expenseTypes: null,
            invoiceStatuses: null,
            incomingInvoiceStatuses: null,
            invoiceTypes: null,
            languages: [{
                    value: "hu",
                    text: this.$fn.pgettext("Language", "hungarian"),
                },
                {
                    value: "en",
                    text: this.$fn.pgettext("Language", "english"),
                },
            ],
            tags: null,
            ux: null,
            b_6917646200545441_dataSource: {
                name: "suggest_client",
            },
        });
    }
    get isReadonly() {
        if (this.loaded) {
            return false;
        }
        return null;
    }
    get isIncomingInvoice() {
        if (this.loaded) {
            return this.invoice.invoice_kind == 'incoming';
        }
        return null;
    }
    get isReferenceInvoiceRequired() {
        if (this.loaded) {
            return ['storno', 'correction'].indexOf(this.invoice.invoice_type) > -1;
        }
        return null;
    }
    get preventChangeClient() {
        if (this.loaded) {
            return !!this.invoice.reference_invoice;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_4186377094414664_script_4980_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onReferenceInvoiceSelected($event.data);
        });
    }
    act_4186377094414664_script_4980_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_4186377094414664_script_4980_getActionArgs_value($event),
            };
        });
    }
    evh_4186377094414664_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4186377094414664_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_6917646200545441_script_4982_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.selectClient({ clientId: this.invoice.client.id });
        });
    }
    act_6917646200545441_script_4982_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_6917646200545441_script_4982_getActionArgs_value($event),
            };
        });
    }
    act_6917646200545441_script_4982_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!this.invoice.client;
        });
    }
    evh_6917646200545441_selected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6917646200545441_selected.executeFromDOM(this, event, scope);
        });
    }
    act_7384593256854782_script_4984_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.checkInvoiceNumber(this.invoice.invoice_number);
        });
    }
    act_7384593256854782_script_4984_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7384593256854782_script_4984_getActionArgs_value($event),
            };
        });
    }
    evh_7384593256854782_blur_4983_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.invoice.isValueChanged('invoice_number');
        });
    }
    evh_7384593256854782_blur(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7384593256854782_blur.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_4978 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_4977 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_4978,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_4977],
        });
        const act_4186377094414664_script_4980 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4186377094414664_script_4980_getActionArgs,
            events: [],
        });
        const evh_4186377094414664_optionSelected_4979 = new core_1.actions.EventHandlerImpl({
            action: act_4186377094414664_script_4980,
            event: "optionSelected",
            displayName: "script",
        });
        const evh_4186377094414664_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4186377094414664_optionSelected_4979],
        });
        const act_6917646200545441_script_4982 = new core_1.actions.ScriptAction({
            actionArgs: this.act_6917646200545441_script_4982_getActionArgs,
            when: this.act_6917646200545441_script_4982_getWhen,
            events: [],
        });
        const evh_6917646200545441_selected_4981 = new core_1.actions.EventHandlerImpl({
            action: act_6917646200545441_script_4982,
            event: "selected",
            displayName: "script",
        });
        const evh_6917646200545441_selected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6917646200545441_selected_4981],
        });
        const act_7384593256854782_script_4984 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7384593256854782_script_4984_getActionArgs,
            events: [],
        });
        const evh_7384593256854782_blur_4983 = new core_1.actions.EventHandlerImpl({
            action: act_7384593256854782_script_4984,
            event: "blur",
            when: this.evh_7384593256854782_blur_4983_getWhen,
            displayName: "script",
        });
        const evh_7384593256854782_blur = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7384593256854782_blur_4983],
        });
        return {
            act_7315092382398562_reloadComponentData_4978,
            evh_7315092382398562_reload_4977,
            evh_7315092382398562_reload,
            act_4186377094414664_script_4980,
            evh_4186377094414664_optionSelected_4979,
            evh_4186377094414664_optionSelected,
            act_6917646200545441_script_4982,
            evh_6917646200545441_selected_4981,
            evh_6917646200545441_selected,
            act_7384593256854782_script_4984,
            evh_7384593256854782_blur_4983,
            evh_7384593256854782_blur,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetInvoiceHeader.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetInvoiceHeader.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "right",
    }),
    __metadata("design:type", String)
], FieldsetInvoiceHeader.prototype, "labelAlign", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('labelAlign'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetInvoiceHeader.prototype, "onLabel_align", null);
FieldsetInvoiceHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], FieldsetInvoiceHeader);
exports.default = FieldsetInvoiceHeader;
vue_property_decorator_1.Vue.component("FieldsetInvoiceHeader", FieldsetInvoiceHeader);
