"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcRentalFormProperties = class UcRentalFormProperties extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux'];
    }
    beforeCreate() {
    }
    onEditable(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("editable");
    }
    onRental_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rentalId");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_rental_property', { parameters: { rental_id: this.rentalId } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: {
                initialLoadSpinner: true,
            },
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_8320016629450276_close_6987_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_8320016629450276_showModal_6986_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalProperty",
                props: {
                    rentalId: this.rentalId,
                },
            };
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_6853569216922577_close_6991_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_6853569216922577_showModal_6990_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalProperty",
                props: {
                    rentalId: this.rentalId,
                    resourceId: $event.data.item.id,
                },
            };
        });
    }
    evh_6853569216922577_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6853569216922577_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_6492569393195423_request_6994_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_rental_property",
                data: Object.assign(Object.assign({}, $event.data.prop), { rental_id: this.rentalId }),
            };
        });
    }
    act_6492569393195423_request_6994_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.gettext("Please confirm delete."),
                btnOk: this.$fn.gettext("Delete"),
            };
        });
    }
    evh_6492569393195423_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6492569393195423_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6984 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6983 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6984,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6983],
        });
        const act_8320016629450276_reloadComponentData_6988 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8320016629450276_close_6987 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadComponentData_6988,
            event: "close",
            when: this.evh_8320016629450276_close_6987_getWhen,
            displayName: "reloadComponentData",
        });
        const act_8320016629450276_showModal_6986 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_6986_getActionArgs,
            events: [evh_8320016629450276_close_6987],
        });
        const evh_8320016629450276_clickPreventStop_6985 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_6986,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_6985],
        });
        const act_6853569216922577_reloadComponentData_6992 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6853569216922577_close_6991 = new core_1.actions.EventHandlerImpl({
            action: act_6853569216922577_reloadComponentData_6992,
            event: "close",
            when: this.evh_6853569216922577_close_6991_getWhen,
            displayName: "reloadComponentData",
        });
        const act_6853569216922577_showModal_6990 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6853569216922577_showModal_6990_getActionArgs,
            events: [evh_6853569216922577_close_6991],
        });
        const evh_6853569216922577_clickPreventStop_6989 = new core_1.actions.EventHandlerImpl({
            action: act_6853569216922577_showModal_6990,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_6853569216922577_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6853569216922577_clickPreventStop_6989],
        });
        const act_6492569393195423_reloadComponentData_6996 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6492569393195423_success_6995 = new core_1.actions.EventHandlerImpl({
            action: act_6492569393195423_reloadComponentData_6996,
            event: "success",
            displayName: "reloadComponentData",
        });
        const act_6492569393195423_request_6994 = new core_1.actions.RequestAction({
            actionArgs: this.act_6492569393195423_request_6994_getActionArgs,
            confirm: this.act_6492569393195423_request_6994_getConfirm,
            events: [evh_6492569393195423_success_6995],
        });
        const evh_6492569393195423_clickPreventStop_6993 = new core_1.actions.EventHandlerImpl({
            action: act_6492569393195423_request_6994,
            event: "click.prevent.stop",
            displayName: "request",
        });
        const evh_6492569393195423_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6492569393195423_clickPreventStop_6993],
        });
        return {
            act_7315092382398562_reloadComponentData_6984,
            evh_7315092382398562_reload_6983,
            evh_7315092382398562_reload,
            act_8320016629450276_reloadComponentData_6988,
            evh_8320016629450276_close_6987,
            act_8320016629450276_showModal_6986,
            evh_8320016629450276_clickPreventStop_6985,
            evh_8320016629450276_clickPreventStop,
            act_6853569216922577_reloadComponentData_6992,
            evh_6853569216922577_close_6991,
            act_6853569216922577_showModal_6990,
            evh_6853569216922577_clickPreventStop_6989,
            evh_6853569216922577_clickPreventStop,
            act_6492569393195423_reloadComponentData_6996,
            evh_6492569393195423_success_6995,
            act_6492569393195423_request_6994,
            evh_6492569393195423_clickPreventStop_6993,
            evh_6492569393195423_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
        default: true,
    }),
    __metadata("design:type", Boolean)
], UcRentalFormProperties.prototype, "editable", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('editable'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcRentalFormProperties.prototype, "onEditable", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], UcRentalFormProperties.prototype, "rentalId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rentalId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcRentalFormProperties.prototype, "onRental_id", null);
UcRentalFormProperties = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcRentalFormProperties);
exports.default = UcRentalFormProperties;
vue_property_decorator_1.Vue.component("UcRentalFormProperties", UcRentalFormProperties);
