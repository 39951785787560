<template>
<ItpWidgetCard>
  <ItpBox
    alignItems="center"
    slot="header"
  >
    <strong>
      {{ 'Contracts'|pgettext('RentalFormContracts') }}
    </strong>
    <ItpButton
      :text="'Create contract...'|gettext"
      size="sm"
      variant="light"
      class="ml-auto"
      @click.prevent.stop="evh_3343864469950044_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
  <BListGroup
    v-if="loaded"
  >
    <BListGroupItem
      class="card-body text-center"
      v-if="!items.length"
    >
      <span>
        {{ 'No rental contracts.'|pgettext('RentalFormContracts') }}
      </span>
    </BListGroupItem>
    <BListGroupItem
      :key="item.id"
      class="d-flex flex-column"
      v-for="item in items"
    >
      <UcContractCard
        :item="item"
        :displayRental="b_1468474219929533_displayRental"
      >
      </UcContractCard>
    </BListGroupItem>
  </BListGroup>
</ItpWidgetCard>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcRentalFormContracts extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_contract', { parameters: { filter: 'rental_id eq "' + this.rental.id + '"' } })
      .then(resp => _.orderBy(resp.data, ['valid_from', 'valid_to'], ['desc', 'desc']))
  }

  ux!: any;
  b_1468474219929533_displayRental!: any;
  dataMembers = ['items', 'ux', 'b_1468474219929533_displayRental'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
        b_1468474219929533_displayRental: false,
      },
    }
  }

  async evh_3343864469950044_close_6875_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_6875, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_3343864469950044_showModal_6874_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_6874, alias=undefined
    return {
      name: "CreateContract",
      props: {
        rentalId: this.rental.id,
      }
      ,
    }
  }

  async evh_3343864469950044_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_3343864469950044_reloadComponentData_6876: actions.ReloadComponentDataAction;
    evh_3343864469950044_close_6875: actions.EventHandlerImpl;
    act_3343864469950044_showModal_6874: actions.ShowModalAction;
    evh_3343864469950044_clickPreventStop_6873: actions.EventHandlerImpl;
    evh_3343864469950044_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_3343864469950044_reloadComponentData_6876 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_3343864469950044_close_6875 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_reloadComponentData_6876,
        event: "close",
        when: this.evh_3343864469950044_close_6875_getWhen,
        displayName: "reloadComponentData",
      }
    );
    const act_3343864469950044_showModal_6874 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_6874_getActionArgs,
        events: [evh_3343864469950044_close_6875],
      }
    );
    const evh_3343864469950044_clickPreventStop_6873 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_6874,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3343864469950044_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_clickPreventStop_6873],
      }
    );
    return {
      act_3343864469950044_reloadComponentData_6876,
      evh_3343864469950044_close_6875,
      act_3343864469950044_showModal_6874,
      evh_3343864469950044_clickPreventStop_6873,
      evh_3343864469950044_clickPreventStop,
    }
  }
}

Vue.component("UcRentalFormContracts", UcRentalFormContracts);

</script>