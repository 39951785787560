<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    v-if="loaded && !loading && !stats"
  >
    <ItpAlert
      show
      class="col"
    >
      <ItpIcon
        name="fa-info-circle"
        class="mr-1"
      >
      </ItpIcon>
      <span>
        {{ 'No data is available for the selected device.'|pgettext('MeterChartsAlert') }}
      </span>
    </ItpAlert>
  </DIV>
  <DIV
    class="container float-left"
    v-if="loaded && stats"
  >
    <ItpRow
      class="mb-4"
    >
      <ItpWidgetCard
        class="col"
      >
        <ItpUplot
          :chartData="stats.readings"
          :options="b_7789867021953041_options"
          slot="content"
        >
        </ItpUplot>
      </ItpWidgetCard>
      <ItpWidgetCard
        class="col"
      >
        <ItpUplot
          :chartData="stats.consumptions"
          :options="b_2070266589801014_options"
          slot="content"
        >
        </ItpUplot>
      </ItpWidgetCard>
    </ItpRow>
    <ItpDataTable
      :value="stats.tableData"
      :editable="b_4186377094414664_editable"
      paging
      tableId="meterAutoReadingStats"
      :columns="b_4186377094414664_columns"
      class="mb-4 shadow-sm"
    >
    </ItpDataTable>
  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcMeterCharts extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  mapResponseData(...args: any[]) {
    const data = args[0]
    if (!data.series.values.length) {
      return;
    }

    let
      columns = data.series.columns,
      tableData = _.map(
        data.series.values,
        p => _.extend({ id: p[1] }, _.zipObject(columns, p))
      );

    tableData = _.orderBy(tableData, ['time']);

    const
      times = _.map(tableData, p => p.time),
      raw = _.map(tableData, p => p.raw),
      values = _.map(tableData, p => p.value),
      offsets = _.map(tableData, p => p.offset),
      diffs = _.map(tableData, p => p.difference);

    return {
      tableData: _.orderBy(tableData, ['time'], ['desc']),
      heatmapData: tableData,
      readings: [times, raw, values],
      consumptions: [times, diffs]
    }
  }

  @Prop({
    required: true,
    type: Object,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  stats!: any;

  async $$load_stats() {
    return this.$fn.fetch('meters/get_daily_stats', {
      parameters: {
        serial: this.meter.serial_number,
        time_from: '2020-01-01'
      }
    }).then(d => this.mapResponseData(d))
  }

  ux!: any;
  b_7789867021953041_options!: any;

  async $$load_b_7789867021953041_options() {
    return {
      title: this.$fn.pgettext("MeterCharts", "Values"),
      id: "meter-readings",
      class: "my-chart",
      width: 500,
      height: 300,
      series: [{
        label: this.$fn.pgettext("MeterCharts", "Time"),
      }
        ,
      {
        label: this.$fn.pgettext('MeterCharts', 'Raw') + ' (' + this.meter.unit + ')',
        stroke: "blue",
        fill: "#00437a22",
      }
        ,
      {
        label: this.$fn.pgettext('MeterCharts', 'Calculated') + ' (' + this.meter.unit + ')',
        stroke: "magenta",
        fill: "#00437a22",
      }
        ,
      ]
      ,
    }
  }

  b_2070266589801014_options!: any;

  async $$load_b_2070266589801014_options() {
    return {
      title: this.$fn.pgettext("MeterCharts", "Consumption"),
      id: "consumptions",
      class: "my-chart",
      width: 500,
      height: 300,
      series: [{
        label: this.$fn.pgettext("MeterCharts", "Time"),
      }
        ,
      {
        label: this.meter.unit,
        stroke: "#00437a",
        fill: "#00437a22",
        stepped: {
          align: -1,
        }
        ,
      }
        ,
      ]
      ,
    }
  }

  b_4186377094414664_editable!: any;
  b_4186377094414664_columns!: any;
  dataMembers = ['stats', 'ux', 'b_7789867021953041_options', 'b_2070266589801014_options', 'b_4186377094414664_editable', 'b_4186377094414664_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        stats: null,
        ux: null,
        b_7789867021953041_options: null,
        b_2070266589801014_options: null,
        b_4186377094414664_editable: false,
        b_4186377094414664_columns: [{
          name: "day",
          header: this.$fn.pgettext("MeterCharts", "Date"),
          width: "120px",
          formatter: {
            name: "template",
            value: "<span\n\
  class=\"text-nowrap\"\n\
  :class=\"{'text-red-500': $fn.isWeekend(value)}\"\n\
>\n\
  {{ value | date }}\n\
</span>\n\
",
          }
          ,
        }
          ,
        {
          name: "raw",
          header: this.$fn.pgettext("MeterCharts", "Raw Value"),
          type: "number",
          width: "100px",
          textAlign: "right",
          cellClass: "text-muted",
          formatter: {
            name: "template",
            value: "<span>{{ value|number }} {{ row.unit }}</span>",
          }
          ,
        }
          ,
        {
          name: "offset",
          header: this.$fn.pgettext("MeterCharts", "Offset"),
          type: "number",
          width: "100px",
          cellClass: "text-muted",
        }
          ,
        {
          name: "value",
          header: this.$fn.pgettext("MeterCharts", "Offset Value"),
          width: "120px",
          textAlign: "right",
          cellClass: "font-weight-bolder",
          formatter: {
            name: "template",
            value: "<span>{{ value|number }} {{ row.unit }}</span>",
          }
          ,
        }
          ,
        {
          name: "difference",
          header: this.$fn.pgettext("MeterCharts", "Consumption"),
          width: "120px",
          textAlign: "right",
          cellClass: "font-weight-bolder",
          formatter: {
            name: "template",
            value: "<span>{{ value|number }} {{ row.unit }}</span>",
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6780: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6779: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6780 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6779 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6780,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6779],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6780,
      evh_7315092382398562_reload_6779,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcMeterCharts", UcMeterCharts);

</script>