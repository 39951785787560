<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InflationIndexedPriceChange"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="payload"
      id="hnvqxzl56c"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        labelFor="contract_number"
        :label="'Contract number'|gettext"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormPlaintext
          strong
        >
          {{ item.data.contract_data.contract_number }}
        </ItpFormPlaintext>
      </ItpFormGroup>
      <ItpFormGroup
        labelFor="tenant"
        :label="'Tenant'|gettext"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormPlaintext
          strong
        >
          {{ item.data.contract_data.counterparty.name }}
        </ItpFormPlaintext>
      </ItpFormGroup>
      <ItpFormGroup
        labelFor="rental"
        :label="'Rental'|gettext"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        v-if="item.data.contract_data.rental"
      >
        <ItpFormPlaintext
          strong
        >
          {{ item.data.contract_data.rental.name }}
        </ItpFormPlaintext>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        name="name"
        :label="'Name'|pgettext('ContractService')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="name"
          v-model="item.data.service_data.name"
          readonly
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        name="current_price"
        :label="'Current price'|pgettext('ContractServiceFee')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        contentClass="input-group"
      >
        <ItpFormInput
          type="number"
          name="fee_amount"
          v-model="item.data.source_object.amount"
          readonly
          class="col-4"
        >
        </ItpFormInput>
        <DIV
          class="input-group-append"
        >
          <span
            class="input-group-text"
          >
            {{ item.data.source_object.currency }}
          </span>
        </DIV>
      </ItpFormGroup>
      <ItpFormGroup
        name="amount"
        :label="'New price'|pgettext('ContractServiceFee')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        contentClass="input-group"
      >
        <ItpFormInput
          type="number"
          name="fee_amount"
          v-model="payload.price"
          required
          class="col-4"
        >
        </ItpFormInput>
        <DIV
          class="input-group-append"
        >
          <span
            class="input-group-text"
          >
            {{ item.data.source_object.currency }}
          </span>
        </DIV>
      </ItpFormGroup>
      <ItpFormGroup
        name="valid_from"
        :label="'Valid from'|pgettext('ContractServiceFee')"
        labelColsMd="4"
        contentColsMd="4"
        :labelAlign="labelAlign"
      >
        <ItpFormDatetime
          name="valid_from"
          v-model="payload.valid_from"
          required
          :readonly="isServiceReadonly"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="valid_to"
        :label="'Valid to'|pgettext('ContractServiceFee')"
        labelColsMd="4"
        contentColsMd="4"
        :labelAlign="labelAlign"
      >
        <ItpFormDatetime
          name="valid_to"
          v-model="payload.valid_to"
          :readonly="isServiceReadonly"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="fee_notes"
        :label="'Notes'|pgettext('ContractServiceFee')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="fee_notes"
          v-model="payload.notes"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      form="hnvqxzl56c"
      :text="'Save'|gettext"
      type="submit"
      variant="primary"
      :spinning="actions.update.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="actions.update.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InflationIndexedPriceChange extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  id!: string;

  @Watch('id')
  onId(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("id")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_notification', { parameters: { id: this.id }, asResource: true })
  }

  payload!: any;

  async $$load_payload() {
    return {
      price: this.item.data.new_price,
      valid_from: this.item.data.source_object.valid_to || this.$fn.today(),
      valid_to: null,
      notes: null
    }
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['labelAlign', 'item', 'payload', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        item: null,
        payload: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Create new price"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2186_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2186, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_2192_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_2192, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async act_1040167445267876_request_2190_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_2190, alias=update
    return {
      operation: "update_notification",
      data: {
        id: this.item.id,
        $etag: this.item.$etag,
        state: 'handled',
        result_notes: this.payload.notes,
        result_data: this.payload
      },
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_2194_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_2194, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2186: actions.CloseModalAction;
    evh_7315092382398562_close_2185: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2188: actions.CloseComponentAction;
    evh_2248226175642056_close_2187: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_2192: actions.CloseModalAction;
    evh_1040167445267876_success_2191: actions.EventHandlerImpl;
    act_1040167445267876_request_2190: actions.RequestAction;
    evh_1040167445267876_submit_2189: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_2194: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_2193: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2186 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2186_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2185 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2186,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2185],
      }
    );
    const act_2248226175642056_closeComponent_2188 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2187 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2188,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2187],
      }
    );
    const act_1040167445267876_closeModal_2192 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_2192_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_2191 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_2192,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_2190 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_2190_getActionArgs,
        displayName: "update",
        events: [evh_1040167445267876_success_2191],
      }
    );
    const evh_1040167445267876_submit_2189 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_2190,
        event: "submit",
        displayName: "update",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2189],
      }
    );
    const act_1419464017721962_closeModal_2194 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_2194_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2193 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_2194,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2193],
      }
    );
    return {
      act_7315092382398562_closeModal_2186,
      evh_7315092382398562_close_2185,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2188,
      evh_2248226175642056_close_2187,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_2192,
      evh_1040167445267876_success_2191,
      act_1040167445267876_request_2190,
      evh_1040167445267876_submit_2189,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_2194,
      evh_1419464017721962_clickPreventStop_2193,
      evh_1419464017721962_clickPreventStop,
      update: act_1040167445267876_request_2190,
    }
  }
}

Vue.component("InflationIndexedPriceChange", InflationIndexedPriceChange);

</script>