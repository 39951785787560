"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let AccountStatementReport = class AccountStatementReport extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['accounts', 'formId', 'connector', 'item', 'file', 'responseType', 'responseTypes', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_style'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "AccountStatementReport.item");
        this.$$cache_responseType = new core_1.ComponentValueCache(this, "responseType", "AccountStatementReport.responseType");
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onInvoices(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoices");
    }
    onTransaction_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("transactionType");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_accounts() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_account')
                .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']));
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_connector() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.resourceProxy({
                url: () => this.$fn.urlFor('report_account_statement', Object.assign(Object.assign({}, this.item), { account: this.item.account.id })),
                useAuth: true,
            }).on('file', args => this.setData('file', args));
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                date_from: null,
                date_to: null,
                account: this.accounts ? this.accounts[0] : null,
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            accounts: null,
            formId: null,
            connector: null,
            item: null,
            file: null,
            responseType: "json",
            responseTypes: ["json",
                "pdf",
            ],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "full-screen",
            },
            b_5731695935601903_style: {
                width: "300px",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("AccountStatementReport/title", "Account Statement Report"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3078_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1747314907724257_setData_3082_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_1747314907724257_setData_3084_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1747314907724257_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1747314907724257_changed.executeFromDOM(this, event, scope);
        });
    }
    act_2421697723438155_script_3086_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.connector.load();
        });
    }
    act_2421697723438155_script_3086_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2421697723438155_script_3086_getActionArgs_value($event),
            };
        });
    }
    evh_2421697723438155_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2421697723438155_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3078 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3078_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3077 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3078,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3077],
        });
        const act_2248226175642056_closeModal_3080 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_3079 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_3080,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3079],
        });
        const act_1747314907724257_setData_3082 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1747314907724257_setData_3082_getActionArgs,
            events: [],
        });
        const evh_1747314907724257_changed_3081 = new core_1.actions.EventHandlerImpl({
            action: act_1747314907724257_setData_3082,
            event: "changed",
            displayName: "setData",
        });
        const act_1747314907724257_setData_3084 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1747314907724257_setData_3084_getActionArgs,
            events: [],
        });
        const evh_1747314907724257_changed_3083 = new core_1.actions.EventHandlerImpl({
            action: act_1747314907724257_setData_3084,
            event: "changed",
            displayName: "setData",
        });
        const evh_1747314907724257_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1747314907724257_changed_3081, evh_1747314907724257_changed_3083],
        });
        const act_2421697723438155_script_3086 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2421697723438155_script_3086_getActionArgs,
            events: [],
        });
        const evh_2421697723438155_click_3085 = new core_1.actions.EventHandlerImpl({
            action: act_2421697723438155_script_3086,
            event: "click",
            displayName: "script",
        });
        const evh_2421697723438155_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2421697723438155_click_3085],
        });
        return {
            act_7315092382398562_closeModal_3078,
            evh_7315092382398562_close_3077,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_3080,
            evh_2248226175642056_close_3079,
            evh_2248226175642056_close,
            act_1747314907724257_setData_3082,
            evh_1747314907724257_changed_3081,
            act_1747314907724257_setData_3084,
            evh_1747314907724257_changed_3083,
            evh_1747314907724257_changed,
            act_2421697723438155_script_3086,
            evh_2421697723438155_click_3085,
            evh_2421697723438155_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], AccountStatementReport.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], AccountStatementReport.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Array,
        default: null,
    }),
    __metadata("design:type", typeof (_a = typeof array !== "undefined" && array) === "function" ? _a : Object)
], AccountStatementReport.prototype, "invoices", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoices'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], AccountStatementReport.prototype, "onInvoices", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], AccountStatementReport.prototype, "transactionType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('transactionType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], AccountStatementReport.prototype, "onTransaction_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], AccountStatementReport.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], AccountStatementReport.prototype, "onSize", null);
AccountStatementReport = __decorate([
    (0, vue_property_decorator_1.Component)()
], AccountStatementReport);
exports.default = AccountStatementReport;
vue_property_decorator_1.Vue.component("AccountStatementReport", AccountStatementReport);
