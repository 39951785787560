"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let Payment = class Payment extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    recalculateAmountFromLines(...args) {
        const item = this.item;
        const [invoiceData] = args;
        console.log('recalculateAmountFromLines', args, invoiceData);
        if (invoiceData) {
            const idx = _.findIndex(item.invoices.data, { invoice_id: invoiceData.invoice_id });
            if (idx > -1) {
                _.extend(item.invoices.data[idx], invoiceData);
            }
        }
        item.amount = _.sumBy(item.invoices.data, p => _.toNumber(p.amount));
        if (this.isPettyCashPayment) {
            item.amount = Math.abs(item.amount);
        }
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onInvoices(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoices");
    }
    onAccount_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("accountId");
    }
    onTransaction_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("transactionType");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid == null
                ? this.$fn.fetch('get_payment_template', {
                    asResource: true,
                    parameters: {
                        TransactionType: this.transactionType,
                        invoices: this.invoices ? this.invoices.join(',') : null,
                        account_id: this.accountId
                    }
                })
                : this.$fn.fetch('read_payment', { asResource: true, parameters: { id: this.oid } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get isDeletable() {
        if (this.loaded) {
            return this.oid != null;
        }
        return null;
    }
    get isNew() {
        if (this.loaded) {
            return this.item && this.item.id == null;
        }
        return null;
    }
    get isPettyCashPayment() {
        if (this.loaded) {
            return this.item.account && this.item.account.account_type === 'cash_book';
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3142_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_3150_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "payments",
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_setData_3154_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event && $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_1040167445267876_setData_3154_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1040167445267876_reloadSlickgrid_3156_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "payments",
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_3156_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1040167445267876_crud_3152_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "payment",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_3083451129821185_script_3158_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateAmountFromLines($event.data);
        });
    }
    act_3083451129821185_script_3158_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_3083451129821185_script_3158_getActionArgs_value($event),
            };
        });
    }
    evh_3083451129821185_recalculate(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3083451129821185_recalculate.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_setData_3162_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event && $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1419464017721962_success_3161_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1419464017721962_reloadSlickgrid_3164_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "payments",
            };
        });
    }
    evh_1419464017721962_success_3163_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1419464017721962_rest_3160_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: {
                    self: this.item.links.parent,
                    object: 'list',
                    data: []
                },
                resource: this.item,
                data: {
                    etag: this.item.etag,
                    complete: true,
                },
            };
        });
    }
    act_1419464017721962_rest_3160_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: this.dialogHeader,
                message: this.$fn.gettext("Completing prevents further modifications. Please confirm this operation."),
                btnOk: this.$fn.gettext("Complete"),
                severity: "warning",
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5566617329548203_setData_3166_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: this.item.clone({
                    id: null,
                    rev: null,
                    payment_number: this.isPettyCashPayment ? null : this.item.payment_number,
                    amount: 0,
                    links: null,
                    etag: null,
                    invoices: { data: [] },
                    preview_url: null,
                    locked: false,
                    notes: null,
                    internal_notes: null,
                }),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_5566617329548203_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3142 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3142_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3141 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3142,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3141],
        });
        const act_2248226175642056_closeModal_3144 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_3143 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_3144,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3143],
        });
        const act_8564662037462133_reloadComponentData_3146 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_3145 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_3146,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_3145],
        });
        const act_8564662037462133_closeModal_3148 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_8564662037462133_deleted_3147 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_3148,
            event: "deleted",
            displayName: "closeModal",
        });
        const act_8564662037462133_reloadSlickgrid_3150 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_3150_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_3149 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_3150,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_3147, evh_8564662037462133_deleted_3149],
        });
        const act_1040167445267876_setData_3154 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_3154_getActionArgs,
            when: this.act_1040167445267876_setData_3154_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_3153 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_3154,
            event: "success",
            displayName: "setData",
        });
        const act_1040167445267876_reloadSlickgrid_3156 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_3156_getActionArgs,
            when: this.act_1040167445267876_reloadSlickgrid_3156_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_3155 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_3156,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_crud_3152 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_3152_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_3153, evh_1040167445267876_success_3155],
        });
        const evh_1040167445267876_submit_3151 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_3152,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_3151],
        });
        const act_3083451129821185_script_3158 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3083451129821185_script_3158_getActionArgs,
            events: [],
        });
        const evh_3083451129821185_recalculate_3157 = new core_1.actions.EventHandlerImpl({
            action: act_3083451129821185_script_3158,
            event: "recalculate",
            displayName: "script",
        });
        const evh_3083451129821185_recalculate = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3083451129821185_recalculate_3157],
        });
        const act_1419464017721962_setData_3162 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1419464017721962_setData_3162_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_success_3161 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_setData_3162,
            event: "success",
            when: this.evh_1419464017721962_success_3161_getWhen,
            displayName: "setData",
        });
        const act_1419464017721962_reloadSlickgrid_3164 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1419464017721962_reloadSlickgrid_3164_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_success_3163 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_reloadSlickgrid_3164,
            event: "success",
            when: this.evh_1419464017721962_success_3163_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_1419464017721962_rest_3160 = new core_1.actions.RestAction({
            actionArgs: this.act_1419464017721962_rest_3160_getActionArgs,
            confirm: this.act_1419464017721962_rest_3160_getConfirm,
            displayName: "complete",
            events: [evh_1419464017721962_success_3161, evh_1419464017721962_success_3163],
        });
        const evh_1419464017721962_clickPreventStop_3159 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_rest_3160,
            event: "click.prevent.stop",
            displayName: "complete",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_3159],
        });
        const act_5566617329548203_setData_3166 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5566617329548203_setData_3166_getActionArgs,
            events: [],
        });
        const evh_5566617329548203_clickPreventStop_3165 = new core_1.actions.EventHandlerImpl({
            action: act_5566617329548203_setData_3166,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_5566617329548203_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5566617329548203_clickPreventStop_3165],
        });
        return {
            act_7315092382398562_closeModal_3142,
            evh_7315092382398562_close_3141,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_3144,
            evh_2248226175642056_close_3143,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_3146,
            evh_8564662037462133_reload_3145,
            evh_8564662037462133_reload,
            act_8564662037462133_closeModal_3148,
            evh_8564662037462133_deleted_3147,
            act_8564662037462133_reloadSlickgrid_3150,
            evh_8564662037462133_deleted_3149,
            evh_8564662037462133_deleted,
            act_1040167445267876_setData_3154,
            evh_1040167445267876_success_3153,
            act_1040167445267876_reloadSlickgrid_3156,
            evh_1040167445267876_success_3155,
            act_1040167445267876_crud_3152,
            evh_1040167445267876_submit_3151,
            evh_1040167445267876_submit,
            act_3083451129821185_script_3158,
            evh_3083451129821185_recalculate_3157,
            evh_3083451129821185_recalculate,
            act_1419464017721962_setData_3162,
            evh_1419464017721962_success_3161,
            act_1419464017721962_reloadSlickgrid_3164,
            evh_1419464017721962_success_3163,
            act_1419464017721962_rest_3160,
            evh_1419464017721962_clickPreventStop_3159,
            evh_1419464017721962_clickPreventStop,
            act_5566617329548203_setData_3166,
            evh_5566617329548203_clickPreventStop_3165,
            evh_5566617329548203_clickPreventStop,
            save: act_1040167445267876_crud_3152,
            complete: act_1419464017721962_rest_3160,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Payment.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Array,
        default: null,
    }),
    __metadata("design:type", typeof (_a = typeof array !== "undefined" && array) === "function" ? _a : Object)
], Payment.prototype, "invoices", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoices'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onInvoices", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Payment.prototype, "accountId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('accountId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onAccount_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Payment.prototype, "transactionType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('transactionType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onTransaction_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], Payment.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onSize", null);
Payment = __decorate([
    (0, vue_property_decorator_1.Component)()
], Payment);
exports.default = Payment;
vue_property_decorator_1.Vue.component("Payment", Payment);
